import "babel-polyfill";
import "es6-promise/auto";
// promise.polyfill();

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import "ant-design-vue/dist/antd.css";
import Antd from "ant-design-vue";

Vue.use(Antd);

import Astrict from "../src/utils/astrict";
Vue.use(Astrict);

import router from "./router";
import axios from "axios";
Vue.prototype.axios = axios;

// 挂载网络请求
import http from "./utils/http";
Vue.prototype.$http = http;

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: sessionStorage.getItem("languagevalue")
    ? sessionStorage.getItem("languagevalue")
    : "zh", // 语言标识
  messages: {
    zh: require("../src/assets/langs/zh_CN"), //中文
    en: require("../src/assets/langs/en"), //英语
    zh_TW: require("../src/assets/langs/zh_TW"), //简体中文
    de_US: require("../src/assets/langs/de_US"), //德语
    cs_US: require("../src/assets/langs/cs_US"), //捷克语
    es_US: require("../src/assets/langs/es_US"), //西班牙语
    fr_US: require("../src/assets/langs/fr_US"), //法语
    it_US: require("../src/assets/langs/it_US"), //意大利语
    ko_KR: require("../src/assets/langs/ko_KR"), //韩语
    pt_BR: require("../src/assets/langs/pt_BR"), //葡萄牙语
    ru_CN: require("../src/assets/langs/ru_CN"), //俄语
    ja: require("../src/assets/langs/ja"), //日语
    pt: require("../src/assets/langs/pt") //葡萄牙语标准
  }
});

Vue.config.productionTip = false;
window.vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
