<template>
  <a-config-provider :locale="locale">
    <div id="app" @mousemove="appMouseMove($event)">
      <!-- <keep-alive> -->
        <router-view />
      <!-- </keep-alive> -->
      
    </div>
  </a-config-provider>
</template>

<script>
import zh from "ant-design-vue/es/locale-provider/zh_CN";
import pt_BR from "ant-design-vue/es/locale-provider/pt_BR";
import pt from "ant-design-vue/es/locale-provider/pt_PT";
import cs_US from "ant-design-vue/es/locale-provider/cs_CZ";
import de_US from "ant-design-vue/es/locale-provider/de_DE";
import ko_KR from "ant-design-vue/es/locale-provider/ko_KR";
import ja from "ant-design-vue/es/locale-provider/ja_JP";
import it_US from "ant-design-vue/es/locale-provider/it_IT";
import es_US from "ant-design-vue/es/locale-provider/es_ES";
import fr_US from "ant-design-vue/es/locale-provider/fr_FR";
import ru_CN from "ant-design-vue/es/locale-provider/ru_RU";
import zh_TW from "ant-design-vue/es/locale-provider/zh_TW";
import en from "ant-design-vue/es/locale-provider/en_US";
import { updatePostionInfo } from "@/views/Admin.vue";
export default {
  data() {
    return {
      zh,
      en,
      zh_TW,
      de_US,
      cs_US,
      es_US,
      fr_US,
      it_US,
      ko_KR,
      pt_BR,
      ru_CN,
      ja,
      pt,
      locale: zh,
      lanvalue: ""
    };
  },
  created() {
    this.changelan();
  },
  methods: {
    changelan() {
      this.lanvalue = sessionStorage.getItem("languagevalue")
        ? sessionStorage.getItem("languagevalue")
        : "zh";
      if (this.lanvalue == "zh") {
        this.locale = zh;
      } else if (this.lanvalue == "en") {
        this.locale = en;
      } else if (this.lanvalue == "zh_TW") {
        this.locale = zh_TW;
      } else if (this.lanvalue == "de_US") {
        this.locale = de_US;
      } else if (this.lanvalue == "cs_US") {
        this.locale = cs_US;
      } else if (this.lanvalue == "es_US") {
        this.locale = es_US;
      } else if (this.lanvalue == "fr_US") {
        this.locale = fr_US;
      } else if (this.lanvalue == "it_US") {
        this.locale = it_US;
      } else if (this.lanvalue == "ko_KR") {
        this.locale = ko_KR;
      } else if (this.lanvalue == "pt_BR") {
        this.locale = pt_BR;
      } else if (this.lanvalue == "ru_CN") {
        this.locale = ru_CN;
      } else if (this.lanvalue == "ja") {
        this.locale = ja;
      } else if (this.lanvalue == "pt") {
        this.locale = pt;
      }
    },
    appMouseMove(event) {
      updatePostionInfo(event);
    }
  },
  provide() {
    return {
      changelan: this.changelan
    };
  }
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin !important;
}
#nprogress .bar {
  background: #1890ff !important; //自定义颜色
}
::-webkit-scrollbar {
  width: 7px;
  height: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
