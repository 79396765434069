import axios from "axios";

const http = axios.create({
  // baseURL: process.env.VUE_APP_API_URL,

  // baseURL: 'https://api-test.xmeye.net/api2', //测试服


  baseURL: 'https://api.xmeye.net/api', //正式服
  // baseURL: 'http://10.10.22.46:8081', //罗嘉兴本地
  timeout: 10000,//超时修改为10s
  headers: {
    "Content-Type": "application/json"
    // "codeToken": localStorage.getItem("XM-token")
  }
});

const error = err => {
  // console.log('http.js error',err)
  if (err.response) {
    const { status } = err.response;
    if (status === 401) {
      window.vm.$message.destroy();
      window.vm.$message.error(window.vm.$t("accessnot"));
    } else if (status === 403) {
      window.vm.$message.destroy();
      window.vm.$message.error(window.vm.$t("resourcenot"));
    } else if (status === 404) {
      window.vm.$message.destroy();
      window.vm.$message.error(window.vm.$t("nopage"));
    } else if (status === 405) {
      window.vm.$message.destroy();
      window.vm.$message.error(window.vm.$t("methoderror"));
    } else if (status === 500) {
      window.vm.$message.destroy();
      window.vm.$message.error(window.vm.$t("serveerror"));
    } else {
      window.vm.$message.destroy();
      window.vm.$message.error(window.vm.$t("unknownerror"));
    }
    return Promise.reject(err);
  }
};
// axios.interceptors.response.use(response => {
//   // IE 8-9
//   if (
//     response.data == null &&
//     response.config.responseType === "json" &&
//     response.request.responseText != null
//   ) {
//     try {
//       // eslint-disable-next-line no-param-reassign
//       response.data = JSON.parse(response.request.responseText);
//     } catch (e) {
//       // ignored
//     }
//   }
//   return response;
// });

http.interceptors.request.use(function(config) {
  // console.log('http.js request',config)
  return config;
}, error);

http.interceptors.response.use(function(response) {
  // console.log('http.js response',response)
  return response.data;
}, error);

export default http;
