module.exports = {
  //2022-6 新增
  cloudinfo: {
    normal: "通常の使用",
    timeOut: "期限切れ",
    nonactivated: "開いていません",
    notSupport: "サポートしません",
    noRemainingFlow: "流れが残っていない",
    VipStatus: "Vipステータス",
    totalFlow: "トータルフロー",
    residualFlow: "残留流",
    expireDate: "有効期限"
  },
  //新增
  cloud: "クラウドストレージ",
  cloudTimeOut: "クラウドストレージ（期限切れ）",
  cloudNormal: "クラウドストレージ（使用中）",
  cloudNonActivated: "クラウドストレージ（未開通）",
  cloudnotSupported:"クラウドストレージ（提供なし）",
  vipTimeOut: "4Gフロー（期限切れ）",
  vipNormal: "4G流量（使用中）",
  vipNonActivated: "4G流量（未開通）",
  vipnotSupported:"4G流量(提供なし)",
  // 日语
  // 登录页
  userLogin: "ユーザーログイン", //用户登录
  equipLogin: "デバイスログイン", //设备登录
  login: "ログイン", //登录
  regist: "今すぐサインアップ", //立即注册
  findPassword: "パスワードを忘れる", //找回密码
  userRule: "ユーザー名を入力してください", //请输入用户名
  passwordRule: "パスワードを入力してください", //请输入密码
  codeRule: "確認コードを入力してください", //请输入验证码
  equipIdRule: "デバイスのシリアル番号を入力してください", //请输入设备序列号
  appDownload: "APP ダウンロード", //App 下载
  webUnitDownload: "ActiveX ダウンロード", //Web控件下载
  callUs: "技術サポート", //联系我们
  officialEdition: "クラシック版", //经典版
  proEdition: "PRO版", //PRO版
  codeLenRule: "4文字の確認コードを入力してください", //请输入4位验证码
  notEmpty: "スペースを入力しないでください", //请勿输入空格

  // 注册
  userRegist: "アカウントを作成", //创建账户
  emailRule: "入力してくださいメール", //请输入邮箱
  emailtrue: "メールボックス番号を正しい形式で入力してください", //请输入正确格式的邮箱号
  phonetrue:"正しいフォーマットの携帯電話番号を入力してください",//请输入正确的手机号码
  confirmPasswordRule: "パスワードをもう一度入力してください", //请再次输入密码
  hadCount: "すぐにログインする", //已有账号，立即登录
  registCount: "登録する", //注册
  hadUser: "登録済みのユーザー名は既に存在します", //您注册的用户名已存在
  hadMail: "登録済みのメールアドレスは既に存在します", //您注册的邮箱已存在
  sendSuccess: "メールは正常に送信されました", //邮件发送成功
  mailFormatError: "メールフォーマットエラー、確認してください", //邮箱格式错误，请检查
  codeError: "検証コードエラー", //验证码错误
  registerSuccess: "登録成功", //注册成功
  mailNotFind: "入力したメールアドレスが存在しません。確認してください", //您输入的邮箱不存在，请检查
  userNameLen: "4〜8文字のユーザー名を入力してください", //请输入4~8位用户名
  userPwdLen: "8〜32桁のパスワードを入力してください", //请输入8~32位密码
  privacyPolicy:"プライバシーポリシーを読み、選択する",//阅读并选择隐私政策
  emailRegistration:"メールボックス登録",//邮箱注册
  mobileRegistration:"携帯電話の登録",//手机注册
  // 找回密码
  phoneRetrievePassword:"携帯電話がパスワードを取り戻す",//手机找回密码
  mailboxRetrievalPassword:"メールボックスのパスワードの検索",//邮箱找回密码
  findThePassword: "パスワードを忘れる", //找回密码
  passwordText:
    "アカウント登録時に使用したメールアドレスを入力してパスワードを取得してください", //请输入注册账号时使用的邮箱来找回密码
  exit: "戻る", //退出
  nextStep: "次の一歩", //下一步
  reset: "再設定", //重新设置
  getCode: "送信", //获取验证码
  someTime: "再取得", //秒后重新获取
  ok: "確認", //确定
  mailSendMuch: "メールはメールボックスごとに1日3回しか送信できません", //邮件每个邮箱一天只能发送三次
  mailChangeSuccess: "正常に変更されました", //修改成功
  pwdError: "パスワードの形式が正しくありません", //密码格式不正确
  codeTimeout: "検証コードの有効期限が切れ", //验证码过期
  codeErrorMuch: "エラーが多すぎます。しばらくしてからもう一度お試しください", //错误次数过多,请稍后再试
  backLogin: "ログインに戻る", //返回登录
  mailFast: "繰り返しの電子メールを短時間で許可されていません", //短时间内不允许重复发送邮件

  //主页面
  refresh: "更新", //刷新
  myDevice: "私のデバイス", //我的设备
  personalInformation: "個人情報", //个人信息
  deviceManagement: "設備管理", //设备管理
  VIPTraffic:"VIPトラフィック",//VIP流量
  changePassword: "パスワードを変更する", //修改密码
  changePasswordSuccess: "パスワードの変更に成功", //修改密码成功
  LogOut: "サインアウト", //退出登录
  shurLogOut: "やめてもよろしいですか", //你确定要退出吗
  oldPassword: "以前のパスワード", //旧密码
  newPassword: "新しいパスワード", //新密码
  inputNewPassword: "新しいパスワードの確認", //再次输入新密码
  errorRefresh: "リフレッシュに失敗しました", //刷新失败
  online: "オンライン", //在线
  offline: "オフライン", //离线
  UidLength:
    "大文字と小文字の文字と数字で構成される16桁または20桁のデバイスシリアル番号を入力してください", //请输入16或20位由大,小写字母,数字组成的设备序列号
    PwordLength: "100ビット以下の大、小文字、数字からなるデバイスパスワードを入力してください",
    passwordrule: "特別な記号を入力しないでください", //请勿输入特殊符号
  equipOffline:
    "デバイスは一時的にオフラインです。別のデバイスを選択してください", //设备暂时不在线请选择其它设备吧
  BrowserSupport:
    "このブラウザはプラグイン再生をサポートしていません。GoogleChromeを使用してください", //此浏览器不支持插件播放，请使用IE或360浏览器
  embedBrowserFail:
    "ブラウザのバインドに失敗しました。ログイン時にページをすばやく切り替えないでください。", //绑定浏览器失败，请在登录时勿快速切换页面
  noPlugin: "プラグインが検出されません", //未检测到插件
  CancelAccount: "アカウントをキャンセルする",
  CancelAccountTitle: "アカウントを削除してもよろしいですか？",
  CancelSuccess: "アカウントを正常にキャンセルする",

  // 设备列表、
  order: "SN", //序号
  equipmentPwd: "デバイスのパスワード", //设备密码
  equipmentOrder: "シリアルナンバー", //序列号
  equipmentName: "設備名", //名称
  equipmentUser: "ユーザ名", //设备用户名
  option: "操作します", //操作
  addEquipment: "デバイスを追加", //添加设备
  changeEquipmentMessage: "修正デバイス情報", //修改设备信息
  changePasswordMessage:"パスワード情報の変更",//修改密码信息
  deleteEquipment:"デバイスの削除",//删除设备
  delmessage: "本当に削除してもよろしいですか", //你确定要删除吗
  all: "合計で", //共
  tips: "データ", //条数据
  add: "追加", //添加
  equipmentSuccess: "デバイス情報が正常に変更されました", //设备信息修改成功
  addUid: "追加するデバイスのシリアル番号を入力してください", //请输入你要添加的设备序列号
  addid: "追加するシリアル番号を入力してください", //请输入你要添加的序号
  addname: "追加するデバイスの名前を入力してください", //请输入你要添加的设备名
  addUsername: "追加するデバイスのユーザー名を入力してください", //请输入你要添加的设备用户名

  // 用户信息
  userinfo: "ユーザー情報", //用户信息
  userName: "ユーザー名", //用户名
  usercontact: "電話番号", //联系方式
  useremail: "Eメール", //电子邮箱

  // 全局提示
  successLogin: "ログインに成功", //登录成功
  errorLogin1:
    "ログインに失敗しました、ユーザー名またはパスワードが間違っています", //登录失败，用户名或密码错误
  errorLogin2: "ログインに失敗しました、確認コードエラー", //登录失败，验证码错误
  errorLogin3: "ログインに失敗し、確認コードの有効期限が切れました", //登录失败，验证码过期
  errorLogin4: "ログイン失敗が多すぎます。アカウントが10分間ロックされています", //登录失败次数过多，账户锁定10分钟
  delsucess: "正常に削除", //删除成功
  addSuccess: "正常に追加されました", //添加成功
  addFail: "追加に失敗しました", //添加失败
  timeOut: "ログインタイムアウト、自動的にログインページにジャンプします", //登录超时，自动跳转登录页
  changeEquipmentPwdError: "元のデバイスのパスワードが正しく入力されていません", //原设备密码输入错误

  // 网络提示
  accessnot: "アクセス拒否", //访问被拒绝
  resourcenot: "リソースが利用できません", //资源不可用
  nopage: "ページが見つかりません", //找不到页面
  methoderror: "メソッドは許可されていません", //不允许此方法
  serveerror: "サーバーエラー", //服务器错误
  unknownerror: "未知の間違い", //未知错误

  // 表单提示
  emailTip: "**@**.***",
  phoneTip:"正しい携帯番号を入力してください",
  confirmTip: "パスワードと一致させてください", //请与密码保持一致
  equipmentIdTip: "16桁または20桁のデバイスシリアル番号を入力してください", //请输入16或20位设备序列号
  equipmentUserTip: "デバイスのユーザー名を入力してください", //请输入设备用户名
  equipmentPasswordTip1: "デバイスのパスワードを入力してください", //请输入设备密码
  equipmentPasswordTip:
    "デバイスパスワードを入力してください、パスワードなしでそれを入力する必要はありません", //请输入设备密码，没有则无需输入
  codeTip: "取得した電子メールの確認コードを入力してください", //请输入获取的邮箱验证码
  equipmentPwdTip: "デバイスのパスワードはデフォルトでは空白です", //设备密码默认为空
  equipmentNameTip: "デバイス名を入力してください", //请输入设备名称
  equipmentUserNameTip: "デバイスのユーザー名のデフォルトは「admin」です", //设备用户名默认为“admin”
  pagetip1:
    "左側の[マイデバイス]でデバイスをクリックして、デバイスでのプレビュー、再生、構成、およびその他の操作を行います", //单击左边“我的设备”中的设备，对设备进行预览，回放，配置等操作
  pagetip2:
    "初めてのアクセスの場合は、プラグインをダウンロードしてください。数分かかる場合がありますので、お待ちください", //如果您是第一次访问，会自动下载插件，可能需要花费几分钟时间，请耐心等待
  passwordChangeTip: "パスワードを変更しない場合は、空白のままにしてください", //若不修改密码请保持为空
  loginUseNameTip: "ユーザー名、メール番号、携帯電話番号でログイン", //可使用用户名、邮箱号、手机号登录

  placeholderUser: "ユーザー名/メール/モバイル", //用户名/邮箱号/手机号
  placeholderPassword: "パスワード", //密码
  placeholderCode: "検証コード", //验证码
  placeholderEquipId: "シリアルナンバー", //设备序列号
  placeholderEquipAdmin: "デバイスのユーザー名", //设备用户名
  placeholderEquipPassword: "デバイスのパスワード", //设备密码
  placeholderRegistUser: "ユーザー名", //用户名
  placeholderRegistMail: "メール", //邮箱
  placeholderRegistPhone: "携帯電話", //手机
  placeholderRegistCode: "検証コード", //验证码
  placeholderRegistPwd: "パスワード", //密码
  placeholderRegistPwd2: "パスワードを認証する", //确认密码

  supportTitle: "テクニカルサポートの連絡先情報", //技术支持联系方式
  supportPhone: "サービスホットライン", //服务热线
  supportMail: "サービスメールボックス", //服务邮箱
  supportTime: "サービス時間：月曜日から土曜日8：30-17：30（法定休日を除く）", //服务时间：周一到周六8:30--17:30（法定节假日除外）
  userPwdRule: "パスワードの形式が間違っている", //密码格式错误
  userNameRule: "ユーザー名の形式が正しくありません", //用户名格式错误
  Ok: "確認", //确认
  cancel: "キャンセル", //取消
  backOld: "古いバージョン", //回到旧版

  cookie:
    "当社のウェブサイトは、クッキーを使用してウェブサイトを改善し、ユーザーエクスペリエンスを向上させています。 ブラウザのCookie設定を変更せずにこのウェブサイトを閲覧し続けると、Cookieの使用ポリシーに同意したものと見なされます。", //我们的网站使用cookies以改进我们的网站和改善用户体验。如您继续浏览本网站而未更改浏览器cookie设置，视为同意我们的cookies使用策略。
  cookieLink: "ポリシー", //政策
  agree: "同意", //同意
  notAgree: "同意しない", //不同意

  cookiePolicy: "Cookie ポリシー", //Cookie 政策
  cookieUse: "クッキーおよび同様のテクノロジーの使用方法", //我们如何使用Cookie和同类技术
  cookieWhy:
    "ウェブサイトの正常な動作を保証するために、Cookieと呼ばれる小さなデータファイルをコンピューターまたはモバイルデバイスに保存します。 Cookieには通常、識別子、サイト名、およびいくつかの数字と文字が含まれています。 クッキーの助けを借りて、ウェブサイトはあなたの好みなどのデータを保存することができます。", //为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据档。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。
  cookieFor:
    "このポリシーに記載されている以外の目的でCookieを使用することはありません。 好みに応じてCookieを管理または削除できます。 詳細については、AboutCookies.orgを参照してください。 コンピュータに保存されているすべてのCookieをクリアでき、ほとんどのWebブラウザにはCookieをブロックする機能があります。 ただし、これを行う場合は、当社のWebサイトにアクセスするたびにユーザー設定を個人的に変更する必要があります。 ブラウザの設定を変更する方法の詳細については、使用しているブラウザの関連する設定ページにアクセスしてください。 ただし、Cookieを受け入れない場合、Webサイトの一部の機能を十分に体験できない可能性があることに注意してください。", //我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。但请注意，如果您不接受cookie，可能无法全面体验网站的某些功能。
  cookieList: "以下は、Webサイトで使用されるCookieの種類のリストです。", //以下是网站使用的cookie种类列表：
  cookiePurpose: "目的", //目的
  cookieDescribe: "説明", //描述
  cookieType: "タイプと有効期限", //种类和失效时间
  cookiePerformance: "パフォーマンス（ユーザーブラウザ）", //性能（用户浏览器）
  cookieLogin:
    "ユーザーのログインステータスを記録し、7日以内に再度ログインする必要はありません", //记录用户登录状态，以及7天内不用重新登录
  cookieDay: "7日", //7天
  cookieCnzz: "CNZZ", //CNZZ
  cookieTime: "ユーザーがWebサイトにアクセスした回数を記録します", //记录用户访问网站的次数
  cookieForever: "永続", //永久
  cookieTrack:
    "多くのWebブラウザーには、DoNotTrackリクエストをWebサイトに投稿できるDoNotTrack機能があります。 現在、主要なインターネット標準化組織は、Webサイトがそのような要求にどのように応答するかを規制するための関連ポリシーを確立していません。 ただし、ブラウザでDo Not Trackが有効になっている場合、当社のWebサイトはお客様の選択を尊重します。", //很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do NotTrack请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站都会尊重您的选择。
  backHome: "ホームページに戻る" ,//回到首页
  // RS
  RS2000: '実行に成功しました',//执行成功
  RS4000: "ユーザー名またはパスワードが間違っています",//用户名或密码错误
  RS4010: '認証コードエラー',//验证码错误
  RS4011: 'パスワードが一致しません',//密码不一致
  RS4012: "ユーザー名が登録されています",//用户名已被注册
  RS4013: "ユーザー名が空です",//用户名为空
  RS4014: 'パスワードが空です',//密码为空
  RS4015: 'パスワードが空であることを確認します',//确认密码为空
  RS4016: '携帯番号が空です',//手机号为空
  RS4017: "ユーザー名のフォーマットが正しくありません",//用户名格式不正确
  RS4018: 'パスワードフォーマットが正しくありません',//密码格式不正确
  RS4019: 'パスワードのフォーマットが正しくないことを確認します',//确认密码格式不正确
  RS4020: '携帯番号のフォーマットが正しくありません',//手机号格式不正确
  RS4021: '携帯番号は登録済み',//手机号已注册
  RS4022: '携帯番号未登録',//手机号未注册
  RS4023: 'メールボックスは登録されています',//邮箱已注册
  RS4024: 'メールボックスは登録されていません',//邮箱未注册
  RS4025: "ユーザー名が登録されていません",//用户名未注册
  RS4026: '元のパスワードが正しくありません',//原密码错误
  RS4027: 'パスワードの変更に失敗しました',//修改密码失败
  RS4029: "ユーザIDがNULLです",//用户ID为空
  RS4030: '認証コードがNULLです',//验证码为空
  RS4031: 'メールボックスが空です',//邮箱为空
  RS4032: 'メールボックスのフォーマットが正しくありません',//邮箱格式不正确
  RS4033: 'メールボックスのフォーマットが正しくありません',//邮箱格式不正确
  RS4034: "ウィチャットはバインドされていません",//微信没有绑定
  RS4035: 'ウィチャットのバインドに失敗しました',//绑定微信失败
  RS4036: '携帯番号のバインドに失敗しました',//绑定手机号失败
  RS4037: 'メールボックスのバインドに失敗しました',//绑定邮箱失败
  RS4038: '最大回数を超える認証コードの送信',//发送验证码超过最大次数
  RS4039: '登録に失敗しました',//注册失败
  RS4040: "ウィチャットがユーザーをバインドしました",//微信已绑定用户
  RS4041: "ユーザー名を変更する権限がありません（生成された匿名ユーザーにのみ変更）",//没有权限修改用户名（仅针对生成的匿名用户修改）
  RS4042: "USER_NOT_FB_BINDユーザーはfacebookをバインドしていません",//USER_NOT_FB_BIND 用户没有绑定facebook
  RS4043: "USER_FAIL_FB_BINDユーザがfacebookをバインドできませんでした",//USER_FAIL_FB_BIND 用户绑定facebook失败
  RS4044: "USER_NOT_GG_BINDユーザーはgoogleバインドを持っていません",//USER_NOT_GG_BIND 用户没有google绑定
  RS4045: "USER_FAIL_GG_BINDユーザーバインドgoogleに失敗しました",//USER_FAIL_GG_BIND 用户绑定google失败
  RS4046: "Lineアカウントがバインドされていません",//Line账户未绑定
  RS4047: 'Lineアカウントのバインドに失敗しました',//Line账户绑定失败
  RS4100: "デバイスが不正に追加されていません",//设备非法不允许添加
  RS4101: "デバイスは既に存在します",//设备已存在
  RS4102: 'デバイスの削除に失敗しました',//删除设备失败
  RS4103: '変更に失敗しました',//修改失败
  RS4104: 'デバイスuuidパラメータ異常',//设备uuid参数异常
  RS4105: 'デバイスユーザ名パラメータ異常',//设备用户名参数异常
  RS4106: "デバイスパスワードパラメータ異常",//设备密码参数异常
  RS4116: "デバイスマスタアカウントを他のユーザーに渡すことに失敗しました。ユーザーがデバイスを所有し、そのユーザーがデバイスマスタアカウント権限を持っているかどうかをチェックします",//将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限
  RS4117: 'メインアカウントではありません',//不是主账户
  RS4118: "ユーザーはこのデバイスを持っていません",//用户没有此设备
  RS4119: "追加デバイスが一意ではなく、他のアカウントが追加されました",//添加设备不唯一，其它账户已添加
  RS4120: "デバイス最大制限の追加（100）",//添加设备最大限制(100个)
  RS4126: 'デバイスサポートトークンは1つのアカウントにしか追加できません',//设备支持令牌只有一个账户添加
  RS4127: "デバイストークンがありません",//缺少设备令牌
  RS4300: "送信に失敗しました",//发送失败
  RS4302: 'ログアウト検証コードの送信に成功しました',//发送注销验证码成功
  RS4400: "SMSインタフェースの検証に失敗しました。ご連絡ください。",//短信接口验证失败，请联系我们
  RS4401: "ショートメッセージインタフェースのパラメータが間違っているので、ご連絡ください",//短信接口参数错误，请联系我们
  RS4402: '携帯番号ごとに1日3回しかメールを送信できない',//每个手机号一天只能发送三次短信
  RS4403: "送信に失敗しました。後で再試行してください",//发送失败，请稍后再试
  RS4404: "送信頻度が高すぎるので120秒間隔でお願いします",//发送太频繁了，请间隔120秒
  RS4405: "送信に失敗しました",//发送失败
  RS5000: "サーバ障害",//服务器故障
  RS5001: "証明書は存在しません",//证书不存在
  RS5002: "要求ヘッダ情報エラー",//请求头信息错误
  RS5003: "証明書の有効期限",//证书失效
  RS5004: "鍵検証エラーの生成",//生成密钥校验错误
  RS5005: 'パラメータ異常',//参数异常
  RS5009: 'ウィーチャットcodeエラー、AES加復号エラー',//微信code错误、AES加解密错误
  RS5010: 'tokenは期限切れです',//token 已过期
  RS5011: 'トークンエラー',//token 错误
  RS5012: 'token権限なし',//token 无权限
  RS5013: "サポートされていません",//不支持
  RS5014: "操作が頻繁すぎる",//操作太频繁
  RS4050: "操作が頻繁すぎる",//操作太频繁
  phoneCodeTip: "取得した携帯電話の認証コードを入力してください",//======请输入获取的手机验证码
  phoneNotFind: "ご入力いただいた携帯電話番号は存在しませんので、ご確認ください",//=====您输入的手机号码不存在，请检查
  phoneFormatError: "携帯番号のフォーマットが間違っているので、チェックしてください",//======手机号码格式错误，请检查
  phoneSendSuccess: "認証コードの送信に成功しました。確認してください",//=======验证码发送成功请注意查收
  hadPhone: "登録された携帯電話番号はすでに存在します", //======您的注册手机号码已存在
  phoneRule: "携帯電話番号を入力してください",//======请输入手机号码


  useNameTip:"4-15ビット文字（中国語、英字、数字、_和-里の2つ以上の構成", //4-15位字符，可由中文、数字、_和-组成
    passwordTip:"8-32ビットの文字長。英字、数字、アンダースコア、-内の2つ以上から構成できます", //8-32位字符长度，由英文字母、数字、下划线和-组成
    NameLength: "255ビット以下の大、小文字、数字、中国語からなるデバイス名を入力してください",
    UnameLength: "255ビット以下の大、小文字、数字、中国語からなるデバイスユーザー名を入力してください」",
    placeTip:"シリアル番号を入力してください",
    deleteAll:"一括削除",

    // nettimeout:"网络超时",
  accessTokenfail:"サービスのタイムアウト、accessTokenの取得に失敗しました",
  userinfo2faile:"サービスのタイムアウト、ユーザー情報の取得に失敗しました",
  getcodefail:"サービスがタイムアウトし、認証コードの取得に失敗しました",
  getmailfail:"サービスのタイムアウト、変更に失敗しました",
  getdevicefail:"サービスがタイムアウトし、デバイスリストを取得できませんでした",
  getareacodefail:"サービスがタイムアウトし、エクステント番号リストを取得できませんでした",
  rulenamefail:"サービスのタイムアウト、ユーザー名の検証に失敗しました",
  rulephonefail:"サービスのタイムアウト、ユーザーの携帯番号の検証に失敗しました",
  ruleregisterfail:"サービスのタイムアウト、登録に失敗しました",
  ruleemailfail:"サービスのタイムアウト、メールボックスの検証に失敗しました",
  getdevicestatusfail:"サービスがタイムアウトし、デバイスの状態を取得できませんでした",

  oldequipmentpassword:"元のパスワード",
  oldpasswordrule:"デバイスの元のパスワードを入力してください、なければ入力する必要はありません",
  newequipmentpassword:"新しいパスワード",
  newpasswordrule:"デバイスの新しいパスワードを入力してください、なければ入力する必要はありません",
  confirmequippassword:"パスワードの確認",
  confirmeqpasswordrule:"新しいパスワードと一致させてください",
};
