module.exports = {
  //2022-6 新增
  cloudinfo: {
    normal: "Нормальное использование",
    timeOut: "истекший",
    nonactivated: "неактивированный",
    notSupport: "не поддерживается",
    noRemainingFlow: "Не осталось потока",
    VipStatus: "VIP-статус",
    totalFlow: "общий объем данных сотовой связи",
    residualFlow: "остаточный поток",
    expireDate: "Дата истечения срока годности"
  },
  //新增
  cloud: "облачное хранилище",
  cloudTimeOut: "Облачное хранилище (срок действия истек)",
  cloudNormal: "Облачное хранилище (используется) ",
  cloudNonActivated: "Облачное хранилище (не включено) ",
  cloudnotSupported:"Облачное хранилище (не предоставляется) ",
  vipTimeOut: "4G - трафик (просрочен) ",
  vipNormal: "4G - трафик (используется) ",
  vipNonActivated: "4G - трафик (не открыт) ",
  vipnotSupported:"4G - трафик (не предоставляется) ",
  // 俄语
  userLogin: "Логин пользователя", //用户登录
  equipLogin: "Войти устройства", //设备登录
  login: "Авторизоваться", //登录
  regist: "создать аккаунт", //立即注册
  findPassword: "забыть пароль", //找回密码
  userRule: "пожалуйста, введите имя пользователя", //请输入用户名
  passwordRule: "Пожалуйста, введите пароль", //请输入密码
  codeRule: "пожалуйста, введите проверочный код", //请输入验证码
  equipIdRule: "Пожалуйста, введите серийный номер устройства", //请输入设备序列号
  appDownload: "APP скачать", //App 下载
  webUnitDownload: "ActiveX скачать", //Web控件下载
  callUs: "связаться с нами", //联系我们
  officialEdition: "обычный Версия", //经典版
  proEdition: "PRO Версия", //PRO版
  codeLenRule: "Пожалуйста, введите 4-значный проверочный код", //请输入4位验证码
  notEmpty: "Пожалуйста, не вводите пробелы", //请勿输入空格

  // 注册
  userRegist: "создать аккаунт", //创建账户
  emailRule: "введите свой адрес электронной почты", //请输入邮箱
  emailtrue: "Пожалуйста, введите номер почтового ящика в правильном формате", //请输入正确格式的邮箱号
  phonetrue:"Введите номер телефона в правильном формате",//请输入正确的手机号码
  confirmPasswordRule: "Пожалуйста, подтвердите пароль еще раз", //请再次输入密码
  hadCount: "Уже есть аккаунт", //已有账号，立即登录
  registCount: "Регистрация", //注册
  hadUser: "Ваше зарегистрированное имя пользователя уже существует", //您注册的用户名已存在
  hadMail: "Ваш зарегистрированный адрес электронной почты уже существует", //您注册的邮箱已存在
  sendSuccess: "Письмо успешно отправлено", //邮件发送成功
  mailFormatError: "Email Ошибка формата, пожалуйста, проверьте", //邮箱格式错误，请检查
  codeError: "Ошибка Проверочный код", //验证码错误
  registerSuccess: "Регистрация успех", //注册成功
  mailNotFind:
    "Введенный вами адрес электронной почты не существует, проверьте", //您输入的邮箱不存在，请检查
  userNameLen: "Пожалуйста, введите имя пользователя от 4 до 8 символов", //请输入4~8位用户名
  userPwdLen: "Пожалуйста, введите пароль из 8 ~ 32 символов", //请输入8~32位密码
  privacyPolicy:"Читать и выбирать политику конфиденциальности",//阅读并选择隐私政策
  emailRegistration:"Регистрация почтового ящика",//邮箱注册
  mobileRegistration:"Регистрация мобильного телефона",//手机注册

  // 找回密码
  phoneRetrievePassword:"Верните код телефона",//手机找回密码
  mailboxRetrievalPassword:"Найдите пароль для почтового ящика",//邮箱找回密码
  findThePassword: "забыть пароль", //找回密码
  passwordText:
    "Пожалуйста, введите адрес электронной почты, который использовался при регистрации аккаунта, чтобы принять проверочный код.", //请输入注册账号时使用的邮箱来找回密码
  exit: "уволиться", //退出
  nextStep: "Следующий шаг", //下一步
  reset: "Сброс", //重新设置
  getCode: "Отправить", //获取验证码
  someTime: "Повторно отправить", //秒后重新获取
  ok: "подтверждать", //确定
  mailSendMuch:
    "Каждый почтовый ящик может отправлять не более трех электронных писем в день.", //邮件每个邮箱一天只能发送三次
  mailChangeSuccess: "Успешно изменено", //修改成功
  pwdError: "Неправильный формат пароля", //密码格式不正确
  codeTimeout: "Проверочный код истек", //验证码过期
  codeErrorMuch: "Слишком много ошибок. Повторите попытку позже.", //错误次数过多,请稍后再试
  backLogin: "Вернуться на страницу входа", //返回登录
  mailFast:
    "Повторные письма не допускается в течение короткого периода времени", //短时间内不允许重复发送邮件

  //主页面
  refresh: "Обновить", //刷新
  myDevice: "Мое устройство", //我的设备
  personalInformation: "личные данные", //个人信息
  deviceManagement: "Управление оборудованием", //设备管理
  VIPTraffic:"VIP - трафик",//VIP流量
  changePassword: "Измени пароль", //修改密码
  changePasswordSuccess: "успешно сменить пароль", //修改密码成功
  LogOut: "выйти", //退出登录
  shurLogOut: "Вы уверены, что хотите выйти", //你确定要退出吗
  oldPassword: "Прежний пароль", //旧密码
  newPassword: "новый пароль", //新密码
  inputNewPassword: "Введите новый пароль еще раз", //再次输入新密码
  errorRefresh: "Не удалось обновить", //刷新失败
  online: "онлайн", //在线
  offline: "Офлайн", //离线
  UidLength:
    "Введите 16- или 20-значный серийный номер устройства, состоящий из прописных и строчных букв и цифр.", //请输入16或20位由大,小写字母,数字组成的设备序列号
    PwordLength: "Введите не более 100 бит пароля устройства, состоящего из больших, нижних букв и цифр ",
    passwordrule: "Не вводите специальные символы", //请勿输入特殊符号
  equipOffline: "Устройство временно не в сети. Выберите другое устройство.", //设备暂时不在线请选择其它设备吧
  BrowserSupport:
    "Этот браузер не поддерживает воспроизведение подключаемых модулей, используйте Google Chrome.", //此浏览器不支持插件播放，请使用IE或360浏览器
  embedBrowserFail:
    "Falha ao vincular o navegador, não mude de página rapidamente ao fazer login", //绑定浏览器失败，请在登录时勿快速切换页面
  noPlugin: "Plugin não detectado", //未检测到插件

  CancelAccount: "Аннулировать аккаунт",
  CancelAccountTitle: "Вы уверены, что хотите удалить учетную запись?",
  CancelSuccess: "Успешно аннулировать аккаунт",
  // 设备列表、
  order: "SN", //序号
  equipmentPwd: "Пароль устройства", //设备密码
  equipmentOrder: "серийный номер", //序列号
  equipmentName: "Название оборудования", //名称
  equipmentUser: "имя пользователя", //设备用户名
  option: "Операция", //操作
  addEquipment: "Добавить устройство", //添加设备
  changeEquipmentMessage: "Изменить информацию", //修改设备信息
  changePasswordMessage:"Изменить пароль",//修改密码信息
  deleteEquipment:"Удалить устройство",//删除设备
  delmessage: "Вы уверены, что хотите удалить?", //你确定要删除吗
  all: "Всего", //共
  tips: "данные", //条数据
  add: "добавлять", //添加
  equipmentSuccess: "Информация об устройстве успешно изменена", //设备信息修改成功
  addUid:
    "Пожалуйста, введите серийный номер устройства, которое вы хотите добавить", //请输入你要添加的设备序列号
  addid: "Пожалуйста, введите серийный номер, который вы хотите добавить", //请输入你要添加的序号
  addname: "Пожалуйста, введите имя устройства, которое вы хотите добавить", //请输入你要添加的设备名
  addUsername:
    "Пожалуйста, введите имя пользователя устройства, которое вы хотите добавить", //请输入你要添加的设备用户名

  // 用户信息
  userinfo: "Информация о пользователе", //用户信息
  userName: "имя пользователя", //用户名
  usercontact: "Способ контакта", //联系方式
  useremail: "Эл. адрес", //电子邮箱

  // 全局提示
  successLogin: "Вход выполнен успешно", //登录成功
  errorLogin1: "Войти не удалось, имя пользователя или пароль неправильный", //登录失败，用户名或密码错误
  errorLogin2: "Ошибка входа, ошибка кода подтверждения", //登录失败，验证码错误
  errorLogin3: "Войти не удалось, код проверки истек", //登录失败，验证码过期
  errorLogin4:
    "Слишком много ошибок входа, учетная запись заблокирована на 10 минут", //登录失败次数过多，账户锁定10分钟
  delsucess: "успешно удален", //删除成功
  addSuccess: "Добавлено успешно", //添加成功
  addFail: "Не удалось добавить", //添加失败
  timeOut: "Тайм-аут входа, автоматический переход на страницу входа", //登录超时，自动跳转登录页
  changeEquipmentPwdError: "Исходный пароль устройства был введен неверно", //原设备密码输入错误

  // 网络提示
  accessnot: "Доступ запрещен", //访问被拒绝
  resourcenot: "Ресурс недоступен", //资源不可用
  nopage: "Страница не найдена", //找不到页面
  methoderror: "Методы не допускаются", //不允许此方法
  serveerror: "Ошибка сервера", //服务器错误
  unknownerror: "неизвестная ошибка", //未知错误

  // 表单提示
  emailTip: "**@**.***",
  phoneTip:"Пожалуйста, введите правильный номер телефона",
  confirmTip: "Пожалуйста, будьте последовательны с паролем", //请与密码保持一致
  equipmentIdTip: "Введите 16- или 20-значный серийный номер устройства.", //请输入16或20位设备序列号
  equipmentUserTip: "Пожалуйста, введите имя пользователя устройства", //请输入设备用户名
  equipmentPasswordTip1: "Пожалуйста, введите пароль устройства", //请输入设备密码
  equipmentPasswordTip:
    "Пожалуйста, введите пароль устройства, вам не нужно вводить его, если у вас его нет", //请输入设备密码，没有则无需输入
  codeTip: "Пожалуйста, введите полученный код подтверждения электронной почты", //请输入获取的邮箱验证码
  equipmentPwdTip: "Пароль устройства является пустым по умолчанию", //设备密码默认为空
  equipmentNameTip: "Пожалуйста, введите имя устройства", //请输入设备名称
  equipmentUserNameTip: "Имя пользователя устройства по умолчанию - «admin».", //设备用户名默认为“admin”
  pagetip1:
    "Щелкните устройство в «Мое устройство» слева, чтобы просмотреть, воспроизвести, настроить и другие операции на устройстве.", //单击左边“我的设备”中的设备，对设备进行预览，回放，配置等操作
  pagetip2:
    "Если вы посещаете первый раз, загрузите плагин, который может занять несколько минут, пожалуйста, подождите", //如果您是第一次访问，会自动下载插件，可能需要花费几分钟时间，请耐心等待
  passwordChangeTip: "Если вы не меняете пароль, оставьте поле пустым.", //若不修改密码请保持为空
  loginUseNameTip:
    "Можно использовать имя пользователя, адрес электронной почты, мобильный телефон, чтобы войти", //可使用用户名、邮箱号、手机号登录

  placeholderUser: "Имя пользователя / Email / Mobile", //用户名/邮箱号/手机号
  placeholderPassword: "пароль", //密码
  placeholderCode: "Код верификации", //验证码
  placeholderEquipId: "серийный номер", //设备序列号
  placeholderEquipAdmin: "Имя пользователя устройства", //设备用户名
  placeholderEquipPassword: "Пароль устройства", //设备密码
  placeholderRegistUser: "имя пользователя", //用户名
  placeholderRegistMail: "Номер почтового ящика", //邮箱
  placeholderRegistPhone: "Мобильные телефоны ", //手机
  placeholderRegistCode: "Код верификации", //验证码
  placeholderRegistPwd: "пароль", //密码
  placeholderRegistPwd2: "Подтвердите пароль", //确认密码

  supportTitle: "Контактная информация службы технической поддержки", //技术支持联系方式
  supportPhone: "Горячая линия обслуживания", //服务热线
  supportMail: "Служебный почтовый ящик", //服务邮箱
  supportTime:
    "Время обслуживания: с понедельника по субботу 8: 30-17: 30 (кроме государственных праздников)", //服务时间：周一到周六8:30--17:30（法定节假日除外）
  userPwdRule: "Неправильный формат пароля", //密码格式错误
  userNameRule: "Неправильный формат имени пользователя", //用户名格式错误
  Ok: "подтвердить", //确认
  cancel: "отменить", //取消
  backOld: "Старая версия", //回到旧版

  cookie:
    "Наш веб-сайт использует файлы cookie, чтобы улучшить наш веб-сайт и улучшить взаимодействие с пользователем. Если вы продолжаете просматривать этот веб-сайт без изменения настроек файлов cookie в своем браузере, вы соглашаетесь с нашей политикой использования файлов cookie.", //我们的网站使用cookies以改进我们的网站和改善用户体验。如您继续浏览本网站而未更改浏览器cookie设置，视为同意我们的cookies使用策略。
  cookieLink: "политика", //政策
  agree: "Согласен", //同意
  notAgree: "не согласен", //不同意

  cookiePolicy: "Политика использования файлов cookie", //Cookie 政策
  cookieUse: "Как мы используем файлы cookie и аналогичные технологии", //我们如何使用Cookie和同类技术
  cookieWhy:
    "Чтобы обеспечить нормальную работу веб-сайта, мы будем хранить небольшие файлы данных, называемые файлами cookie, на вашем компьютере или мобильном устройстве. Файлы cookie обычно содержат идентификаторы, названия сайтов, а также некоторые числа и символы. С помощью файлов cookie веб-сайты могут хранить такие данные, как ваши предпочтения.", //为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据档。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。
  cookieFor:
    "Мы не будем использовать файлы cookie для каких-либо целей, кроме тех, которые указаны в этой политике. Вы можете управлять файлами cookie или удалять их в соответствии с вашими предпочтениями. Подробнее см. AboutCookies.org. Вы можете удалить все файлы cookie, сохраненные на вашем компьютере, и большинство веб-браузеров имеют функцию блокировки файлов cookie. Но если вы это сделаете, вам нужно будет лично изменять пользовательские настройки каждый раз, когда вы посещаете наш сайт. Чтобы узнать больше о том, как изменить настройки браузера, посетите соответствующую страницу настроек браузера, который вы используете. Однако обратите внимание, что если вы не принимаете файлы cookie, вы не сможете в полной мере использовать некоторые функции веб-сайта.", //我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。但请注意，如果您不接受cookie，可能无法全面体验网站的某些功能。
  cookieList:
    "Ниже приводится список типов файлов cookie, используемых веб-сайтом:", //以下是网站使用的cookie种类列表：
  cookiePurpose: "Цель", //目的
  cookieDescribe: "описать", //描述
  cookieType: "Тип и срок действия", //种类和失效时间
  cookiePerformance: "Производительность (пользовательский браузер)", //性能（用户浏览器）
  cookieLogin:
    "Запишите статус входа пользователя, и нет необходимости повторно входить в систему в течение 7 дней", //记录用户登录状态，以及7天内不用重新登录
  cookieDay: "7 дней", //7天
  cookieCnzz: "CNZZ", //CNZZ
  cookieTime: "Запишите, сколько раз пользователь посещал веб-сайт.", //记录用户访问网站的次数
  cookieForever: "постоянный", //永久
  cookieTrack:
    "Многие веб-браузеры имеют функцию «Не отслеживать», которая может отправлять запросы на «Не отслеживать» на веб-сайты. В настоящее время основные организации по стандартизации Интернета не разработали соответствующих политик, регулирующих реакцию веб-сайтов на такие запросы. Но если в вашем браузере включен режим «Не отслеживать», наш веб-сайт учтет ваш выбор.", //很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do NotTrack请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站都会尊重您的选择。
  backHome: "вернуться на главную", //回到首页
  // RS
  RS2000: 'Успешное осуществление',//执行成功
  RS4000: "Ошибка имени пользователя или пароля",//用户名或密码错误
  RS4010: 'Ошибка кода проверки',//验证码错误
  RS4011: 'Пароль не совпадает',//密码不一致
  RS4012: "Имя пользователя зарегистрировано",//用户名已被注册
  RS4013: "Имя пользователя Пустое ",//用户名为空
  RS4014: 'Пароль пустой.',//密码为空
  RS4015: 'Подтвердить пустой пароль',//确认密码为空
  RS4016: ' Номер телефона пустой',//手机号为空
  RS4017: " Неверный формат имени пользователя",//用户名格式不正确
  RS4018: 'Неверный формат пароля',//密码格式不正确
  RS4019: 'Подтвердить неправильный формат пароля ',//确认密码格式不正确
  RS4020: 'Формат телефона неправильный',//手机号格式不正确
  RS4021: ' Номер телефона зарегистрирован',//手机号已注册
  RS4022: ' Номер телефона не зарегистрирован ',//手机号未注册
  RS4023: 'Почтовый ящик зарегистрирован',//邮箱已注册
  RS4024: 'Почтовый ящик не зарегистрирован',//邮箱未注册
  RS4025: "Имя пользователя не зарегистрировано",//用户名未注册
  RS4026: 'Ошибка исходного пароля ',//原密码错误
  RS4027: 'Ошибка изменения пароля ',//修改密码失败
  RS4029: "Идентификатор пользователя пуст ",//用户ID为空
  RS4030: 'Код проверки пуст ',//验证码为空
  RS4031: 'Почтовый ящик пуст',//邮箱为空
  RS4032: 'Неверный формат почтового ящика ',//邮箱格式不正确
  RS4033: 'Неверный формат почтового ящика ',//邮箱格式不正确
  RS4034: "Микросхема нет",//微信没有绑定
  RS4035: 'Ошибка привязки микрофона',//绑定微信失败
  RS4036: 'Ошибка привязки номера телефона ',//绑定手机号失败
  RS4037: 'Ошибка привязки почтового ящика',//绑定邮箱失败
  RS4038: 'Отправить код проверки больше максимального количества раз ',//发送验证码超过最大次数
  RS4039: 'Ошибка регистрации ',//注册失败
  RS4040: "Микросхемы привязывают пользователей ",//微信已绑定用户
  RS4041: "Нет прав изменять имя пользователя (только для генерируемых анонимных пользователей) ",//没有权限修改用户名（仅针对生成的匿名用户修改）
  RS4042: "США  НОТ  ФБ  Пользователи BIND не привязаны к Facebook ",//USER_NOT_FB_BIND 用户没有绑定facebook
  RS4043: "США  Файл  ФБ  Ошибка привязки пользователей BIND к Facebook ",//USER_FAIL_FB_BIND 用户绑定facebook失败
  RS4044: "США  НОТ  GG  У пользователей BIND нет привязок к Google ",//USER_NOT_GG_BIND 用户没有google绑定
  RS4045: " США  Файл  GG  Ошибка привязки пользователей BIND к Google ",//USER_FAIL_GG_BIND 用户绑定google失败
  RS4046: "Счет Line не привязан ",//Line账户未绑定
  RS4047: 'Ошибка привязки учетной записи Line ',//Line账户绑定失败
  RS4100: "Устройство незаконно не разрешено добавлять ",//设备非法不允许添加
  RS4101: "Оборудование уже существует ",//设备已存在
  RS4102: 'Ошибка удаления устройства ',//删除设备失败
  RS4103: 'Ошибка изменения ',//修改失败
  RS4104: 'Необычные параметры UUID устройства ',//设备uuid参数异常
  RS4105: ' Необычные параметры имени пользователя устройства ',//设备用户名参数异常
  RS4106: " Параметры пароля устройства ненормальные ",//设备密码参数异常
  RS4116: " Невозможно передать основную учетную запись устройства другому пользователю, проверить, имеет ли пользователь устройство и имеет ли пользователь права на основную учетную запись устройства",//将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限
  RS4117: 'Не основной счет',//不是主账户
  RS4118: "У пользователя нет этого устройства",//用户没有此设备
  RS4119: "Добавлено не только устройство, добавлены другие учетные записи ",//添加设备不唯一，其它账户已添加
  RS4120: " Максимальное ограничение для добавления устройств (100) ",//添加设备最大限制(100个)
  RS4126: 'Токены поддержки устройств могут быть добавлены только одним аккаунтом ',//设备支持令牌只有一个账户添加
  RS4127: "Отсутствие маркеров оборудования ",//缺少设备令牌
  RS4300: "Ошибка отправки ",//发送失败
  RS4302: 'Отправить код отмены успешно ',//发送注销验证码成功
  RS4400: "Ошибка проверки интерфейса SMS, пожалуйста, свяжитесь с нами.",//短信接口验证失败，请联系我们
  RS4401: "Ошибка параметров интерфейса SMS, пожалуйста, свяжитесь с нами ",//短信接口参数错误，请联系我们
  RS4402: 'Каждый номер телефона может отправлять только три сообщения в день',//每个手机号一天只能发送三次短信
  RS4403: "Не удалось отправить, попробуйте позже ",//发送失败，请稍后再试
  RS4404: " Отправить слишком часто, пожалуйста, с интервалом 120 секунд ",//发送太频繁了，请间隔120秒
  RS4405: "Ошибка отправки ",//发送失败
  RS5000: " Сбой сервера ",//服务器故障
  RS5001: "Сертификат не существует ",//证书不存在
  RS5002: "Ошибка заголовка запроса ",//请求头信息错误
  RS5003: " Действие сертификата ",//证书失效
  RS5004: "Ошибка проверки ключа генерации ",//生成密钥校验错误
  RS5005: 'Параметры аномалии ',//参数异常
  RS5009: ' Ошибка кодирования микроканалов, ошибка расшифровки AES ',//微信code错误、AES加解密错误
  RS5010: 'Токен просрочен',//token 已过期
  RS5011: 'Ошибка токена ',//token 错误
  RS5012: ' Токен без прав ',//token 无权限
  RS5013: "Не поддерживается ",//不支持
  RS5014: "Слишком часто",//操作太频繁
  RS4050: "Слишком часто",//操作太频繁
  phoneCodeTip: "Введите полученный код проверки мобильного телефона",//======请输入获取的手机验证码
  phoneNotFind: "Введённый вами номер телефона не существует, проверьте ",//=====您输入的手机号码不存在，请检查
  phoneFormatError: "Ошибка формата номера телефона, проверьте ",//======手机号码格式错误，请检查
  phoneSendSuccess: "Код успешно отправлен. Пожалуйста, обратите внимание.",//=======验证码发送成功请注意查收
  hadPhone: " Ваш зарегистрированный номер мобильного телефона уже существует.", //======您的注册手机号码已存在
  phoneRule: "Пожалуйста, введите номер телефона.",//======请输入手机号码


  useNameTip:"4 - 15 - битные символы, которые могут быть сделаны из китайского языка, букв, цифр,  Два и более членов ", //4-15位字符，可由中文、数字、_和-组成
  passwordTip:"8 - 32 - битная длина символа, может состоять из английских букв, цифр, подчёркивания и - внутри и выше ", //8-32位字符长度，由英文字母、数字、下划线和-组成
  NameLength: "Введите не более 255 - битных имен устройств, состоящих из больших, прописных букв, цифр, китайского языка ",
  UnameLength: "Введите не более 255 - битного имени пользователя устройства, состоящего из больших, прописных букв, цифр, китайского языка.",
  placeTip:"Введите серийный номер",
  deleteAll:"Удаление пакетов ",

  // nettimeout:"网络超时",
  accessTokenfail:"Время ожидания службы, ошибка получения AccessToken ",
  userinfo2faile:"Время ожидания службы, сбой в получении информации пользователя ",
  getcodefail:"Время ожидания службы, ошибка получения кода проверки ",
  getmailfail:"Время ожидания службы, ошибка изменения ",
  getdevicefail:"Время ожидания службы, невозможно получить список устройств ",
  getareacodefail:"Время ожидания службы, невозможно получить список номеров районов ",
  rulenamefail:"Время ожидания службы, ошибка проверки имени пользователя ",
  rulephonefail:"Время ожидания службы, ошибка проверки номера мобильного телефона пользователя ",
  ruleregisterfail:"Превышение времени обслуживания, регистрация не удалась ",
  ruleemailfail:"Время ожидания службы, ошибка проверки почтового ящика ",
  getdevicestatusfail:"Время ожидания службы, невозможно получить состояние устройства ",

  oldequipmentpassword:"Оригинальный пароль ",
  oldpasswordrule:"Введите оригинальный пароль устройства, если нет, не нужно вводить ",
  newequipmentpassword:"Новый пароль ",
  newpasswordrule:"Введите новый пароль устройства, если нет, не нужно вводить ",
  confirmequippassword:"Подтвердить пароль ",
  confirmeqpasswordrule:"Пожалуйста, свяжитесь с новым паролем. ",
};
