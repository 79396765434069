<template>
  <a-modal
    :visible="visibleUser"
    :footer="null"
    :closable="false"
    :maskClosable="true"
    @cancel="handleUserCancel"
    width="50%"
    class="changetitle"
    :centered="true"
  >
    <a-descriptions :title="$t('userinfo')" bordered size="default">
      <a-descriptions-item :label="$t('userName')" :span="3">
        {{ userName }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('usercontact')" :span="3">
        {{ userPhone }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('useremail')" :span="3">
        {{ userEmail }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('cloudinfo.VipStatus')" :span="3">
        <span @click="toCloudBuy">
          <a-tag color="green" v-if="info.datStatus === 1">
            <img style="width: 30px" src="../../assets/image/VIP.png" alt="" />
          </a-tag>
          <a-tag color="red" v-else-if="info.datStatus === 2">
            {{ $t("cloudinfo.timeOut") }}</a-tag
          >
          <a-tag color="orange" v-else-if="info.datStatus === 3">
            {{ $t("cloudinfo.nonactivated") }}
          </a-tag>
          <a-tag color="cyan" v-else> {{ $t("cloudinfo.noRemainingFlow") }} </a-tag>
        </span>
        <span v-if="info.datStatus === 4">
          <a-tag color="grey">
            {{ $t("cloudinfo.notSupport") }}
          </a-tag>
        </span>
      </a-descriptions-item>
      <template v-if="info.datStatus === 1">
        <a-descriptions-item :label="$t('cloudinfo.totalFlow')" :span="3"
          >{{ info.datAvailable }}GB
        </a-descriptions-item>
        <a-descriptions-item :label="$t('cloudinfo.residualFlow')" :span="3">
          {{ info.datSurplus }}GB
        </a-descriptions-item>
        <a-descriptions-item :label="$t('cloudinfo.expireDate')" :span="3">
          {{ info.datExpirationTime }}
        </a-descriptions-item>
      </template>
    </a-descriptions>
  </a-modal>
</template>

<script>
import { showmessage } from "../../utils/showMessage";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      visibleUser: false,
      info: {},
      userName: "",
      userPhone: "",
      userEmail: "",
      // datStatus: 4,
      accessToken: "",
      sn: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {
    showUserInfo() {
      this.visibleUser = true;
      //   ;
      this.getUseMessage();
      this.getCloudToken();
    },
    // 获取用户信息
    getUseMessage() {
      const value = {
        Authorization: this.$store.state.accessToken.accessToken,
        name: this.$store.state.userInfo.uname,
        password: this.$store.state.userInfo.upass,
      };
      this.$http.post("/transfer/userinfo2/v1", value).then((res) => {
        if(res){
          if (res.code == 2000) {
          // console.log("用户信息", res.data);
          this.userName = res.data.username;
          this.userPhone = res.data.phone;
          this.userEmail = res.data.mail;
          this.sn = res.data.userId;
          this.getUserInfoData(res.data.userId);
        } else {
          this.$message.destroy();
          this.$message.error(this.$t("" + showmessage(res)));
        }
        }else{
          this.$message.destroy();
          this.$message.error(this.$t("userinfo2faile"));
        }
      });
    },
    /**
     * @description 取消显示表
     */
    handleUserCancel() {
      this.visibleUser = false;
    },
    /**
     * @description
     */
    getUserInfoData(id) {
      let arr = []
      arr.push(id)
      const params = {
        ver: 2,
        sn: arr,
        caps: ["xmc.css", "net.cellular", "net.dat"],
      };
      this.$http.post("/caps/status", params).then((res) => {
        if(res){
          this.info = res.data.capsData[0].datVo;
        }else{
          this.$message.destroy();
          this.$message.error(this.$t("userinfo2faile"));
        }
        
        //this.info = res.data.capsData[0].datVo;
        // console.log('this.info',this.info)
        // this.datStatus = res.data.datVo.datStatus;
      });
    },

    //获取云存储Token
    getCloudToken() {
      const value = {
        account: this.$store.state.userInfo.uname,
        pass: this.$store.state.userInfo.upass,
      };
      console.log('geilogintoken userinfo')
      this.$http.post("/transfer/getLoginToken", value).then((res) => {
        this.accessToken = res.accessToken;
      });
    },
    //跳转到云存储界面
    toCloudBuy() {
      let appKey = "3620c5b0249341058d270c2bb62047f7";
      window.open(
        `https://boss22-api.xmcsrv.net/index?authorization=${this.accessToken}&appKey=${appKey}&sn=${this.sn}&channel=`
      );
    },
  },
};
</script>

<style lang="less" scoped></style>
