module.exports = {
  //2022-6 新增
  cloudinfo: {
    normal: "Normal use",
    timeOut: "Expired",
    nonactivated: "Nonactivated",
    notSupport: "Not support",
    noRemainingFlow: "No remaining flow",
    VipStatus: "Vip status",
    totalFlow: "Total flow",
    residualFlow: "Residual flow",
    expireDate: "Expire date"
  },
  //新增
  cloud: "cloud storage",
  cloudTimeOut: "Cloud storage (expired)",
  cloudNormal: "Cloud storage (in use)",
  cloudNonActivated: "Cloud storage (not opened)",
  cloudnotSupported:"Cloud storage (not available)",
  vipTimeOut: "4G traffic (expired)",
  vipNormal: "4G traffic (in use)",
  vipNonActivated: "4G traffic (not opened)",
  vipnotSupported:"4G traffic (not available)",
  // 英语
  // 登录页
  userLogin: "User Login", //用户登录
  equipLogin: "Device Login", //设备登录
  login: "Login", //登录
  regist: "Sign Up Now", //立即注册
  findPassword: "Forgot Password", //忘记密码
  userRule: "Please enter the user name", //请输入用户名
  passwordRule: "Please enter the user password", //请输入密码
  codeRule: "Please enter the verification code", //请输入验证码
  equipIdRule: "Please enter the device serial number", //请输入设备序列号

  appDownload: "App Download", //App 下载
  webUnitDownload: "ActiveX Download", //Web控件下载
  officialEdition: "Classic Version", //经典版
  proEdition: "Pro Version", //PRO版
  codeLenRule: "Please enter the 4-digit verification code", //请输入4位验证码
  notEmpty: "Do not enter spaces", //请勿输入空格
  callUs: "Contact Us", //联系我们

  // 注册
  userRegist: "Create Your Account", //用户注册
  emailRule: "Please enter the email", //请输入邮箱
  emailtrue: "Please enter the correct format of the email number", //请输入正确格式的邮箱号
  phonetrue:"Please enter a mobile phone number in the correct format",//请输入正确的手机号码
  confirmPasswordRule: "Please confirm your password", //请再次输入密码
  hadCount: "Sign In Now", //已有账号，立即登录
  registCount: "Regist", //注册
  hadUser: "Your registered user name already exists", //您注册的用户名已存在
  hadMail: "The email you registered already exists", //您注册的邮箱已存在
  sendSuccess: "Please check the email successfully", //邮件发送成功请注意查收
  mailFormatError: "Email format error, please check", //邮箱格式错误，请检查
  codeError: "Verification code error", //验证码错误
  registerSuccess: "Registration Success", //注册成功
  mailNotFind: "The mailbox you entered does not exist, please check", //您输入的邮箱不存在，请检查
  userNameLen: "Please enter a username of 4~8 digits", //请输入4~8位的用户名
  userPwdLen: "Please enter a password of 8~32 digits", //请输入8~32位的密码
  privacyPolicy:"Read and select the privacy policy",//阅读并选择隐私政策
  emailRegistration:"Email registration",//邮箱注册
  mobileRegistration:"Mobile registration",//手机注册
  // 找回密码
  phoneRetrievePassword:"Mobile phone to retrieve password",//手机找回密码
  mailboxRetrievalPassword:"Mailbox password retrieval",//邮箱找回密码
  findThePassword: "Forgot Password", //忘记密码
  passwordText:
    "Please enter the email address used when registering the account to retrieve the password", //请输入注册账号时使用的邮箱来找回密码
  exit: "Exit", //退出
  nextStep: "Next", //下一步
  reset: "Reset", //重新设置
  getCode: "Request", //获取验证码
  someTime: "Recapture", //秒后重新获取
  ok: "Submit", //确定
  mailSendMuch: "Each email can only be sent three times a day", //邮件每个邮箱一天只能发送三次
  mailChangeSuccess: "Modified Successful",
  pwdError: "Incorrect password format",
  codeTimeout: "Verification code expired", //验证码过期
  codeErrorMuch: "Too many errors, please try again later", //错误次数过多,请稍后再试
  backLogin: "Back to login", //返回登录
  mailFast: "Repetitive emails are not allowed within a short period of time", //短时间内不允许重复发送邮件

  //主页面
  refresh: "Refresh", //刷新
  myDevice: "My Device", //我的设备
  personalInformation: "Personal Information", //个人信息
  deviceManagement: "Device Management", //设备管理
  VIPTraffic:"VIP Traffic",//VIP流量
  changePassword: "Change Password", //修改密码
  changePasswordSuccess: "Password changed successfully", //修改密码成功
  LogOut: "Log Out", //退出登录
  shurLogOut: "Are you sure you want to quit?", //你确定要退出吗
  oldPassword: "Old Password", //旧密码
  newPassword: "New Password", //新密码
  inputNewPassword: "Confirm Password", //确认密码
  errorRefresh: "Refresh Error", //刷新失败
  online: "online", //在线
  offline: "offline", //离线
  UidLength:
    "Please enter a 16 or 20-digit device serial number consisting of uppercase and lowercase letters and numbers", //请输入16或20位由大,小写字母,数字组成的设备序列号
    PwordLength: "Please enter a device password with no more than 100 characters, consisting of upper and lower case letters and numbers",
    passwordrule: "Do not enter special symbols", //请勿输入特殊符号
  equipOffline:
    "The device is temporarily offline, please choose another device", //设备暂时不在线请选择其它设备吧
  BrowserSupport:
    "This browser does not support plug-in playback, please use Google Chrome", //此浏览器不支持插件播放，请使用IE浏览器
  embedBrowserFail:
    "Failed to bind the browser, please do not switch pages quickly when logging in", //绑定浏览器失败，请在登录时勿快速切换页面
  noPlugin: "Plugin not detected", //未检测到插件
  CancelAccount: "Cancel account",
  CancelAccountTitle: "Are you sure you want to delete the account?",
  CancelSuccess: "Account cancellation succeeded",

  // 设备列表、
  // order: "id",//序号
  equipmentPwd: "Device Password", //设备密码
  equipmentOrder: "SerialNO", //序列号
  equipmentName: "Device Name", //名称
  equipmentUser: "User Name", //用户名
  option: "Operate", //操作
  addEquipment: "Add Device", //添加设备
  changeEquipmentMessage: "Modify device information", //修改设备信息
  changePasswordMessage:"Modify password information",//修改密码信息
  deleteEquipment:"Delete device",//删除设备
  delmessage: "Are you sure you want to delete it", //你确定要删除吗
  addUid: "Please enter the serial number of the device you want to add", //请输入你要添加的设备序列号
  addid: "Please enter the serial number you want to add", //请输入你要添加的序号
  addname: "Please enter the name of the device you want to add", //请输入你要添加的设备名
  addUsername: "Please enter the user name of the device you want to add", //请输入你要添加的设备用户名
  all: "total ",
  tips: "datas",
  add: "Add",
  equipmentSuccess: "Device information modified successfully", //设备信息修改成功

  // 用户信息
  userinfo: "User Information", //用户信息
  userName: "User Name", //用户名
  usercontact: "Phone Number", //联系方式
  useremail: "Email", //电子邮箱

  // 全局提示
  successLogin: "Login successful", //登录成功
  errorLogin1: "Login failure, wrong user name or password", //登录失败，用户名或密码错误
  errorLogin2: "Login failed, error of verification code", //登录失败，验证码错误
  errorLogin3: "Login failed, verification code expired", //登录失败，验证码过期
  errorLogin4: "Too many login failures, account locked for 10 minutes", //登录失败次数过多，账户锁定10分钟
  delsucess: "Delete Successful", //删除成功
  addSuccess: "Added Successful", //添加成功
  addFail: "Add Failed", //添加失败
  timeOut: "Login timeout, automatically jump to login page", //登录超时，自动跳转登录页
  changeEquipmentPwdError: "Original device password input error", //原设备密码输入错误
  // 网络提示
  accessnot: "Access Was Denied", //访问被拒绝
  resourcenot: "Resource not available", //资源不可用
  nopage: "Page Not Found", //找不到页面
  methoderror: "This method is not allowed", //不允许此方法
  serveerror: "Server Error", //服务器错误
  unknownerror: "Unknown Error", //未知错误

  // 表单提示
  // useNameTip:"4-15 characters, which can be composed of Chinese, letters, numbers, _ and -", //4-15位字符，可由中文、数字、_和-组成
  emailTip: "**@**.***", //**@**.***
  phoneTip:"Please enter the correct mobile number",
  confirmTip: "Please keep the same password", //请与密码保持一致
  equipmentIdTip: "Please enter 16 or 20 digit serial number", //请输入16或20位设备序列号
  equipmentUserTip: "Please enter the device user name", //请输入设备用户名
  equipmentPasswordTip:
    "Please enter the device password, if not, you don’t need to enter it", //请输入设备密码，没有则无需输入
  equipmentPasswordTip1: "Please enter the device password", //请输入设备密码
  codeTip: "Please enter the obtained email verification code", //请输入获取的邮箱验证码
  equipmentPwdTip: "The device password is blank by default", //设备密码默认为空
  equipmentNameTip: "Please enter the device name", //请输入设备名称
  equipmentUserNameTip: "The device user name defaults to “admin”", //设备用户名默认为“admin”
  pagetip1:
    "Click the device in “My Device” on the left to preview, replay, configure and other operations on the device", //单击左边“我的设备”中的设备，对设备进行预览，回放，配置等操作
  pagetip2:
    "If this is your first visit, please download the plug-in. It may take several minutes. Please wait patiently", //如果您是第一次访问，会自动下载插件，可能需要花费几分钟时间，请耐心等待
  passwordChangeTip: "If you do not change the password, please leave it blank", //若不修改密码请保持为空
  loginUseNameTip:
    "You can login with user name, email number and mobile phone number", //可使用用户名、邮箱号、手机号登录

  placeholderUser: "Name / Email / Phone ", //用户名/邮箱号/手机号
  placeholderPassword: "Password", //密码
  placeholderCode: "Verify Code", //验证码
  placeholderEquipId: "Serial Number", //设备序列号
  placeholderEquipAdmin: "Device User Name", //设备用户名
  placeholderEquipPassword: "Device Password", //设备密码
  placeholderRegistUser: "Name", //用户名
  placeholderRegistMail: "Email", //邮箱
  placeholderRegistPhone: "Phone", //手机
  placeholderRegistCode: "Verify Code", //验证码
  placeholderRegistPwd: "Password", //密码
  placeholderRegistPwd2: "Confirm Password", //确认密码

  
  supportTitle: "Technical Support Contact Information", //技术支持联系方式
  supportPhone: "Service Hotline", //服务热线
  supportMail: "Service Mailbox", //服务邮箱
  supportTime:
    "Service time: 8:30-17:30 from Monday to Saturday (except legal holidays)", //服务时间：周一到周六8:30--17:30（法定节假日除外）
  userPwdRule: "Password format is wrong", //密码格式错误
  userNameRule: "Incorrect username format", //用户名格式错误
  Ok: "OK", //确认
  cancel: "Cancel", //取消
  backOld: "Old Version", //回到旧版

  cookie:
    "Our website uses cookies to improve our website and improve user experience. If you continue to browse this website without changing your browser's cookie settings, you are deemed to agree to our cookie usage policy", //我们的网站使用cookies以改进我们的网站和改善用户体验。如您继续浏览本网站而未更改浏览器cookie设置，视为同意我们的cookies使用策略
  cookieLink: "policy", //政策
  agree: "Agree", //同意
  notAgree: "Disagree", //不同意

  cookiePolicy: "Cookie policy", //Cookie 政策
  cookieUse: "How we use cookies and similar technologies", //我们如何使用Cookie和同类技术
  cookieWhy:
    "To ensure the normal operation of the website, we will store small data files called Cookies on your computer or mobile device. Cookies usually contain identifiers, site names, and some numbers and characters. With the help of cookies, websites can store data such as your preferences.", //为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据档。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。
  cookieFor:
    "We will not use cookies for any purpose other than those stated in this policy. You can manage or delete cookies according to your preferences. For details, see AboutCookies.org. You can clear all cookies saved on your computer, and most web browsers have a function to block cookies. But if you do this, you need to personally change the user settings every time you visit our website. To learn more about how to change browser settings, please visit the relevant settings page of the browser you are using. However, please note that if you do not accept cookies, you may not be able to fully experience some features of the website.", //我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。但请注意，如果您不接受cookie，可能无法全面体验网站的某些功能。
  cookieList:
    "The following is a list of the types of cookies used by the website:", //以下是网站使用的cookie种类列表：
  cookiePurpose: "Purpose", //目的
  cookieDescribe: "describe", //描述
  cookieType: "Type and expiration time", //种类和失效时间
  cookiePerformance: "Performance (user browser)", //性能（用户浏览器）
  cookieLogin:
    "Record user login status, and no need to log in again within 7 days", //记录用户登录状态，以及7天内不用重新登录
  cookieDay: "7 days", //7天
  cookieCnzz: "CNZZ", //CNZZ
  cookieTime: "Record the number of times the user visits the website", //记录用户访问网站的次数
  cookieForever: "forever", //永久
  cookieTrack:
    "Many web browsers have a Do Not Track function, which can post Do NotTrack requests to websites. Currently, major Internet standards organizations have not established relevant policies to regulate how websites should respond to such requests. But if your browser enables Do Not Track, then our website will respect your choice.", //很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do NotTrack请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站都会尊重您的选择。
  backHome: "Back to homepage", //回到首页
    // RS
    RS2000: 'Execution succeeded',//执行成功
    RS4000: 'Wrong user name or password',//用户名或密码错误
    RS4010: 'Verification code error',//验证码错误
    RS4011: 'Password is inconsistent',//密码不一致
    RS4012: 'User name has been registered',//用户名已被注册
    RS4013: 'User name is empty',//用户名为空
    RS4014: 'Password is empty',//密码为空
    RS4015: 'Confirm that the password is empty',//确认密码为空
    RS4016: 'Mobile number is empty',//手机号为空
    RS4017: 'Incorrect user name format',//用户名格式不正确
    RS4018: 'Incorrect password format',//密码格式不正确
    RS4019: 'Confirm that the password format is incorrect',//确认密码格式不正确
    RS4020: 'Mobile phone number format is incorrect',//手机号格式不正确
    RS4021: 'Mobile number registered',//手机号已注册
    RS4022: 'Mobile number is not registered',//手机号未注册
    RS4023: 'Email registered',//邮箱已注册
    RS4024: 'The mailbox is not registered',//邮箱未注册
    RS4025: 'User name is not registered',//用户名未注册
    RS4026: 'The original password is incorrect',//原密码错误
    RS4027: 'Failed to modify password',//修改密码失败
    RS4029: 'User ID is empty',//用户ID为空
    RS4030: 'The verification code is empty',//验证码为空
    RS4031: 'The mailbox is empty',//邮箱为空
    RS4032: 'Incorrect mailbox format',//邮箱格式不正确
    RS4033: 'Incorrect mailbox format',//邮箱格式不正确
    RS4034: 'WeChat is not bound',//微信没有绑定
    RS4035: 'Failed to bind WeChat',//绑定微信失败
    RS4036: 'Failed to bind mobile phone number',//绑定手机号失败
    RS4037: 'Failed to bind mailbox',//绑定邮箱失败
    RS4038: 'Send verification code more than the maximum number of times',//发送验证码超过最大次数
    RS4039: 'login has failed',//注册失败
    RS4040: 'WeChat has bound users',//微信已绑定用户
    RS4041: 'No permission to modify user name (only for generated anonymous users)',//没有权限修改用户名（仅针对生成的匿名用户修改）
    RS4042: 'USER_ NOT_ FB_ BIND user is not bound to Facebook',//USER_NOT_FB_BIND 用户没有绑定facebook
    RS4043: 'USER_ FAIL_ FB_ BIND user failed to bind to Facebook',//USER_FAIL_FB_BIND 用户绑定facebook失败
    RS4044: 'USER_ NOT_ GG_ BIND users do not have Google binding',//USER_NOT_GG_BIND 用户没有google绑定
    RS4045: 'USER_ FAIL_ GG_ BIND user failed to bind to Google',//USER_FAIL_GG_BIND 用户绑定google失败
    RS4046: 'Line account is not bound',//Line账户未绑定
    RS4047: 'Line account binding failed',//Line账户绑定失败
    RS4100: 'The device is not allowed to be added illegally',//设备非法不允许添加
    RS4101: 'Device already exists',//设备已存在
    RS4102: 'Failed to delete device',//删除设备失败
    RS4103: 'Modification failed',//修改失败
    RS4104: 'Device uuid parameter exception',//设备uuid参数异常
    RS4105: 'Device user name parameter exception',//设备用户名参数异常
    RS4106: 'Device password parameter is abnormal',//设备密码参数异常
    RS4116: 'Failed to hand over the device master account to another user. Check whether the user has the device and the user has the device master account authority',//将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限
    RS4117: 'Not a master account',//不是主账户
    RS4118: 'User does not have this device',//用户没有此设备
    RS4119: 'The added device is not unique. Other accounts have been added',//添加设备不唯一，其它账户已添加
    RS4120: 'Maximum limit of adding devices (100)',//添加设备最大限制(100个)
    RS4126: 'The device support token can only be added by one account',//设备支持令牌只有一个账户添加
    RS4127: 'Missing device token',//缺少设备令牌
    RS4300: 'fail in send',//发送失败
    RS4302: 'Successfully sent cancellation verification code',//发送注销验证码成功
    RS4400: 'SMS interface verification failed, please contact us.',//短信接口验证失败，请联系我们
    RS4401: 'SMS interface parameter error, please contact us',//短信接口参数错误，请联系我们
    RS4402: 'Each mobile phone number can only send SMS three times a day',//每个手机号一天只能发送三次短信
    RS4403: 'Sending failed. Please try again later',//发送失败，请稍后再试
    RS4404: 'Sending is too frequent, please wait 120 seconds',//发送太频繁了，请间隔120秒
    RS4405: 'fail in send',//发送失败
    RS5000: 'Server failure',//服务器故障
    RS5001: 'Certificate does not exist',//证书不存在
    RS5002: 'Request header information error',//请求头信息错误
    RS5003: 'Certificate Expiration',//证书失效
    RS5004: 'Generation key verification error',//生成密钥校验错误
    RS5005: 'Parameter exception',//参数异常
    RS5009: 'WeChat code error, AES encryption and decryption error',//微信code错误、AES加解密错误
    RS5010: 'Token expired',//token 已过期
    RS5011: 'Token error',//token 错误
    RS5012: 'Token has no permission.',//token 无权限
    RS5013: "I won't support it",//不支持
    RS5014: 'Operation is too frequent',//操作太频繁
    RS4050: 'Operation is too frequent',//操作太频繁
    phoneCodeTip: "Please enter the obtained mobile phone verification code",//======请输入获取的手机验证码
    phoneNotFind: "The mobile number you entered does not exist, please check",//=====您输入的手机号码不存在，请检查
    phoneFormatError: "Mobile number format error, please check",//======手机号码格式错误，请检查
    phoneSendSuccess: "Please check if the verification code is sent successfully",//=======验证码发送成功请注意查收
    hadPhone: "Your registered mobile number already exists", //======您的注册手机号码已存在
    phoneRule: "Please enter your mobile number",//======请输入手机号码



    useNameTip:"4-15 characters, including Chinese, letters, numbers_ Consisting of two or more items", //4-15位字符，可由中文、数字、_和-组成
    passwordTip:"8-32 character length, which can be composed of English letters, numbers, underscores and - li or more", //8-32位字符长度，由英文字母、数字、下划线和-组成
    NameLength: "Please enter a device name with no more than 255 characters consisting of upper and lower case letters, numbers and Chinese",
    UnameLength: "Please enter a device user name with no more than 255 characters consisting of upper and lower case letters, numbers and Chinese",
    placeTip:"Please enter the serial number",
    deleteAll:"Batch deletion",


     // nettimeout:"网络超时",
  accessTokenfail:"Service timeout, access token failed",
  userinfo2faile:"Service timeout, user information acquisition failed",
  getcodefail:"Service timeout, obtaining verification code failed",
  getmailfail:"Service timeout, modification failed",
  getdevicefail:"Service timeout, unable to get device list",
  getareacodefail:"Service timeout, unable to get area code list",
  rulenamefail:"Service timeout, user name verification failed",
  rulephonefail:"Service timeout, verification of user's mobile phone number failed",
  ruleregisterfail:"Service timeout, registration failed",
  ruleemailfail:"Service timeout, verification of mailbox failed",
  getdevicestatusfail:"Service timeout, unable to get device status",

  oldequipmentpassword:"Original password",
  oldpasswordrule:"Please enter the original password of the device. If not, you do not need to enter it",
  newequipmentpassword:"New password",
  newpasswordrule:"Please enter a new password for the device. If not, you do not need to enter it",
  confirmequippassword:"Confirm Password",
  confirmeqpasswordrule:"Please keep consistent with the new password",
  };
