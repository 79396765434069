module.exports = {
  //2022-6 新增
  cloudinfo: {
    normal: "정상적인 사용",
    timeOut: "만료 된",
    nonactivated: "활성화되지 않은",
    notSupport: "지원하지 않는다",
    noRemainingFlow: "잔여 흐름 없음",
    VipStatus: "VIP 상태",
    totalFlow: "총 흐름",
    residualFlow: "잔류 흐름",
    expireDate: "만료일"
  },
  //新增
  cloud: "클라우드 스토리지",
  cloudTimeOut: "클라우드 스토리지(만료됨)",
  cloudNormal: "클라우드 스토리지 (사용 중)",
  cloudNonActivated: "클라우드 스토리지 (미개통)",
  cloudnotSupported:"클라우드 스토리지 (제공되지 않음)",
  vipTimeOut: "4G 트래픽(만료됨)",
  vipNormal: "4G 트래픽(사용 중)",
  vipNonActivated: "4G 트래픽(미개통)",
  vipnotSupported:"4G 트래픽(제공되지 않음)",
  // 韩语
  // 登录页
  userLogin: "사용자 로그인", //用户登录
  equipLogin: "기기 로그인", //设备登录
  login: "로그인", //登录
  regist: "지금 가입", //立即注册
  findPassword: "비밀 번호를 검색", //找回密码
  userRule: "사용자 이름을 입력하십시오", //请输入用户名
  passwordRule: "비밀번호를 입력해주세요", //请输入密码
  codeRule: "인증 코드를 입력하십시오", //请输入验证码
  equipIdRule: "장치 일련 번호를 입력하십시오", //请输入设备序列号
  appDownload: "APP 다운로드", //App 下载
  webUnitDownload: "ActiveX 다운로드", //Web控件下载
  callUs: "문의하기", //联系我们
  officialEdition: "클래식 에디션", //经典版
  proEdition: "PRO 버전", //PRO版
  codeLenRule: "4자리 인증 코드를 입력하세요.", //请输入4位验证码
  notEmpty: "공백을 입력하지 마십시오", //请勿输入空格

  // 注册
  userRegist: "계정 만들기", //创建账户
  emailRule: "우편함 번호를 입력하세요", //请输入邮箱
  emailtrue: "우편함 번호를 올바른 형식으로 입력하세요", //请输入正确格式的邮箱号
  phonetrue:"올바른 형식의 핸드폰 번호를 입력하십시오",//请输入正确的手机号码
  confirmPasswordRule: "비밀번호를 확인해주세요", //请再次输入密码
  hadCount: "이미 계정을 가지고", //已有账号，立即登录
  registCount: "등록", //注册
  hadUser: "등록된 사용자 이름이 이미 존재합니다", //您注册的用户名已存在
  hadMail: "등록된 이메일이 이미 존재합니다", //您注册的邮箱已存在
  sendSuccess: "메일이 성공적으로 전송", //邮件发送成功
  mailFormatError: "이메일 형식 오류, 확인하십시오", //邮箱格式错误，请检查
  codeError: "인증 코드 오류", //验证码错误
  registerSuccess: "등록 성공", //注册成功
  mailNotFind: "입력하신 이메일 주소가 존재하지 않습니다. 확인해주세요", //您输入的邮箱不存在，请检查
  userNameLen: "4~8자의 사용자 이름을 입력하세요", //请输入4~8位用户名
  userPwdLen: "8~32자의 비밀번호를 입력하세요", //请输入8~32位密码
  privacyPolicy:"개인 정보 보호 정책 읽기 및 선택",//阅读并选择隐私政策
  emailRegistration:"메일박스 등록",//邮箱注册
  mobileRegistration:"휴대폰 등록",//手机注册
  // 找回密码
  phoneRetrievePassword:"핸드폰 비밀번호 찾기",//手机找回密码
  mailboxRetrievalPassword:"메일박스 비밀번호 찾기",//邮箱找回密码
  findThePassword: "비밀 번호를 검색", //找回密码
  passwordText:
    "비밀번호를 찾기 위해 계정을 등록할 때 사용한 이메일 주소를 입력하세요.", //请输入注册账号时使用的邮箱来找回密码
  exit: "그만두 다", //退出
  nextStep: "다음", //下一步
  reset: "초기화", //重新设置
  getCode: "보내다", //获取验证码
  someTime: "재획득", //秒后重新获取
  ok: "확인", //确定
  mailSendMuch: "메일은 우편함당 하루에 세 번만 보낼 수 있습니다.", //邮件每个邮箱一天只能发送三次
  mailChangeSuccess: "성공적으로 비밀번호 변경", //修改成功
  pwdError: "잘못된 비밀번호 형식", //密码格式不正确
  codeTimeout: "인증 코드 만료됨", //验证码过期
  codeErrorMuch: "너무 많은 오류가 나중에 다시 시도하십시오", //错误次数过多,请稍后再试
  backLogin: "로그인으로 돌아가기", //返回登录
  mailFast: "짧은 시간에 반복적으로 이메일을 보내지 마십시오", //短时间内不允许重复发送邮件

  //主页面
  refresh: "새로 고치다", //刷新
  myDevice: "내 장치", //我的设备
  personalInformation: "사용자 정보", //个人信息
  deviceManagement: "장비 관리", // 设备管理
  VIPTraffic:"VIP 트래픽",//VIP流量
  changePassword: "비밀번호 변경", //修改密码
  changePasswordSuccess: "성공적으로 비밀번호 변경", //修改密码成功
  LogOut: "로그아웃", //退出登录
  shurLogOut: "로그아웃하시겠습니까?", //你确定要退出吗
  oldPassword: "이전 암호", //旧密码
  newPassword: "새 비밀번호", //新密码
  inputNewPassword: "새 암호를 다시 확인", //再次输入新密码
  errorRefresh: "새로고침 실패", //刷新失败
  online: "온라인", //在线
  offline: "오프라인", //离线
  UidLength:
    "대문자, 소문자 및 숫자로 구성된 16자리 또는 20자리의 장치 일련 번호를 입력하세요", //请输入16或20位由大,小写字母,数字组成的设备序列号
    PwordLength: "크기, 소문자, 숫자로 구성된 장치 암호를 100자리 미만으로 입력하십시오",
    passwordrule: "특수 기호를 입력하지 마십시오.", //请勿输入特殊符号
  equipOffline:
    "기기가 일시적으로 오프라인 상태입니다. 다른 기기를 선택하세요.", //设备暂时不在线请选择其它设备吧
  BrowserSupport:
    "이 브라우저는 플러그인 재생을 지원하지 않습니다. Google 크롬을 사용하세요.", //此浏览器不支持插件播放，请使用IE或360浏览器
  embedBrowserFail:
    "브라우저를 바인딩하지 못했습니다. 로그인할 때 페이지를 빠르게 전환하지 마십시오.", //绑定浏览器失败，请在登录时勿快速切换页面
  noPlugin: "플러그인이 감지되지 않음", //未检测到插件
  CancelAccount: "계정 취소",
  CancelAccountTitle: "계정을 삭제하시겠습니까?",
  CancelSuccess: "로그아웃 성공",

  // 设备列表、
  order: "SN", //序号
  equipmentPwd: "기기 비밀번호", //设备密码
  equipmentOrder: "일련 번호", //序列号
  equipmentName: "장비 이름", //名称
  equipmentUser: "기기 사용자 이름", //设备用户名
  option: "운영하다", //操作
  addEquipment: "기기 추가", //添加设备
  changeEquipmentMessage: "기기 정보 수정", //修改设备信息
  changePasswordMessage:"암호 정보 수정",//修改密码信息
  deleteEquipment:"장치 삭제",//删除设备
  delmessage: "정말 삭제 하시겠습니까", //你确定要删除吗
  all: "전체적으로", //共
  tips: "데이터", //条数据
  add: "에 추가", //添加
  equipmentSuccess: "기기 정보가 성공적으로 수정되었습니다", //设备信息修改成功
  addUid: "추가하려는 장치의 일련 번호를 입력하십시오.", //请输入你要添加的设备序列号
  addid: "추가하려는 일련 번호를 입력하십시오.", //请输入你要添加的序号
  addname: "추가하려는 장치의 이름을 입력하십시오", //请输入你要添加的设备名
  addUsername: "추가하려는 장치의 사용자 이름을 입력하십시오", //请输入你要添加的设备用户名

  // 用户信息
  userinfo: "사용자 정보", //用户信息
  userName: "사용자 이름", //用户名
  usercontact: "연락 번호", //联系方式
  useremail: "이메일", //电子邮箱

  // 全局提示
  successLogin: "로그인 성공", //登录成功
  errorLogin1: "로그인 실패, 사용자 이름 또는 비밀번호가 잘못되었습니다", //登录失败，用户名或密码错误
  errorLogin2: "로그인 실패, 인증 코드 오류", //登录失败，验证码错误
  errorLogin3: "로그인 실패, 인증 코드 만료", //登录失败，验证码过期
  errorLogin4: "너무 많은 로그인 실패, 10분 동안 계정 잠김", //登录失败次数过多，账户锁定10分钟
  delsucess: "성공적으로 삭제", //删除成功
  addSuccess: "성공적으로 추가됨", //添加成功
  addFail: "추가 실패", //添加失败
  timeOut: "로그인 시간 초과, 자동으로 로그인 페이지로 이동", //登录超时，自动跳转登录页
  changeEquipmentPwdError: "원래 장치 암호가 잘못 입력되었습니다.", //原设备密码输入错误

  // 网络提示
  accessnot: "액세스 거부됨", //访问被拒绝
  resourcenot: "리소스를 사용할 수 없음", //资源不可用
  nopage: "페이지를 찾을 수 없음", //找不到页面
  methoderror: "방법은 허용되지 않습니다", //不允许此方法
  serveerror: "서버 오류", //服务器错误
  unknownerror: "알 수 없는 실수", //未知错误

  // 表单提示
  emailTip: "**@**.***",
  phoneTip:"정확한 핸드폰 번호를 입력하십시오",
  confirmTip: "비밀번호와 일관성을 유지하십시오", //请与密码保持一致
  equipmentIdTip: "16자리 또는 20자리 장치 일련 번호를 입력하세요", //请输入16或20位设备序列号
  equipmentUserTip: "장치 사용자 이름을 입력하십시오", //请输入设备用户名
  equipmentPasswordTip1: "기기 비밀번호를 입력해주세요", //请输入设备密码
  equipmentPasswordTip:
    "기기 비밀번호를 입력하세요. 비밀번호가 없으면 입력하지 않아도 됩니다", //请输入设备密码，没有则无需输入
  codeTip: "받은 이메일 인증 코드를 입력하세요", //请输入获取的邮箱验证码
  equipmentPwdTip: "장치 암호는 기본적으로 비어 있습니다", //设备密码默认为空
  equipmentNameTip: "기기명을 입력해주세요", //请输入设备名称
  equipmentUserNameTip: "장치 사용자 이름의 기본값은 “admin” 입니다", //设备用户名默认为“admin”
  pagetip1:
    "왼쪽의 “내 장치” 에서 장치를 클릭하여 장치에서 미리보기, 재생, 구성 및 기타 작업을 수행합니다", //单击左边“我的设备”中的设备，对设备进行预览，回放，配置等操作
  pagetip2:
    "처음 액세스하는 경우 플러그인을 다운로드하고 몇 분 정도 걸릴 수 있습니다. 기다려 주십시오", //如果您是第一次访问，会自动下载插件，可能需要花费几分钟时间，请耐心等待
  passwordChangeTip: "비밀번호를 변경하지 않으실 경우 비워두세요", //若不修改密码请保持为空
  loginUseNameTip:
    "사용자 이름, 이메일 번호, 휴대폰 번호로 로그인할 수 있습니다", //可使用用户名、邮箱号、手机号登录

  placeholderUser: "사용자 이름 / 이메일 번호 / 휴대폰 번호", //用户名/邮箱号/手机号
  placeholderPassword: "비밀번호", //密码
  placeholderCode: "확인 코드", //验证码
  placeholderEquipId: "일련 번호", //设备序列号
  placeholderEquipAdmin: "기기 사용자 이름", //设备用户名
  placeholderEquipPassword: "장치 암호", //设备密码
  placeholderRegistUser: "사용자 이름", //用户名
  placeholderRegistMail: "이메일", //邮箱
  placeholderRegistPhone: "핸드폰", //手机
  placeholderRegistCode: "확인 코드", //验证码
  placeholderRegistPwd: "비밀번호", //密码
  placeholderRegistPwd2: "암호 확인", //确认密码

  supportTitle: "기술 지원 연락처", //技术支持联系方式
  supportPhone: "서비스 핫라인", //服务热线
  supportMail: "서비스 사서함", //服务邮箱
  supportTime:
    "서비스 시간 : 월요일부터 토요일 8 : 30 ~ 17 : 30 (제외 법정 공휴일)", //服务时间：周一到周六8:30--17:30（法定节假日除外）
  userPwdRule: "비밀번호 형식이 잘못되었습니다", //密码格式错误
  userNameRule: "잘못된 사용자 이름 형식", //用户名格式错误
  Ok: "확인", //确认
  cancel: "취소", //取消
  backOld: "구 버전", //回到旧版

  cookie:
    "당사 웹사이트는 쿠키를 사용하여 웹사이트를 개선하고 사용자 경험을 개선합니다. 브라우저의 쿠키 설정을 변경하지 않고 이 웹사이트를 계속 탐색하면 쿠키 사용 정책에 동의하는 것으로 간주됩니다", //我们的网站使用cookies以改进我们的网站和改善用户体验。如您继续浏览本网站而未更改浏览器cookie设置，视为同意我们的cookies使用策略。
  cookieLink: "정책", //政策
  agree: "동의하다", //同意
  notAgree: "동의하지 않는다", //不同意

  cookiePolicy: "쿠키 정책", //Cookie 政策
  cookieUse: "쿠키 및 유사 기술을 사용하는 방법", //我们如何使用Cookie和同类技术
  cookieWhy:
    "웹사이트의 정상적인 작동을 보장하기 위해 쿠키라는 작은 데이터 파일을 귀하의 컴퓨터 또는 모바일 장치에 저장합니다. 쿠키에는 일반적으로 식별자, 사이트 이름, 일부 숫자 및 문자가 포함됩니다. 쿠키의 도움으로 웹사이트는 사용자의 기본 설정과 같은 데이터를 저장할 수 있습니다.", //为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据档。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。
  cookieFor:
    "우리는 이 정책에 명시된 것 이외의 다른 목적으로 쿠키를 사용하지 않습니다. 귀하는 귀하의 선호에 따라 쿠키를 관리하거나 삭제할 수 있습니다. 자세한 내용은 AboutCookies.org를 참조하십시오. 귀하의 컴퓨터에 저장된 모든 쿠키는 삭제할 수 있으며 대부분의 웹 브라우저에는 쿠키를 차단하는 기능이 있습니다. 하지만 이렇게 하면 웹사이트를 방문할 때마다 사용자 설정을 개인적으로 변경해야 합니다. 브라우저 설정을 변경하는 방법에 대해 자세히 알아보려면 사용 중인 브라우저의 관련 설정 페이지를 방문하십시오. 단, 쿠키를 허용하지 않을 경우 웹사이트의 일부 기능을 완벽하게 경험하지 못할 수 있습니다.", //我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。但请注意，如果您不接受cookie，可能无法全面体验网站的某些功能。
  cookieList: "다음은 웹사이트에서 사용하는 쿠키 유형 목록입니다.", //以下是网站使用的cookie种类列表：
  cookiePurpose: "목적", //目的
  cookieDescribe: "설명하다", //描述
  cookieType: "유형 및 만료 시간", //种类和失效时间
  cookiePerformance: "성능(사용자 브라우저)", //性能（用户浏览器）
  cookieLogin:
    "사용자 로그인 상태를 기록하고 7일 이내에 다시 로그인할 필요 없음", //记录用户登录状态，以及7天内不用重新登录
  cookieDay: "7 일", //7天
  cookieCnzz: "CNZZ", //CNZZ
  cookieTime: "사용자가 웹사이트를 방문한 횟수를 기록합니다.", //记录用户访问网站的次数
  cookieForever: "영구적 인", //永久
  cookieTrack:
    "많은 웹 브라우저에는 Do NotTrack 요청을 웹사이트에 게시할 수 있는 Do Not Track 기능이 있습니다. 현재 주요 인터넷 표준 조직에서는 웹사이트가 이러한 요청에 응답하는 방법을 규제하는 관련 정책을 수립하지 않았습니다. 그러나 브라우저에서 추적 금지를 활성화하면 당사 웹사이트는 귀하의 선택을 존중합니다.", //很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do NotTrack请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站都会尊重您的选择。
  backHome: "홈페이지로 돌아가기", //回到首页
  // RS
  RS2000: '실행 성공',//执行成功
  RS4000: "사용자 이름 또는 암호 오류",//用户名或密码错误
  RS4010: '인증 코드 오류',//验证码错误
  RS4011: '암호 불일치',//密码不一致
  RS4012: "사용자 이름이 등록되었습니다",//用户名已被注册
  RS4013: "사용자 이름이 비어 있습니다",//用户名为空
  RS4014: '암호가 비어 있음',//密码为空
  RS4015: '암호가 비어 있는지 확인',//确认密码为空
  RS4016: '핸드폰 번호가 비어 있습니다',//手机号为空
  RS4017: "사용자 이름 형식이 잘못되었습니다",//用户名格式不正确
  RS4018: '잘못된 암호 형식',//密码格式不正确
  RS4019: '잘못된 암호 형식 확인',//确认密码格式不正确
  RS4020: '핸드폰 번호의 형식이 정확하지 않다',//手机号格式不正确
  RS4021: '휴대폰 번호 등록됨',//手机号已注册
  RS4022: '휴대폰 번호 미등록',//手机号未注册
  RS4023: '메일박스 등록됨',//邮箱已注册
  RS4024: '메일박스 미등록',//邮箱未注册
  RS4025: "사용자 이름이 등록되지 않았습니다",//用户名未注册
  RS4026: '원래 암호 오류',//原密码错误
  RS4027: '암호 수정 실패',//修改密码失败
  RS4029: "사용자 ID가 비어 있습니다",//用户ID为空
  RS4030: '인증 코드가 비어 있음',//验证码为空
  RS4031: '메일박스가 비어 있습니다',//邮箱为空
  RS4032: '메일박스 형식이 잘못되었습니다',//邮箱格式不正确
  RS4033: '메일박스 형식이 잘못되었습니다',//邮箱格式不正确
  RS4034: "위챗에 바인딩이 없습니다",//微信没有绑定
  RS4035: '위챗 바인딩 실패',//绑定微信失败
  RS4036: '휴대폰 번호 바인딩 실패',//绑定手机号失败
  RS4037: '메일박스 바인딩 실패',//绑定邮箱失败
  RS4038: '최대 인증 코드 전송 횟수 초과',//发送验证码超过最大次数
  RS4039: '등록 실패',//注册失败
  RS4040: "위챗 이미 사용자 바인딩",//微信已绑定用户
  RS4041: "사용자 이름을 수정할 권한이 없습니다 (생성된 익명 사용자만 수정)",//没有权限修改用户名（仅针对生成的匿名用户修改）
  RS4042: "USER_NOT_FB_BIND 사용자가 Facebook을 바인딩하지 않음",//USER_NOT_FB_BIND 用户没有绑定facebook
  RS4043: "USER_FAIL_FB_BIND 사용자가 Facebook을 바인딩하지 못했습니다",//USER_FAIL_FB_BIND 用户绑定facebook失败
  RS4044: "USER_NOT_GG_BIND 사용자가 google 바인딩되지 않음",//USER_NOT_GG_BIND 用户没有google绑定
  RS4045: "USER_FAIL_GG_BIND 사용자 google 바인딩 실패",//USER_FAIL_GG_BIND 用户绑定google失败
  RS4046: "라인 계정이 바인딩되지 않았습니다",//Line账户未绑定
  RS4047: '라인 계정 바인딩 실패',//Line账户绑定失败
  RS4100: "장치가 불법으로 추가되지 않음",//设备非法不允许添加
  RS4101: "디바이스가 이미 있습니다",//设备已存在
  RS4102: '장치 삭제 실패',//删除设备失败
  RS4103: '수정 실패',//修改失败
  RS4104: '장치 uuid 매개 변수 예외',//设备uuid参数异常
  RS4105: '장치 사용자 이름 매개 변수 예외',//设备用户名参数异常
  RS4106: "장치 암호 매개 변수 예외",//设备密码参数异常
  RS4116: "장치 마스터 계정을 다른 사용자에게 넘기는 데 실패했습니다. 사용자가 장치를 소유하고 있으며 해당 사용자가 장치 마스터 계정 권한을 가지고 있는지 확인합니다",//将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限
  RS4117: '주 계정 아님',//不是主账户
  RS4118: "사용자에게 이 장치가 없습니다",//用户没有此设备
  RS4119: "장치 추가는 고유하지 않습니다. 다른 계정이 추가되었습니다",//添加设备不唯一，其它账户已添加
  RS4120: "장치 최대 제한 사항 추가 (100개)",//添加设备最大限制(100个)
  RS4126: '장치 지원 토큰은 하나의 계정에서만 추가할 수 있습니다',//设备支持令牌只有一个账户添加
  RS4127: "장치 토큰이 없습니다",//缺少设备令牌
  RS4300: "전송 실패",//发送失败
  RS4302: '로그아웃 인증 코드 전송 성공',//发送注销验证码成功
  RS4400: "문자 인터페이스 검증에 실패했습니다. 연락 주세요",//短信接口验证失败，请联系我们
  RS4401: "문자 인터페이스 매개 변수 오류, 연락 주세요",//短信接口参数错误，请联系我们
  RS4402: '핸드폰 번호당 하루에 세 번만 문자 보낼 수 있어요',//每个手机号一天只能发送三次短信
  RS4403: "전송에 실패했습니다. 나중에 다시 시도하십시오",//发送失败，请稍后再试
  RS4404: "너무 자주 보냅니다. 120초 간격으로 보내주세요",//发送太频繁了，请间隔120秒
  RS4405: "전송 실패",//发送失败
  RS5000: "서버 장애",//服务器故障
  RS5001: "인증서가 없습니다.",//证书不存在
  RS5002: "요청 헤더 정보 오류",//请求头信息错误
  RS5003: "인증서 실패",//证书失效
  RS5004: "키 검사 오류 생성",//生成密钥校验错误
  RS5005: '매개변수 예외',//参数异常
  RS5009: '위챗 코드 오류, AES 복호화 오류',//微信code错误、AES加解密错误
  RS5010: 'token 만료됨',//token 已过期
  RS5011: 'token 오류',//token 错误
  RS5012: 'token 권한 없음',//token 无权限
  RS5013: "지원되지 않음",//不支持
  RS5014: "너무 자주 작동",//操作太频繁
  RS4050: "너무 자주 작동",//操作太频繁
  phoneCodeTip: "획득한 핸드폰 인증번호를 입력하세요",//======请输入获取的手机验证码
  phoneNotFind: "입력한 휴대폰 번호가 없습니다. 확인하십시오",//=====您输入的手机号码不存在，请检查
  phoneFormatError: "휴대폰 번호 형식이 잘못되었습니다. 확인하십시오.",//======手机号码格式错误，请检查
  phoneSendSuccess: "인증 코드 발송 성공 확인",//=======验证码发送成功请注意查收
  hadPhone: "등록된 휴대폰 번호가 이미 있습니다.", //======您的注册手机号码已存在
  phoneRule: "핸드폰 번호를 입력하세요.",//======请输入手机号码

  useNameTip:"중국어, 문자, 숫자, _화-리 두 개 이상 구성", //4-15位字符，可由中文、数字、_和-组成
    passwordTip:"8-32자리 문자 길이, 영문자, 숫자, 밑줄 및 - 의 두 가지 및 그 이상으로 구성 가능", //8-32位字符长度，由英文字母、数字、下划线和-组成
    NameLength: "크기, 소문자, 숫자, 중국어로 구성된 255자리 이하의 장치 이름을 입력하십시오",
    UnameLength: "255자리 미만의 크기, 소문자, 숫자, 중국어로 구성된 장치 사용자 이름을 입력하십시오.",
    placeTip:"일련 번호를 입력하십시오",
    deleteAll:"대량 삭제",

    // nettimeout:"网络超时",
  accessTokenfail:"서비스 시간 초과, accessToken 가져오기 실패",
  userinfo2faile:"서비스 시간 초과, 사용자 정보 가져오기 실패",
  getcodefail:"서비스 시간 초과, 인증 코드 가져오기 실패",
  getmailfail:"서비스 시간 초과, 수정 실패",
  getdevicefail:"서비스 시간 초과, 장치 목록을 가져올 수 없습니다.",
  getareacodefail:"서비스 시간 초과, 지역 번호 목록을 가져올 수 없습니다.",
  rulenamefail:"서비스 시간 초과, 사용자 이름 확인 실패",
  rulephonefail:"서비스 시간 초과, 사용자 휴대폰 번호 확인 실패",
  ruleregisterfail:"서비스 시간 초과, 등록 실패",
  ruleemailfail:"서비스 시간 초과, 메일박스 확인 실패",
  getdevicestatusfail:"서비스 시간이 초과되어 장치 상태를 가져올 수 없습니다.",

  oldequipmentpassword:"원래 암호",
  oldpasswordrule:"장치의 원래 암호를 입력하십시오. 없으면 입력할 필요가 없습니다.",
  newequipmentpassword:"새 암호",
  newpasswordrule:"디바이스의 새 암호를 입력하십시오. 없으면 입력할 필요가 없습니다.",
  confirmequippassword:"암호 확인",
  confirmeqpasswordrule:"새 암호와 일치하십시오.",
};
