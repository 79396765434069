<template>
  <div class="box">
    <div class="topbox">
      <div>
        <a-input-search
          v-model.trim="searchvalue"
          :placeholder="$t('placeTip')"
          enter-button
          allow-clear
          @search="onSearch"
        />
      </div>
      <div>
        <a-button
          style="margin-left: 20px"
          type="primary"
          :disabled="!hasSelected"
          @click="start"
        >
          {{ $t("deleteAll") }}
        </a-button>
      </div>
      <div class="addbutton">
        <a-button type="primary" class="button1" @click="add">
          <a-icon type="plus" /> {{ $t("add") }}
          <a-modal
            class="addmodal"
            v-model="showAdd"
            :title="$t('addEquipment')"
            @ok="submitAdd"
            @cancel="() => AddCancel()"
          >
            <a-form :form="form5" :selfUpdate="true">
              <a-form-item :label="$t('equipmentOrder')" has-feedback>
                <!-- 设备id -->
                <a-tooltip placement="right">
                  <template slot="title"
                    ><span class="tips">{{ $t(`equipmentIdTip`) }}</span>
                  </template>
                  <a-input
                    autocomplete="off"
                    v-decorator="[
                      'uuid',
                      {
                        rules: [
                          {
                            required: true,
                            message: this.$t('addUid'),
                          },
                          {
                            pattern: /^[a-zA-Z0-9-_]{16}$|^[a-zA-Z0-9-_]{20}$/,
                            message: this.$t('UidLength'),
                          },
                          {
                            pattern: /^[^\s]*$/,
                            message: this.$t('notEmpty'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label="$t('equipmentName')" has-feedback>
                <!-- 设备名称 -->
                <a-tooltip placement="right">
                  <template slot="title"
                    ><span class="tips">{{ $t(`equipmentNameTip`) }}</span>
                  </template>
                  <a-input
                    autocomplete="off"
                    v-decorator="[
                      'nickname',
                      {
                        rules: [
                          {
                            required: true,
                            message: this.$t('addname'),
                          },
                          {
                            pattern: /^[\u4e00-\u9fa5a-zA-Z0-9-_]*$/,
                            // pattern: /^[a-zA-Z0-9]{16}$|^[a-zA-Z0-9]{255}$/,
                            message: this.$t('NameLength'),
                          },
                          {
                            pattern: /^[^\s]*$/,
                            message: this.$t('notEmpty'),
                          },
                          {
                            max: 255,
                            message: this.$t('NameLength'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label="$t('equipmentUser')" has-feedback>
                <!-- 用户名称 -->
                <a-tooltip placement="right">
                  <template slot="title"
                    ><span class="tips">{{ $t(`equipmentUserNameTip`) }}</span>
                  </template>
                  <a-input
                    autocomplete="off"
                    v-decorator="[
                      'username',
                      {
                        rules: [
                          {
                            required: true,
                            message: this.$t('addUsername'),
                          },
                          {
                            pattern: /^[\u4e00-\u9fa5a-zA-Z0-9-_]*$/,
                            // pattern: /^[a-zA-Z0-9]{16}$|^[a-zA-Z0-9]{255}$/,
                            message: this.$t('UnameLength'),
                          },
                          {
                            pattern: /^[^\s]*$/,
                            message: this.$t('notEmpty'),
                          },
                          {
                            max: 255,
                            message: this.$t('UnameLength'),
                          },
                        ],
                        initialValue: admin,
                      },
                    ]"
                  />
                </a-tooltip>
              </a-form-item>
              <a-form-item :label="$t('equipmentPwd')" has-feedback>
                <!-- 设备密码框 -->
                <a-tooltip placement="right">
                  <template slot="title"
                    ><span class="tips">{{ $t(`equipmentPwdTip`) }}</span>
                  </template>
                  <a-input
                    autocomplete="off"
                    v-decorator="[
                      'password',
                      {
                        rules: [
                          // {
                          //   pattern: /^[a-zA-Z0-9]*$/,
                          //   message: $t('passwordRule'),
                          // },
                          {
                            pattern: /^[a-zA-Z0-9-_]*$/,
                            message: this.$t('PwordLength'),
                          },
                          {
                            pattern: /^[^\s]*$/,
                            message: this.$t('notEmpty'),
                          },
                          {
                            max: 100,
                            message: this.$t('PwordLength'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-tooltip>
              </a-form-item>
            </a-form>
          </a-modal>
        </a-button>
      </div>
    </div>

    <div class="table-box">
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns()"
        :data-source="data"
        bordered
        :pagination="pagination"
        rowKey="id"
        :scroll="{ y: tabelHeight }"
        @change="showSizeChange"
      >
        <template slot="celStatus" slot-scope="item">
          <span @click="toCloudBuy(item.uuid)">
            <a-tag
              color="green"
              style="cursor: pointer"
              v-if="item.celStatus === 1"
            >
              {{ $t("cloudinfo.normal") }}
            </a-tag>
            <a-tag
              color="red"
              style="cursor: pointer"
              v-else-if="item.celStatus === 2"
            >
              {{ $t("cloudinfo.timeOut") }}
            </a-tag>
            <a-tag
              color="orange"
              style="cursor: pointer"
              v-else-if="item.celStatus === 3"
              >{{ $t("cloudinfo.nonactivated") }}
            </a-tag>
            <a-tag
              color="cyan"
              style="cursor: pointer"
              v-else-if="item.celStatus === 5"
            >
              {{ $t("cloudinfo.noRemainingFlow") }}
            </a-tag>
          </span>
          <span>
            <a-tag color="grey" v-if="item.celStatus === 4">
              {{ $t("cloudinfo.notSupport") }}
            </a-tag>
          </span>
        </template>

        <template slot="cssStatus" slot-scope="item">
          <span @click="toCloudBuy(item.uuid)">
            <a-tag
              color="green"
              style="cursor: pointer"
              v-if="item.cssStatus === 1"
            >
              {{ $t("cloudinfo.normal") }}
            </a-tag>
            <a-tag
              color="red"
              style="cursor: pointer"
              v-else-if="item.cssStatus === 2"
            >
              {{ $t("cloudinfo.timeOut") }}</a-tag
            >
            <a-tag
              color="orange"
              style="cursor: pointer"
              v-else-if="item.cssStatus === 3"
            >
              {{ $t("cloudinfo.nonactivated") }}
            </a-tag>
            <a-tag
              color="cyan"
              style="cursor: pointer"
              v-else-if="item.cssStatus === 5"
            >
              {{ $t("cloudinfo.noRemainingFlow") }}
            </a-tag>
          </span>
          <span>
            <a-tag color="grey" v-if="item.cssStatus === 4">
              {{ $t("cloudinfo.notSupport") }}
            </a-tag>
          </span>
        </template>

        <template slot="operation" slot-scope="item">
          <a-tooltip placement="top" :title="$t('changeEquipmentMessage')">
            <a-button
              type="primary"
              icon="edit"
              class="button"
              @click="change(item)"
            >
            </a-button>
          </a-tooltip>
          <a-tooltip placement="top" :title="$t('changePasswordMessage')">
            <a-button
              type="primary"
              icon="form"
              class="button"
              @click="changepsw(item)"
            >
            </a-button>
          </a-tooltip>
          <a-tooltip placement="top" :title="$t('deleteEquipment')">
            <a-button
              type="danger"
              icon="delete"
              class="button"
              @click="del(item)"
            >
            </a-button>
          </a-tooltip>
        </template>
      </a-table>
      <a-modal
        class="modalbox"
        v-model="showChange"
        :title="$t('changeEquipmentMessage')"
        @ok="submitChange"
        :dialog-style="{ top: '9vh' }"
        @cancel="() => changeCancel()"
      >
        <a-form :form="form4">
          <a-form-item :label="$t('equipmentOrder')" has-feedback>
            <a-input
              disabled
              v-decorator="[
                'uuid',
                {
                  rules: [],
                  initialValue: uuid,
                },
              ]"
            />
          </a-form-item>
          <a-form-item :label="$t('equipmentName')" has-feedback>
            <a-tooltip placement="right">
              <template slot="title"
                ><span class="tips">{{ $t(`equipmentNameTip`) }}</span>
              </template>
              <a-input
                autocomplete="off"
                v-decorator="[
                  'nickname',
                  {
                    rules: [
                      {
                        required: true,
                        message: this.$t('equipmentNameTip'),
                      },
                      {
                        pattern: /^[\u4e00-\u9fa5a-zA-Z0-9-_]*$/,
                        // pattern: /^[a-zA-Z0-9]{16}$|^[a-zA-Z0-9]{255}$/,
                        message: this.$t('NameLength'),
                      },
                      {
                        pattern: /^[^\s]*$/,
                        message: this.$t('notEmpty'),
                      },
                      {
                        max: 255,
                        message: this.$t('NameLength'),
                      },
                    ],
                    initialValue: nickname,
                  },
                ]"
              />
            </a-tooltip>
          </a-form-item>
          <a-form-item :label="$t('equipmentUser')" has-feedback>
            <a-tooltip placement="right">
              <template slot="title"
                ><span class="tips">{{ $t(`equipmentUserTip`) }}</span>
              </template>
              <a-input
                autocomplete="off"
                v-decorator="[
                  'username',
                  {
                    rules: [
                      {
                        required: true,
                        message: this.$t('equipmentUserTip'),
                      },
                      {
                        pattern: /^[\u4e00-\u9fa5a-zA-Z0-9-_]*$/,
                        // pattern: /^[a-zA-Z0-9]{16}$|^[a-zA-Z0-9]{255}$/,
                        message: this.$t('UnameLength'),
                      },
                      {
                        pattern: /^[^\s]*$/,
                        message: this.$t('notEmpty'),
                      },
                      {
                        max: 255,
                        message: this.$t('UnameLength'),
                      },
                    ],
                    initialValue: username,
                  },
                ]"
              />
            </a-tooltip>
          </a-form-item>
        </a-form>
      </a-modal>

      <!-- 修改设备密码dialog -->
      <a-modal
        class="modalbox"
        v-model="pswshowChange"
        :title="$t('changePasswordMessage')"
        @ok="pswsubmitChange"
        :dialog-style="{ top: '9vh' }"
        @cancel="() => pswchangeCancel()"
      >
        <a-form :form="pswform4">
          <a-form-item :label="$t('oldequipmentpassword')" has-feedback>
            <a-tooltip placement="right">
              <template slot="title"
                ><span class="tips">{{ $t(`oldpasswordrule`) }}</span>
              </template>
              <a-input
                :disabled="changepsdisabled"
                autocomplete="off"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        pattern: /^[a-zA-Z0-9-_]*$/,
                        message: this.$t('PwordLength'),
                      },
                      {
                        pattern: /^[^\s]*$/,
                        message: this.$t('notEmpty'),
                      },
                      {
                        max: 100,
                        message: this.$t('PwordLength'),
                      },
                    ],
                    initialValue: password,
                  },
                ]"
              />
            </a-tooltip>
          </a-form-item>
          <a-form-item :label="$t('newequipmentpassword')" has-feedback>
            <a-tooltip placement="right">
              <template slot="title"
                ><span class="tips">{{ $t(`newpasswordrule`) }}</span>
              </template>
              <a-input
                :disabled="changepsdisabled"
                autocomplete="off"
                v-decorator="[
                  'newpassword',
                  {
                    rules: [
                      {
                        pattern: /^[a-zA-Z0-9-_]*$/,
                        message: this.$t('PwordLength'),
                      },
                      {
                        pattern: /^[^\s]*$/,
                        message: this.$t('notEmpty'),
                      },
                      {
                        max: 100,
                        message: this.$t('PwordLength'),
                      },
                      {
                        validator: validateToNextPassword,
                      },
                    ],
                  },
                ]"
              />
            </a-tooltip>
          </a-form-item>
          <a-form-item :label="$t('confirmequippassword')" has-feedback>
            <a-tooltip placement="right">
              <template slot="title"
                ><span class="tips">{{ $t(`confirmeqpasswordrule`) }}</span>
              </template>
              <a-input
                :disabled="changepsdisabled"
                autocomplete="off"
                v-decorator="[
                  'confirmpassword',
                  {
                    rules: [
                      {
                        pattern: /^[a-zA-Z0-9-_]*$/,
                        message: this.$t('PwordLength'),
                      },
                      {
                        pattern: /^[^\s]*$/,
                        message: this.$t('notEmpty'),
                      },
                      {
                        max: 100,
                        message: this.$t('PwordLength'),
                      },

                      {
                        validator: compareToFirstPassword,
                      },
                    ],
                  },
                ]"
              />
            </a-tooltip>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { showmessage } from "../utils/showMessage";
import { encrypt } from "@/utils/aes";
export default {
  inject: ["autoRefresh"],
  components: {},

  beforeCreate() {
    this.form4 = this.$form.createForm(this, { name: "changeMessage" });
    this.pswform4 = this.$form.createForm(this, { name: "pswchangeMessage" });
    this.form5 = this.$form.createForm(this, { name: "addMessage" });
  },
  data() {
    return {
      selectedRowKeys: [], // Check here to configure the default column
      templateData: [],
      searchvalue: "",
      tabelHeight: 200,
      id: "",
      uuid: "",
      nickname: "",
      username: "",
      password: "",
      showChange: false,
      pswshowChange: false,
      showAdd: false,
      pagination: {
        total: 0,
        defaultPageSize: 10,
        showTotal: (total) => `${this.$t("all")} ${total} ${this.$t("tips")}`,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30"],
        // onShowSizeChange: (current, pageSize) => (
        // this.pageSize = pageSize),
      },
      admin: "admin",
      data: [],
      accessToken: "",
      changepsdisabled: true,
    };
  },
  created() {
    this.readDeviceList();
    // this.getCloudToken();
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  mounted() {
    console.log("mylist mounted");
    this.getHight();
    this.getCloudToken();
    window.addEventListener("resize", this.getHight);
  },
  methods: {
    compareToFirstPassword(rule, value, callback) {
      const pswform4 = this.pswform4;
      if (value && value !== pswform4.getFieldValue("newpassword")) {
        callback(this.$t("confirmeqpasswordrule"));
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const pswform4 = this.pswform4;
      if (value && this.confirmDirty) {
        pswform4.validateFields(["confirmpassword"], { force: true });
      }
      callback();
    },
    showSizeChange(e) {
      console.log("showSizeChange", e);
    },
    deletedatas() {
      let that = this;
      this.data.forEach((item) => {
        this.selectedRowKeys.forEach((ele) => {
          if (ele == item.id) {
            const values = {
              uuid: encrypt(item.uuid),
              uname: that.$store.state.userInfo.uname,
              upass: that.$store.state.userInfo.upass,
            };
            that.$http
              .post("/transfer/mdrm/v1", values, {
                headers: {
                  "Access-Token": localStorage.getItem("XM-Token"),
                },
              })
              .then((res) => {
                if (res.code == 2000) {
                  that.autoRefresh();
                  that.readDeviceList();
                  that.$message.destroy();
                  that.$message.success(that.$t("delsucess"));
                } else {
                  that.$message.destroy();
                  that.$message.error(that.$t("" + showmessage(res)));
                }
              });
          }
        });
      });
      setTimeout(() => {
        that.selectedRowKeys = [];
      }, 100);
    },
    start() {
      var that = this;
      this.$confirm({
        title: this.$t("delmessage"),
        onOk() {
          that.deletedatas();
        },
        onCancel() {},
      });
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    onSearch(value) {
      //前端搜索
      console.log("搜索", value);
      sessionStorage.setItem("searchvalue", JSON.stringify(value));
      if (value.length == 0) {
        this.data = this.templateData;
      } else {
        let arr = [];
        this.templateData.forEach((item) => {
          if (value == item.uuid) {
            arr.push(item);
          }
        });
        this.data = arr;
      }
    },
    //计算表单高度
    getHight() {
      this.tabelHeight = window.innerHeight - 280;
    },

    columns() {
      return [
        {
          title: this.$t("equipmentOrder"),
          dataIndex: "uuid",
          with: "300",
        },
        {
          title: this.$t("equipmentName"),
          dataIndex: "nickname",
          with: "120",
        },
        {
          title: this.$t("equipmentUser"),
          dataIndex: "username",
          with: "150",
        },
        // {
        //   title: "4G",
        //   scopedSlots: { customRender: "celStatus" },
        //   with: "150",
        // },
        // {
        //   title: this.$t("cloud"),
        //   scopedSlots: { customRender: "cssStatus" },
        //   with: "150",
        // },
        {
          title: this.$t("option"),
          scopedSlots: { customRender: "operation" },
          with: "150",
        },
      ];
    },
    add() {
      this.showAdd = true;
    },
    // 获取设备列表
    readDeviceList() {
      this.$http
        .post(
          "/transfer/mdlist/v1",
          {
            uname: this.$store.state.userInfo.uname,
            upass: this.$store.state.userInfo.upass,
          },
          {
            headers: {
              "Access-Token": localStorage.getItem("XM-Token"),
            },
          }
        )
        .then((res) => {
          if (res.code === 2000) {
            this.data = res.data.data || [];
            // 前端搜索赋值
            this.templateData = JSON.parse(JSON.stringify(res.data.data || []));
            if (
              JSON.parse(sessionStorage.getItem("searchvalue")) != undefined &&
              JSON.parse(sessionStorage.getItem("searchvalue")) != null &&
              JSON.parse(sessionStorage.getItem("searchvalue")).length > 0
            ) {
              this.searchvalue = JSON.parse(
                sessionStorage.getItem("searchvalue")
              );
              this.onSearch(JSON.parse(sessionStorage.getItem("searchvalue")));
            }
            this.getDeviceCaps();
          } else {
            this.$message.destroy();
            this.$message.error(this.$t("" + showmessage(res)));
          }
        });
    },
    // 编辑设备信息
    change(item) {
      let that = this;
      that.id = item.id;
      that.uuid = item.uuid;
      that.nickname = item.nickname;
      that.username = item.username;
      let uuidlist = [];
      // 判断设备是否在线不在线不允许修改设备登录密码
      async function getData() {
        that.data.forEach((item) => {
          let obj = { uuid: item.uuid };
          uuidlist.push(obj);
        });
        return uuidlist;
      }
      getData().then((result) => {
        that.$http
          .post("/transfer/devstatus/v1", result, {
            headers: {
              "Access-Token": localStorage.getItem("XM-Token"),
            },
          })
          .then((res) => {
            if (res) {
              if (res.code === 2000) {
                res.data.forEach((item) => {
                  if (item.uuid == that.uuid) {
                    if (item.status == "notfound") {
                      that.changepsdisabled = true;
                    } else {
                      that.changepsdisabled = false;
                    }
                  }
                });
                that.showChange = true;
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("getdevicestatusfail"));
            }
          });
      });
    },
    // 编辑设备密码
    changepsw(item) {
      console.log(item, "pswiiiiiiiiiitem");
      let that = this;
      that.id = item.id;
      that.uuid = item.uuid;
      // that.nickname = item.nickname;
      // that.username = item.username;
      that.password = item.password ? item.password : "";
      console.log(that.password, "that.password");
      let uuidlist = [];
      // 判断设备是否在线不在线不允许修改设备登录密码
      async function getData() {
        that.data.forEach((item) => {
          let obj = { uuid: item.uuid };
          uuidlist.push(obj);
        });
        return uuidlist;
      }
      getData().then((result) => {
        that.$http
          .post("/transfer/devstatus/v1", result, {
            headers: {
              "Access-Token": localStorage.getItem("XM-Token"),
            },
          })
          .then((res) => {
            if (res) {
              if (res.code === 2000) {
                res.data.forEach((item) => {
                  if (item.uuid == that.uuid) {
                    if (item.status == "notfound") {
                      that.changepsdisabled = true;
                    } else {
                      that.changepsdisabled = false;
                    }
                  }
                });
                that.pswshowChange = true;
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("getdevicestatusfail"));
            }
          });
      });
    },
    /**
     * @description 获取能力集设备状态
     * @param {*} e
     */
    getDeviceCaps() {
      let uuidlist = [];
      let that = this;
      async function getData() {
        that.data.forEach((item) => {
          uuidlist.push(item.uuid);
        });
        return uuidlist;
      }
      getData().then((result) => {
        const params = {
          ver: 2,
          sn: result,
          caps: ["xmc.css", "net.cellular", "net.dat"],
        };
        that.$http
          .post("/caps/status", params, {
            headers: {
              "Access-Token": localStorage.getItem("XM-Token"),
            },
          })
          .then((res) => {
            if (res) {
              if (
                res.code != undefined &&
                res.code != null &&
                res.code === 200
              ) {
                let temres = res.data.capsData;
                if (
                  that.data != undefined &&
                  that.data != null &&
                  that.data.length > 0
                ) {
                  that.data.forEach((item) => {
                    if (
                      temres != undefined &&
                      temres != null &&
                      temres.length > 0
                    ) {
                      temres.forEach((ele) => {
                        if (item.uuid == ele.sn) {
                          that.$set(
                            item,
                            "cssStatus",
                            ele.cssNetVo.cssStatus || false
                          );
                          that.$set(item, "celStatus", ele.celVo.celStatus);
                        }
                      });
                    }
                  });
                }
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("getdevicefail"));
              }
              console.log(this.data, "this.data");
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("getdevicefail"));
            }
          });
      });
    },
    //获取云存储Token
    getCloudToken() {
      const value = {
        account: this.$store.state.userInfo.uname,
        pass: this.$store.state.userInfo.upass,
      };
      this.$http.post("/transfer/getLoginToken", value).then((res) => {
        if (res) {
          if (
            res.accessToken != undefined &&
            res.accessToken != null &&
            res.accessToken.length > 0
          ) {
            this.accessToken = res.accessToken;
          } else {
            this.$message.destroy();
            this.$message.error(this.$t("accessTokenfail"));
          }
        } else {
          this.$message.destroy();
          this.$message.error(this.$t("accessTokenfail"));
        }
      });
    },
    //跳转到云存储界面
    toCloudBuy(uuid) {
      let appKey = "3620c5b0249341058d270c2bb62047f7";
      window.open(
        `https://boss22-api.xmcsrv.net/index?authorization=${this.accessToken}&appKey=${appKey}&sn=${uuid}&channel=`
      );
    },

    // 修改设备信息提交
    submitChange(e) {
      e.preventDefault();
      this.form4.validateFieldsAndScroll((err, values) => {
        if (!err) {
          sessionStorage.setItem("cjnickname", JSON.stringify(encrypt(values.nickname)));
          sessionStorage.setItem("cjusername", JSON.stringify(encrypt(values.username)));
          sessionStorage.setItem("cjuuid", JSON.stringify(encrypt(values.uuid)));
          sessionStorage.setItem("cjuname", JSON.stringify(this.$store.state.userInfo.uname));
          sessionStorage.setItem("cjupass", JSON.stringify(this.$store.state.userInfo.upass));
          const send = {
            nickname: encrypt(values.nickname),
            username: encrypt(values.username),
            uuid: encrypt(values.uuid),
            uname: this.$store.state.userInfo.uname,
            upass: this.$store.state.userInfo.upass,
          };
          this.$http
            .post("/transfer/mdedit/v1", send, {
              headers: {
                "Access-Token": localStorage.getItem("XM-Token"),
              },
            })
            .then((res) => {
              if (res) {
                if (res.code == 2000) {
                  this.showChange = false;
                  this.form4.resetFields();
                  this.readDeviceList();
                  this.autoRefresh();
                  this.$message.destroy();
                  this.$message.success(this.$t("equipmentSuccess"));
                } else {
                  this.$message.destroy();
                  this.$message.error(this.$t("" + res.msg));
                }
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("getmailfail"));
              }
            });
        }
      });
    },
    // 修改设备密码提交
    pswsubmitChange(e) {
      e.preventDefault();
      if (this.changepsdisabled == true) {
        this.pswshowChange = false;
        return 
      }
      this.pswform4.validateFieldsAndScroll((err, values) => {
        console.log(values, "000000000000000");
        if (!err) {
          sessionStorage.setItem("cjAuthorization", JSON.stringify(encrypt(this.accessToken)));
          sessionStorage.setItem("cjuuid", JSON.stringify(encrypt(values.uuid)));
          sessionStorage.setItem("cjdevUsername", JSON.stringify(encrypt(values.username)));
          sessionStorage.setItem("cjnewpassword", JSON.stringify(encrypt(values.values.password)));
          sessionStorage.setItem("cjnewPass", JSON.stringify(encrypt(values.newpassword)));
          sessionStorage.setItem("cjconfirmPass", JSON.stringify(encrypt(values.confirmpassword )));
          const send2 = {
            Authorization: this.accessToken,
            uuid: encrypt(values.uuid),
            devUsername: encrypt(values.username), //设备用户名
            password: encrypt(values.password ? values.password : ""), //原密码
            newPass: encrypt(values.newpassword ? values.newpassword : ""), //新密码
            confirmPass: encrypt(
              values.confirmpassword ? values.confirmpassword : ""
            ), //确认密码
          };
          console.log(send2, "send2", encrypt(""));
          this.$http
            .post("/transfer/mdeditPass/v1", send2, {
              headers: {
                "Access-Token": localStorage.getItem("XM-Token"),
              },
            })
            .then((res) => {
              if (res) {
                if (res.code == 2000) {
                  this.pswshowChange = false;
                  this.pswform4.resetFields();
                  this.readDeviceList();
                  this.autoRefresh();
                  this.$message.destroy();
                  this.$message.success(this.$t("equipmentSuccess"));
                } else {
                  this.$message.destroy();
                  this.$message.error(this.$t("" + res.msg));
                }
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("getmailfail"));
              }
            });
        }
      });
    },
    // 添加设备信息
    submitAdd(e) {
      e.preventDefault();
      this.form5.validateFieldsAndScroll((err, values) => {
        if (!err) {
          // 在此处添加从vuex中获取的用户名和密码
          let password = values.password;
          if (password == undefined) {
            password = "";
          }
          const send = {
            // Authorization: this.$store.state.accessToken.accessToken,
            nickname: encrypt(values.nickname),
            uname: this.$store.state.userInfo.uname,
            upass: this.$store.state.userInfo.upass,
            uuid: encrypt(values.uuid),
            username: encrypt(values.username),
            password: encrypt(password),
          };
          this.$http
            .post("/transfer/mdadd/v1", send, {
              headers: {
                "Access-Token": localStorage.getItem("XM-Token"),
              },
            })
            .then((res) => {
              if (res.code == 2000) {
                this.$message.destroy();
                // this.$message.success(this.$t(showmessage(res)));
                this.$message.success(this.$t("addSuccess"));
                this.showAdd = false;
                this.autoRefresh();
                this.readDeviceList();
                this.form5.resetFields();
              } else if (res.code == 4101) {
                this.$message.destroy();
                // this.$message.error(this.$t("addFail"));
                this.$message.error(this.$t("" + showmessage(res)));
                this.showAdd = false;
                this.form5.resetFields();
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
                this.form5.resetFields();
              }
            });
        }
      });
    },
    AddCancel() {
      this.form5.resetFields();
    },
    changeCancel() {
      this.form4.resetFields();
    },
    pswchangeCancel() {
      this.pswform4.resetFields();
    },
    // 删除设备信息   删除后有问题
    del(item) {
      var that = this;
      this.$confirm({
        title: this.$t("delmessage"),
        onOk() {
          const values = {
            uuid: encrypt(item.uuid),
            uname: that.$store.state.userInfo.uname,
            upass: that.$store.state.userInfo.upass,
          };
          that.$http
            .post("/transfer/mdrm/v1", values, {
              headers: {
                "Access-Token": localStorage.getItem("XM-Token"),
              },
            })
            .then((res) => {
              if (res.code == 2000) {
                that.autoRefresh();
                that.readDeviceList();
                that.$message.destroy();
                that.$message.success(that.$t("delsucess"));
              } else {
                that.$message.destroy();
                that.$message.error(that.$t("" + showmessage(res)));
              }
            });
        },
        onCancel() {},
      });
    },
  },
};
</script>
<style scoped>
.topbox {
  display: flex;
}
th.column-money,
td.column-money {
  text-align: right !important;
}
.box {
  margin: 15px;
  padding: 15px;
  background-color: white;
  height: calc(100vh - 95px);
  border-radius: 5px;
}
.ant-table-thead > tr > th {
  font-size: 15px;
}

.editable-cell {
  position: relative;
}
.modalbox >>> .ant-form-item {
  margin-bottom: 12px;
}
.modalbox >>> .ant-modal-title {
  font-size: 30px !important;
}
.modalbox >>> .ant-modal-footer div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.addmodal >>> .ant-modal-title {
  font-size: 30px !important;
}
.addmodal >>> .ant-modal-footer div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
.tips {
  display: block;
  font-size: 14px;
  max-width: 185px;
}
.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.table-box >>> .ant-table-thead > tr > th {
  font-weight: 700;
}

/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/

::-webkit-scrollbar-track {
  width: 6px;
  background-color: #ffffff;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

/*滚动条的设置*/

::-webkit-scrollbar-thumb {
  background-color: #02020282;
  background-clip: padding-box;
  height: 15px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
/*滚动条移上去的背景*/

::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}
/* .table-box >>> .ant-table-body {
  min-height: 64vh;
} */
.button {
  margin-right: 20px;
}
.button1 {
  position: absolute !important;
  right: 0;
  margin: 0 15px;
}
.addbutton {
  width: 100%;
  height: 4vh;
  position: relative;
  margin-bottom: 2vh;
}
</style>
