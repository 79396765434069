module.exports = {
  //2022-6 新增
  cloudinfo: {
    normal: "正常使用",
    timeOut: "已過期",
    nonactivated: "未開通",
    notSupport: "不支持",
    noRemainingFlow: "無剩餘流量",
    VipStatus: "Vip狀態",
    totalFlow: "總流量",
    residualFlow: "剩餘流量",
    expireDate: "到期時間"
  },
  //新增
  cloud: "雲存儲",
  cloudTimeOut: "雲存儲(過期)",
  cloudNormal: "雲存儲（使用中）",
  cloudNonActivated: "雲存儲（未開通）",
  cloudnotSupported: "雲存儲(不支持)",
  vipTimeOut: "4G流量（已過期）",
  vipNormal: "4G流量（使用中）",
  vipNonActivated: "4G流量（未開通）",
  vipnotSupported: "4G流量(不支持)",
  // 繁体中文
  // 登录页
  userLogin: "用戶登錄",
  equipLogin: "設備登錄",
  login: "登錄",
  regist: "立即註冊",
  findPassword: "忘記密碼",
  userRule: "請輸入用戶名",
  passwordRule: "請輸入密碼",
  codeRule: "請輸入驗證碼",
  equipIdRule: "請輸入裝置編號",
  appDownload: "App 下載",
  webUnitDownload: "Web控制項下載",
  officialEdition: "經典版",
  proEdition: "PRO版",
  codeLenRule: "請輸入4位數驗證碼",
  notEmpty: "請勿輸入空格",
  callUs: "聯繫我們",

  // 注册
  userRegist: "用戶註冊",
  emailRule: "請輸入郵箱",
  emailtrue: "請輸入正確格式的郵箱號",
  phonetrue: "請輸入正確格式的手機號碼",//请输入正确的手机号码
  confirmPasswordRule: "請再次輸入密碼",
  hadCount: "已有帳號，立即登錄",
  registCount: "註冊",
  hadUser: "您注册的用戶名已存在",
  hadMail: "您注册的郵箱已存在",
  sendSuccess: "郵件發送成功請注意查收",
  mailFormatError: "郵箱格式錯誤，請檢查",
  codeError: "驗證碼錯誤",
  registerSuccess: "註冊成功",
  mailNotFind: "您輸入的郵箱不存在，請檢查",
  userNameLen: "請輸入4~8位用戶名",
  userPwdLen: "請輸入8~32位密碼",
  privacyPolicy: "閱讀並選擇隱私政策",//阅读并选择隐私政策
  emailRegistration: "郵箱註冊",//邮箱注册
  mobileRegistration: "手機註冊",//手机注册
  // 找回密码
  phoneRetrievePassword: "手機找回",//手机找回密码
  mailboxRetrievalPassword: "郵箱找回",//邮箱找回密码
  findThePassword: "找回密碼",
  passwordText: "請輸入註冊帳號時使用的郵箱來找回密碼",
  exit: "退出",
  nextStep: "下一步",
  reset: "重新設定",
  getCode: "獲取驗證碼",
  someTime: "秒後重新獲取",
  ok: "確定",
  mailSendMuch: "郵件每個郵箱一天只能發送三次",
  mailChangeSuccess: "修改成功",
  pwdError: "密碼格式不正確",
  codeTimeout: "驗證碼過期", //验证码过期
  codeErrorMuch: "錯誤次數過多,請稍後再試", //验证码错误次数过多,请稍后再试
  backLogin: "返回登錄", //返回登录
  mailFast: "短時間內不允許重複發送郵件", //短时间内不允许重复发送邮件

  //主页面
  refresh: "重繪",
  myDevice: "我的設備",
  personalInformation: "個人資訊",
  deviceManagement: "設備管理",
  VIPTraffic: "VIP流量",//VIP流量
  changePassword: "修改密碼",
  changePasswordSuccess: "修改密碼成功",
  LogOut: "登出",
  shurLogOut: "你确定要退出吗?",
  oldPassword: "舊密碼",
  newPassword: "新密碼",
  inputNewPassword: "再次輸入新密碼",
  errorRefresh: "重繪失敗",
  online: "在線",
  offline: "離線",
  UidLength: "請輸入16或20位由大,小寫字母,數字組成的設備id",
  PwordLength: "請輸入不大於100比特由大，小寫字母，數位組成的設備密碼",
  passwordrule: "請勿輸入特殊符號",
  equipOffline: "設備暫時不線上請選擇其它設備吧",
  BrowserSupport: "此瀏覽器不支持插件播放，請使用谷歌瀏覽器",
  embedBrowserFail: "綁定瀏覽器失敗，請在登錄時勿快速切換頁面",
  noPlugin: "未檢測到插件",

  CancelAccount: "註銷賬戶",
  CancelAccountTitle: "你確定要註銷賬戶嗎？",
  CancelSuccess: "註銷成功",
  // 设备列表、
  order: "序號",
  equipmentPwd: "設備密碼",
  equipmentOrder: "設備序號",
  equipmentName: "設備名稱",
  equipmentUser: "設備用戶名",
  option: "操作",
  addEquipment: "添加設備",
  changeEquipmentMessage: "修改設備資訊",
  changePasswordMessage:"修改密碼資訊",//修改密码信息
  deleteEquipment:"删除設備",//删除设备
  delmessage: "你確定要删除嗎",
  all: "共",
  tips: "條數據",
  add: "添加",
  equipmentSuccess: "設備資訊修改成功",
  addUid: "請輸入你要添加的設備序號",
  addid: "請輸入你要添加的序號",
  addname: "請輸入你要添加的設備名",
  addUsername: "請輸入你要添加的設備用戶名",

  // 用户信息
  userinfo: "用戶資訊",
  userName: "用戶名",
  usercontact: "聯繫方式",
  useremail: "電子郵箱",

  // 全局提示
  successLogin: "登入成功",
  errorLogin1: "登入失敗，用戶名或密碼錯誤",
  errorLogin2: "登入失敗，驗證碼錯誤",
  errorLogin3: "登錄失敗，驗證碼過期",
  errorLogin4: "登錄失敗次數過多，賬戶鎖定10分鐘",
  delsucess: "删除成功",
  addSuccess: "添加成功",
  addFail: "添加失敗",
  timeOut: "登入超時，自動跳轉登入頁",
  changeEquipmentPwdError: "原設備密碼輸入錯誤",

  // 网络提示
  accessnot: "訪問被拒絕",
  resourcenot: "資源不可用",
  nopage: "找不到頁面",
  methoderror: "不允許此方法",
  serveerror: "服務器錯誤",
  unknownerror: "未知錯誤",

  // 表单提示
  emailTip: "**@**.***",
  phoneTip: "請輸入正確的手機號碼",
  confirmTip: "請與密碼保持一致",
  equipmentIdTip: "請輸入16或20位設備編碼",
  equipmentUserTip: "請輸入設備用戶名",
  equipmentPasswordTip: "請輸入設備密碼，沒有則無需輸入",
  equipmentPasswordTip1: "請輸入設備密碼",
  codeTip: "請輸入獲取的郵箱驗證碼",
  equipmentPwdTip: "設備密碼默認為空",
  equipmentNameTip: "請輸入裝置名稱",
  equipmentUserNameTip: "設備用戶名默認為“admin”",
  pagetip1: "按一下左邊“我的設備”中的設備，對設備進行預覽，重播，配寘等操作",
  pagetip2:
    "如果您是第一次訪問，請下載外掛程式，可能需要花費幾分鐘時間，請耐心等待",
  passwordChangeTip: "若不修改密碼請保持為空",
  loginUseNameTip: "可使用用戶名、郵箱號、手機號登入",

  placeholderUser: "用戶名/郵箱號/手機號",
  placeholderPassword: "密碼",
  placeholderCode: "驗證碼",
  placeholderEquipId: "16或20位設備編碼",
  placeholderEquipAdmin: "設備用戶名",
  placeholderEquipPassword: "設備密碼",
  placeholderRegistUser: "用戶名",
  placeholderRegistMail: "郵箱",
  placeholderRegistPhone: "手機", //手机
  placeholderRegistCode: "驗證碼",
  placeholderRegistPwd: "密碼",
  placeholderRegistPwd2: "確認密碼",

  supportTitle: "技術支援聯繫方式",
  supportPhone: "服務熱線",
  supportMail: "服務郵箱",
  supportTime: "服務時間：週一到週六8:30--17:30（法定節假日除外）",
  userPwdRule: "密碼格式錯誤",
  userNameRule: "用戶名格式錯誤",
  Ok: "確認",
  cancel: "取消",
  backOld: "回到舊版",

  cookie:
    "我們的網站使用cookies以改進我們的網站和改善用戶體驗。如您繼續瀏覽本網站而未更改瀏覽器cookie設置，視為同意我們的cookies使用策略。",
  cookieLink: "政策",
  agree: "同意",
  notAgree: "不同意",

  cookiePolicy: "Cookie 政策", //Cookie 政策
  cookieUse: "我們如何使用Cookie和同類技術", //我们如何使用Cookie和同类技术
  cookieWhy:
    "為確保網站正常運轉，我們會在您的計算機或移動設備上存儲名為Cookie的小數據檔。 Cookie通常包含標識符、站點名稱以及一些號碼和字符。借助於Cookie，網站能夠存儲您的偏好等數據。", //为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据档。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。
  cookieFor:
    "我們不會將Cookie用於本政策所述目的之外的任何用途。您可根據自己的偏好管理或刪除 Cookie。有關詳情，請參見AboutCookies.org。您可以清除計算機上保存的所有Cookie，大部分網絡瀏覽器都設有阻止Cookie的功能。但如果您這麼做，則需要在每一次訪問我們的網站時親自更改用戶設置。如需詳細了解如何更改瀏覽器設置，請訪問您使用的瀏覽器的相關設置頁面。但請注意，如果您不接受cookie，可能無法全面體驗網站的某些功能。", //我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。但请注意，如果您不接受cookie，可能无法全面体验网站的某些功能。
  cookieList: "以下是網站使用的cookie種類列表：", //以下是网站使用的cookie种类列表：
  cookiePurpose: "目的", //目的
  cookieDescribe: "描述", //描述
  cookieType: "種類和失效時間", //种类和失效时间
  cookiePerformance: "性能（用戶瀏覽器）", //性能（用户浏览器）
  cookieLogin: "記錄用戶登錄狀態，以及7天內不用重新登錄", //记录用户登录状态，以及7天内不用重新登录
  cookieDay: "7天", //7天
  cookieCnzz: "CNZZ", //CNZZ
  cookieTime: "記錄用戶訪問網站的次數", //记录用户访问网站的次数
  cookieForever: "永久", //永久
  cookieTrack:
    "很多網絡瀏覽器均設有Do Not Track功能，該功能可向網站發布Do NotTrack請求。目前，主要互聯網標準組織尚未設立相關政策來規定網站應如何應對此類請求。但如果您的瀏覽器啟用了Do Not Track，那麼我們的網站都會尊重您的選擇。", //很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do NotTrack请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站都会尊重您的选择。
  backHome: "回到首頁",//回到首页
  RS2000: '執行成功',//執行成功
  RS4000: '用戶名或密碼錯誤',//用戶名或密碼錯誤
  RS4010: '驗證碼錯誤',//驗證碼錯誤
  RS4011: '密碼不一致',//密碼不一致
  RS4012: '用戶名已被注册',//用戶名已被注册
  RS4013: '用戶名為空',//用戶名為空
  RS4014: '密碼為空',//密碼為空
  RS4015: '確認密碼為空',//確認密碼為空
  RS4016: '手機號為空',//手機號為空
  RS4017: '用戶名格式不正確',//用戶名格式不正確
  RS4018: '密碼格式不正確',//密碼格式不正確
  RS4019: '確認密碼格式不正確',//確認密碼格式不正確
  RS4020: '手機號格式不正確',//手機號格式不正確
  RS4021: '手機號已注册',//手機號已注册
  RS4022: '手機號未注册',//手機號未注册
  RS4023: '郵箱已注册',//郵箱已注册
  RS4024: '郵箱未注册',//郵箱未注册
  RS4025: '用戶名未注册',//用戶名未注册
  RS4026: '原密碼錯誤',//原密碼錯誤
  RS4027: '修改密碼失敗',//修改密碼失敗
  RS4029: '用戶ID為空',//用戶ID為空
  RS4030: '驗證碼為空',//驗證碼為空
  RS4031: '郵箱為空',//郵箱為空
  RS4032: '郵箱格式不正確',//郵箱格式不正確
  RS4033: '郵箱格式不正確',//郵箱格式不正確
  RS4034: '微信沒有綁定',//微信沒有綁定
  RS4035: '綁定微信失敗',//綁定微信失敗
  RS4036: '綁定手機號失敗',//綁定手機號失敗
  RS4037: '綁定郵箱失敗',//綁定郵箱失敗
  RS4038: '發送驗證碼超過最大次數',//發送驗證碼超過最大次數
  RS4039: '注册失敗',//注册失敗
  RS4040: '微信已綁定用戶',//微信已綁定用戶
  RS4041: '沒有許可權修改用戶名（僅針對生成的匿名使用者修改）',//沒有許可權修改用戶名（僅針對生成的匿名使用者修改）
  RS4042: 'USER_ NOT_ FB_ BIND用戶沒有綁定facebook',//USER_ NOT_ FB_ BIND用戶沒有綁定facebook
  RS4043: 'USER_ FAIL_ FB_ BIND用戶綁定facebook失敗',//USER_ FAIL_ FB_ BIND用戶綁定facebook失敗
  RS4044: 'USER_ NOT_ GG_ BIND用戶沒有google綁定',//USER_ NOT_ GG_ BIND用戶沒有google綁定
  RS4045: 'USER_ FAIL_ GG_ BIND用戶綁定google失敗',//USER_ FAIL_ GG_ BIND用戶綁定google失敗
  RS4046: 'Line帳戶未綁定',//Line帳戶未綁定
  RS4047: 'Line帳戶綁定失敗',//Line帳戶綁定失敗
  RS4100: '設備非法不允許添加',//設備非法不允許添加
  RS4101: '設備已存在',//設備已存在
  RS4102: '删除設備失敗',//删除設備失敗
  RS4103: '修改失敗',//修改失敗
  RS4104: '設備uuid參數异常',//設備uuid參數异常
  RS4105: '設備用戶名參數异常',//設備用戶名參數异常
  RS4106: '設備密碼參數异常',//設備密碼參數异常
  RS4116: '將設備主帳戶移交給其他用戶失敗，檢查用戶是否擁有設備並且該用戶擁有設備主帳戶許可權',//將設備主帳戶移交給其他用戶失敗，檢查用戶是否擁有設備並且該用戶擁有設備主帳戶許可權
  RS4117: '不是主帳戶',//不是主帳戶
  RS4118: '用戶沒有此設備',//用戶沒有此設備
  RS4119: '添加設備不唯一，其它帳戶已添加',//添加設備不唯一，其它帳戶已添加
  RS4120: '添加設備最大限制（100個）',//添加設備最大限制（100個）
  RS4126: '設備支持權杖只能被一個帳戶添加',//設備支持權杖只有一個帳戶添加
  RS4127: '缺少設備權杖',//缺少設備權杖
  RS4300: '發送失敗',//發送失敗
  RS4302: '發送註銷驗證碼成功',//發送註銷驗證碼成功
  RS4400: '簡訊介面驗證失敗，請聯繫我們。',// 簡訊介面驗證失敗，請聯繫我們
  RS4401: '簡訊介面參數錯誤，請聯繫我們',//簡訊介面參數錯誤，請聯繫我們
  RS4402: '每個手機號一天只能發送三次簡訊',//每個手機號一天只能發送三次簡訊
  RS4403: '發送失敗，請稍後再試',//發送失敗，請稍後再試
  RS4404: '發送太頻繁了，請間隔120秒',//發送太頻繁了，請間隔120秒
  RS4405: '發送失敗',//發送失敗
  RS5000: '服務器故障',//服務器故障
  RS5001: '證書不存在',//證書不存在
  RS5002: '請求頭資訊錯誤',//請求頭資訊錯誤
  RS5003: '證書失效',//證書失效
  RS5004: '生成金鑰校驗錯誤',//生成金鑰校驗錯誤
  RS5005: '參數异常',//參數异常
  RS5009: '微信code錯誤、AES加解密錯誤',//微信code錯誤、AES加解密錯誤
  RS5010: 'token已過期',//token已過期
  RS5011: 'token錯誤',//token錯誤
  RS5012: 'token無許可權',//token無許可權
  RS5013: '不支持',//不支持
  RS5014: '操作太頻繁',//操作太頻繁
  RS4050: '操作太頻繁',//操作太頻繁
  phoneCodeTip:'請輸入獲取的手機驗證碼',//======請輸入獲取的手機驗證碼
  phoneNotFind:'您輸入的手機號碼不存在，請檢查',//=====您輸入的手機號碼不存在，請檢查
  phoneFormatError:'手機號碼格式錯誤，請檢查',//======手機號碼格式錯誤，請檢查
  phoneSendSuccess:'驗證碼發送成功請注意查收',//=======驗證碼發送成功請注意查收
  hadPhone:'您的注册手機號碼已存在',//======您的注册手機號碼已存在
  phoneRule:'請輸入手機號碼',//======請輸入手機號碼


  useNameTip:"4-15比特字元，可由中文、字母、數位、_ 和-裏兩項及以上組成", //4-15位字符，可由中文、数字、_和-组成
  passwordTip:"8-32比特字元長度，可由英文字母、數位、底線和-裏兩項及以上組成", //8-32位字符长度，由英文字母、数字、下划线和-组成
  NameLength: "請輸入不大於255比特由大，小寫字母，數位，中文組成的設備名稱 ",
  UnameLength: "請輸入不大於255比特由大，小寫字母，數位，中文組成的設備用戶名",
  placeTip:"請輸入序號",
  deleteAll:"批量删除",

    // nettimeout:"网络超时",
    accessTokenfail:"服務超時，獲取accessToken失敗",
    userinfo2faile:"服務超時，用戶資訊獲取失敗",
    getcodefail:"服務超時，獲取驗證碼失敗",
    getmailfail:"服務超時，修改失敗",
    getdevicefail:"服務超時，無法獲取設備清單",
    getareacodefail:"服務超時，無法獲取區號清單",
    rulenamefail:"服務超時，驗證用戶名失敗",
    rulephonefail:"服務超時，驗證用戶手機號失敗",
    ruleregisterfail:"服務超時，注册失敗",
    ruleemailfail:"服務超時，驗證郵箱失敗",
    getdevicestatusfail:"服務超時，無法獲取設備狀態",
  
    oldequipmentpassword:"原密碼",
    oldpasswordrule:"請輸入裝置原密碼，沒有則無需輸入",
    newequipmentpassword:"新密碼",
    newpasswordrule:"請輸入裝置新密碼，沒有則無需輸入",
    confirmequippassword:"確認密碼",
    confirmeqpasswordrule:"請與新密碼保持一致",
};
