module.exports = {
  //2022-6 新增
  cloudinfo: {
    normal: "Normální používání",
    timeOut: "platnost vypršela",
    nonactivated: "neaktivovaný",
    notSupport: "nepodporovat",
    noRemainingFlow: "Žádný zbývající průtok",
    VipStatus: "VIP status",
    totalFlow: "celkový průtok",
    residualFlow: "zbytkový průtok",
    expireDate: "Datum spotřeby"
  },
  //新增
  cloud: "cloudové úložiště",
  cloudTimeOut: "Cloudové úložiště (vypršela platnost)",
  cloudNormal: "Cloudové úložiště (v používání)",
  cloudNonActivated: "Cloudové úložiště (neotevřené)",
  cloudnotSupported:"Cloud úložiště (není k dispozici)",
  vipTimeOut: "4G provoz (vypršel)",
  vipNormal: "4G provoz (v používání)",
  vipNonActivated: "4G provoz (neotevřený)",
  vipnotSupported:"4G provoz (není k dispozici)",
  // 捷克语
  // 登录页
  userLogin: "Uživatelské přihlášení", //用户登录
  equipLogin: "login zařízení", //设备登录
  login: "Přihlásit", //登录
  regist: "Zaregistrujte se hned", //立即注册
  findPassword: "Načíst heslo", //找回密码
  userRule: "prosím zadejte uživatelské jméno", //请输入用户名
  passwordRule: "Prosím zadejte heslo", //请输入密码
  codeRule: "prosím zadejte ověřovací kód", //请输入验证码
  equipIdRule: "Prosím zadejte sériové číslo zařízení", //请输入设备序列号
  appDownload: "App Stažení", //App 下载
  webUnitDownload: "Instalace ActiveX", //Web控件下载
  callUs: "Kontaktujte nás", //联系我们
  officialEdition: "Klasická edice", //经典版
  proEdition: "PRO edice", //PRO版
  codeLenRule: "Zadejte prosím čtyřmístný ověřovací kód", //请输入4位验证码
  notEmpty: "Nezadávejte mezery", //请勿输入空格

  // 注册
  userRegist: "vytvořit účet", //创建账户
  emailRule: "Zadejte prosím číslo schránky", //请输入邮箱
  emailtrue: "Zadejte prosím číslo schránky ve správném formátu", //请输入正确格式的邮箱号
  phonetrue:"Zadejte prosím číslo mobilního telefonu ve správném formátu",//请输入正确的手机号码
  confirmPasswordRule: "Prosím zadejte heslo znovu", //请再次输入密码
  hadCount: "Okamžitě se přihlaste", //已有账号，立即登录
  registCount: "Registrovat", //注册
  hadUser: "Vaše registrované uživatelské jméno již existuje", //您注册的用户名已存在
  hadMail: "Váš registrovaný e -mail již existuje", //您注册的邮箱已存在
  sendSuccess: "Pošta byla úspěšně odeslána", //邮件发送成功
  mailFormatError: "Chyba formátu e-mailu, prosím, check", //邮箱格式错误，请检查
  codeError: "Chyba ověřovacího kódu", //验证码错误
  registerSuccess: "úspěšná registrace", //注册成功
  mailNotFind: "Zadaná e -mailová adresa neexistuje, zkontrolujte ji", //您输入的邮箱不存在，请检查
  userNameLen: "Zadejte 4-8 uživatelská jména", //请输入4~8位用户名
  userPwdLen: "Zadejte 8-32-místné heslo", //请输入8~32位密码
  privacyPolicy:"Přečtěte si a vyberte zásady ochrany osobních údajů",//阅读并选择隐私政策
  emailRegistration:"Registrace e-mailem",//邮箱注册
  mobileRegistration:"Mobilní registrace",//手机注册

  // 找回密码
  phoneRetrievePassword:"Mobilní telefon pro získání hesla",//手机找回密码
  mailboxRetrievalPassword:"Vyhledávání hesla poštovní schránky",//邮箱找回密码
  findThePassword: "Načíst heslo", //找回密码
  passwordText:
    "Chcete -li obnovit heslo, zadejte e -mailovou adresu použitou při registraci účtu", //请输入注册账号时使用的邮箱来找回密码
  exit: "odhlásit se", //退出
  nextStep: "Další krok", //下一步
  reset: "Obnovit", //重新设置
  getCode: "poslat", //获取验证码
  someTime: "Ještě jednou za pár sekund", //秒后重新获取
  ok: "potvrdit", //确定
  mailSendMuch:
    "Poštu lze odeslat pouze třikrát denně z jedné poštovní schránky", //邮件每个邮箱一天只能发送三次
  mailChangeSuccess: "Úspěšně upraveno", //修改成功
  pwdError: "Nesprávný formát hesla", //密码格式不正确
  codeTimeout: "Platnost ověřovacího kódu vypršela", //验证码过期
  codeErrorMuch: "Příliš mnoho chyb, zkuste to prosím později", //错误次数过多,请稍后再试
  backLogin: "Zpět k přihlášení", //返回登录
  mailFast: "Opakované e -maily nejsou povoleny během krátké doby", //短时间内不允许重复发送邮件

  //主页面
  refresh: "Občerstvit", //刷新
  myDevice: "Moje zařízení", //我的设备
  personalInformation: "Osobní informace", //个人信息
  deviceManagement: "Správa zařízení", //设备管理
  VIPTraffic:"VIP provoz",//VIP流量
  changePassword: "Změnit heslo", //修改密码
  changePasswordSuccess: "Úspěšně změna hesla", //修改密码成功
  LogOut: "Odhlásit se", //退出登录
  shurLogOut: "Jste si jisti, že chcete skončit", //你确定要退出吗
  oldPassword: "Staré heslo", //旧密码
  newPassword: "Nové heslo", //新密码
  inputNewPassword: "Znovu zadejte nové heslo", //再次输入新密码
  errorRefresh: "obnova selhala", //刷新失败
  online: "Online", //在线
  offline: "Offline", //离线
  UidLength:"Zadejte prosím 16 nebo 20místné sériové číslo zařízení skládající se z velkých a malých písmen a číslic", //请输入16或20位由大,小写字母,数字组成的设备序列号
  NameLength: "Zadejte prosím název zařízení s nejvýše 255 znaky sestávající z velkých a malých písmen, čísel a čínštiny",
  UnameLength: "Zadejte prosím uživatelské jméno zařízení s nejvíce než 255 znaky sestávající z velkých a malých písmen, čísel a čínských ",
  PwordLength: "Zadejte prosím heslo zařízení s nejvíce než sto znaků, sestávající z velkých a malých písmen a čísel",
  passwordrule: "Nezadávejte speciální symboly", //请勿输入特殊符号
  equipOffline: "Zařízení je dočasně offline, vyberte prosím jiné zařízení", //设备暂时不在线请选择其它设备吧
  BrowserSupport:
    "Tento prohlížeč nepodporuje přehrávání pomocí zásuvných modulů, použijte prosím Google Chrome", //此浏览器不支持插件播放，请使用IE或360浏览器
  embedBrowserFail:
    "Nepodařilo se svázat prohlížeč, nepřepínejte stránky rychle při přihlašování", //绑定浏览器失败，请在登录时勿快速切换页面
  noPlugin: "Plugin nebyl rozpoznán", //未检测到插件

  CancelAccount: "Zrušit účet",
  CancelAccountTitle: "Opravdu chcete účet smazat?",
  CancelSuccess: "Účet úspěšně zrušit",
  // 设备列表、
  order: "Sériové číslo", //序号
  equipmentPwd: "Heslo zařízení", //设备密码
  equipmentOrder: "sériové číslo", //序列号
  equipmentName: "Název zařízení", //名称
  equipmentUser: "Uživatel", //设备用户名
  option: "Operovat", //操作
  addEquipment: "Přidat zařízení", //添加设备
  changeEquipmentMessage: "Upravit informace o zařízení", //修改设备信息
  changePasswordMessage:"Upravit heslo",//修改密码信息
  deleteEquipment:"Smazat zařízení",//删除设备
  delmessage: "Jste si jistí, že to chcete smazat", //你确定要删除吗
  all: "Úhrnem", //共
  tips: "údaje", //条数据
  add: "Přidat", //添加
  equipmentSuccess: "Informace o zařízení úspěšně změněny", //设备信息修改成功
  addUid: "Zadejte prosím sériové číslo zařízení, které chcete přidat", //请输入你要添加的设备序列号
  addid: "Zadejte prosím sériové číslo, které chcete přidat", //请输入你要添加的序号
  addname: "Zadejte prosím jméno zařízení, které chcete přidat", //请输入你要添加的设备名
  addUsername: "Prosím zadejte uživatelské jméno zařízení, které chcete přidat", //请输入你要添加的设备用户名

  // 用户信息
  userinfo: "Uživatelské informace", //用户信息
  userName: "uživatelské jméno", //用户名
  usercontact: "Kontaktní informace", //联系方式
  useremail: "E-mail", //电子邮箱

  // 全局提示
  successLogin: "Přihlášení úspěšné", //登录成功
  errorLogin1:
    "Přihlášení se nezdařilo, uživatelské jméno nebo heslo je nesprávné", //登录失败，用户名或密码错误
  errorLogin2: "Přihlášení se nezdařilo, chyba ověřovacího kódu", //登录失败，验证码错误
  errorLogin3: "Přihlášení se nezdařilo, platnost ověřovacího kódu vypršela", //登录失败，验证码过期
  errorLogin4: "Příliš mnoho selhání přihlášení, účet uzamčen na 10 minut", //登录失败次数过多，账户锁定10分钟
  delsucess: "Úspěšně smazáno", //删除成功
  addSuccess: "Úspěšně přidáno", //添加成功
  addFail: "Přidání se nezdařilo", //添加失败
  timeOut:
    "Časový limit přihlášení, automaticky přejděte na přihlašovací stránku", //登录超时，自动跳转登录页
  changeEquipmentPwdError: "Původní heslo zařízení bylo zadáno nesprávně", //原设备密码输入错误

  // 网络提示
  accessnot: "Přístup odepřen", //访问被拒绝
  resourcenot: "Zdrojů nedostupný", //资源不可用
  nopage: "Stránka nenalezena", //找不到页面
  methoderror: "Tato metoda není povolena", //不允许此方法
  serveerror: "Chyba serveru", //服务器错误
  unknownerror: "Neznámá chyba", //未知错误

  // 表单提示
  useNameTip:
    "4-15 znaky, včetně čínštiny, písmen, čísel_ Sestávající ze dvou nebo více položek", //4-15位字符，可由中文、数字、_和-组成
  emailTip: "**@**.***",
  phoneTip:"Zadejte prosím správné číslo mobilu",
  passwordTip:
    "8-32 délka znaků, která může být složena z anglických písmen, čísel, podtržení a incompli nebo více", //8-32位字符长度，由英文字母、数字、下划线和-组成
  confirmTip: "Prosím, být v souladu s heslem", //请与密码保持一致
  equipmentIdTip: "Zadejte prosím 16 nebo 20místné sériové číslo zařízení", //请输入16或20位设备序列号
  equipmentUserTip: "Zadejte uživatelské jméno zařízení", //请输入设备用户名
  equipmentPasswordTip1: "Zadejte heslo zařízení", //请输入设备密码
  equipmentPasswordTip: "Zadejte heslo zařízení, jinak jej nemusíte zadávat", //请输入设备密码，没有则无需输入
  codeTip: "Zadejte získaný ověřovací kód e-mailu", //请输入获取的邮箱验证码
  equipmentPwdTip: "Heslo zařízení je ve výchozím nastavení prázdné", //设备密码默认为空
  equipmentNameTip: "Zadejte prosím název zařízení", //请输入设备名称
  equipmentUserNameTip: "Výchozí uživatelské jméno zařízení je „admin“", //设备用户名默认为“admin”
  pagetip1:
    "Klepněte na zařízení v „My Device“ na levé straně, aby náhled, přehrávání, konfigurace a dalších operací na zařízení", //单击左边“我的设备”中的设备，对设备进行预览，回放，配置等操作
  pagetip2:
    "Pokud je to vaše první návštěva, stáhněte si prosím plug-in. Může to trvat několik minut. Počkejte prosím trpělivě", //如果您是第一次访问，会自动下载插件，可能需要花费几分钟时间，请耐心等待
  passwordChangeTip: "Pokud heslo nezměníte, nechte ho prázdné", //若不修改密码请保持为空
  loginUseNameTip:
    "Přihlaste se pomocí uživatelského jména, e -mailového čísla, čísla mobilního telefonu", //可使用用户名、邮箱号、手机号登录

  placeholderUser: "Uživatelské jméno/číslo e -mailu/číslo mobilního telefonu", //用户名/邮箱号/手机号
  placeholderPassword: "Heslo", //密码
  placeholderCode: "Ověřovací kód", //验证码
  placeholderEquipId: "Kód 16 nebo 20 bitového zařízení", //设备序列号
  placeholderEquipAdmin: "Uživatelské jméno zařízení", //设备用户名
  placeholderEquipPassword: "Heslo zařízení", //设备密码
  placeholderRegistUser: "uživatelské jméno", //用户名
  placeholderRegistMail: "Číslo schránky", //邮箱
  placeholderRegistPhone: "mobilní telefon", //手机
  placeholderRegistCode: "Ověřovací kód", //验证码
  placeholderRegistPwd: "Heslo", //密码
  placeholderRegistPwd2: "Potvrďte heslo", //确认密码

  supportTitle: "Kontaktní metoda technické podpory", //技术支持联系方式
  supportPhone: "Servisní horká linka", //服务热线
  supportMail: "Poštovní schránka", //服务邮箱
  supportTime:
    "Provozní doba: pondělí až sobota 8: 30-17: 30 (kromě zákonných svátků)", //服务时间：周一到周六8:30--17:30（法定节假日除外）
  userPwdRule: "Formát hesla je špatný", //密码格式错误
  userNameRule: "Nesprávný formát uživatelského jména", //用户名格式错误
  Ok: "potvrdit", //确认
  cancel: "Zrušit", //取消
  backOld: "Zpět na starou verzi", //回到旧版

  cookie:
    "Naše webové stránky používají soubory cookie ke zlepšení našich webových stránek a zlepšení uživatelské zkušenosti. Pokud budete pokračovat v procházení těchto webových stránek beze změny nastavení souborů cookie v prohlížeči, má se za to, že souhlasíte s našimi zásadami používání souborů cookie.", //我们的网站使用cookies以改进我们的网站和改善用户体验。如您继续浏览本网站而未更改浏览器cookie设置，视为同意我们的cookies使用策略。
  cookieLink: "politika", //政策
  agree: "souhlasit", //同意
  notAgree: "nesouhlasit", //不同意

  cookiePolicy: "Zásady používání cookies", //Cookie 政策
  cookieUse: "Jak používáme soubory cookie a podobné technologie", //我们如何使用Cookie和同类技术
  cookieWhy:
    "Abychom zajistili normální provoz webových stránek, uložíme do vašeho počítače nebo mobilního zařízení malé datové soubory s názvem Cookies. Soubory cookie obvykle obsahují identifikátory, názvy stránek a některá čísla a znaky. Pomocí souborů cookie mohou webové stránky ukládat údaje, jako jsou vaše preference.", //为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据档。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。
  cookieFor:
    "Cookies nebudeme používat k žádnému jinému účelu, než je uvedeno v těchto zásadách. Soubory cookie můžete spravovat nebo mazat podle svých preferencí. Podrobnosti najdete na stránce AboutCookies.org. Můžete vymazat všechny soubory cookie uložené ve vašem počítači a většina webových prohlížečů má funkci blokování souborů cookie. Pokud to ale uděláte, musíte při každé návštěvě našich webových stránek osobně změnit uživatelské nastavení. Chcete -li se dozvědět více o tom, jak změnit nastavení prohlížeče, navštivte příslušnou stránku nastavení prohlížeče, který používáte. Mějte však na paměti, že pokud nepřijmete soubory cookie, možná nebudete moci plně využívat některé funkce webových stránek.", //我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。但请注意，如果您不接受cookie，可能无法全面体验网站的某些功能。
  cookieList:
    "Následuje seznam typů souborů cookie používaných webovými stránkami:", //以下是网站使用的cookie种类列表：
  cookiePurpose: "Účel", //目的
  cookieDescribe: "popsat", //描述
  cookieType: "Typ a doba platnosti", //种类和失效时间
  cookiePerformance: "Výkon (prohlížeč uživatele)", //性能（用户浏览器）
  cookieLogin:
    "Zaznamenejte si stav přihlášení uživatele a nemusíte se do 7 dnů znovu přihlašovat", //记录用户登录状态，以及7天内不用重新登录
  cookieDay: "7 dní", //7天
  cookieCnzz: "CNZZ", //CNZZ
  cookieTime: "Zaznamenejte, kolikrát uživatel web navštívil", //记录用户访问网站的次数
  cookieForever: "trvalé", //永久
  cookieTrack:
    "Mnoho webových prohlížečů má funkci Do Not Track, která umožňuje odesílat požadavky Do NotTrack na webové stránky. V současné době velké organizace zabývající se internetovými standardy nezavedly relevantní zásady, které by upravovaly, jak by weby měly na takové žádosti reagovat. Pokud však váš prohlížeč umožňuje nesledovat, pak naše webové stránky budou respektovat vaši volbu.", //很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do NotTrack请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站都会尊重您的选择。
  backHome: "zpět na domovskou stránku", //回到首页

  // RS接口返回状态码
  RS2000: 'úspěch',//执行成功
  RS4000: 'Nesprávné uživatelské jméno nebo heslo',//用户名或密码错误
  RS4010: 'Chyba ověřovacího kódu',//验证码错误
  RS4011: 'Heslo je nekonzistentní',//密码不一致
  RS4012: 'Uživatelské jméno bylo zaregistrováno',//用户名已被注册
  RS4013: 'Uživatelské jméno je prázdné',//用户名为空
  RS4014: 'Heslo je prázdné',//密码为空
  RS4015: 'Potvrďte, že heslo je prázdné',//确认密码为空
  RS4016: 'Mobilní číslo je prázdné',//手机号为空
  RS4017: 'Nesprávný formát uživatelského jména',//用户名格式不正确
  RS4018: 'Nesprávný formát hesla',//密码格式不正确
  RS4019: 'Potvrďte, že formát hesla je nesprávný',//确认密码格式不正确
  RS4020: 'Formát čísla mobilního telefonu je nesprávný',//手机号格式不正确
  RS4021: 'Registrované číslo mobilního telefonu',//手机号已注册
  RS4022: 'Mobilní číslo není registrováno',//手机号未注册
  RS4023: 'Email registrovaný',//邮箱已注册
  RS4024: 'Poštovní schránka není registrována',//邮箱未注册
  RS4025: 'Uživatelské jméno není registrováno',//用户名未注册
  RS4026: 'Původní heslo je nesprávné',//原密码错误
  RS4027: 'Nepodařilo se změnit heslo',//修改密码失败
  RS4029: 'Uživatelské ID je prázdné',//用户ID为空
  RS4030: 'Ověřovací kód je prázdný',//验证码为空
  RS4031: 'Poštovní schránka je prázdná',//邮箱为空
  RS4032: 'Nesprávný formát poštovní schránky',//邮箱格式不正确
  RS4033: 'Nesprávný formát poštovní schránky',//邮箱格式不正确
  RS4034: 'WeChat není vázán',//微信没有绑定
  RS4035: 'Nepodařilo se vázat WeChat',//绑定微信失败
  RS4036: 'Nepodařilo se zavázat číslo mobilního telefonu',//绑定手机号失败
  RS4037: 'Nepodařilo se vázat poštovní schránku',//绑定邮箱失败
  RS4038: 'Odeslat ověřovací kód více než maximální počet krát',//发送验证码超过最大次数
  RS4039: 'přihlášení selhalo',//注册失败
  RS4040: 'WeChat svázal uživatele',//微信已绑定用户
  RS4041: 'Žádné oprávnění ke změně uživatelského jména (pouze pro generované anonymní uživatele)',//没有权限修改用户名（仅针对生成的匿名用户修改）
  RS4042: 'Uživatel_ NOT_ FB_ Uživatel BIND není vázán na Facebook',//USER_NOT_FB_BIND 用户没有绑定facebook
  RS4043: 'Uživatel_ FAIL_ FB_ Uživatel BIND se nepodařilo připojit k Facebooku',//USER_FAIL_FB_BIND 用户绑定facebook失败
  RS4044: 'Uživatel_ NOT_ GG_ Uživatelé BIND nemají Google závazky',//USER_NOT_GG_BIND 用户没有google绑定
  RS4045: 'Uživatel_ FAIL_ GG_ Uživatel BIND se nepodařilo připojit k Googlu',//USER_FAIL_GG_BIND 用户绑定google失败
  RS4046: 'Linový účet není vázán',//Line账户未绑定
  RS4047: 'Vazba řádkového účtu selhala',//Line账户绑定失败
  RS4100: 'Zařízení nesmí být přidáno nelegálně',//设备非法不允许添加
  RS4101: 'Zařízení již existuje',//设备已存在
  RS4102: 'Nepodařilo se smazat zařízení',//删除设备失败
  RS4103: 'Změna selhala',//修改失败
  RS4104: 'Výjimka parametru uuid zařízení',//设备uuid参数异常
  RS4105: 'Výjimka parametru uživatelského jména zařízení',//设备用户名参数异常
  RS4106: 'Parametr hesla zařízení je abnormální',//设备密码参数异常
  RS4116: 'Nepodařilo se předat hlavní účet zařízení jinému uživateli. Zkontrolujte, zda uživatel má oprávnění hlavního účtu zařízení a zda uživatel má oprávnění hlavního účtu zařízení',//将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限
  RS4117: 'Není hlavní účet',//不是主账户
  RS4118: 'Uživatel toto zařízení nemá',//用户没有此设备
  RS4119: 'Přidané zařízení není jedinečné. Další účty byly přidány',//添加设备不唯一，其它账户已添加
  RS4120: 'Maximální limit přidávání zařízení (100)',//添加设备最大限制(100个)
  RS4126: 'Token podpory zařízení může být přidán pouze jedním účtem',//设备支持令牌只有一个账户添加
  RS4127: '	Chybějící token zařízení',//缺少设备令牌
  RS4300: 'selže odeslání',//发送失败
  RS4302: 'Úspěšně odeslaný ověřovací kód zrušení',//发送注销验证码成功
  RS4400: 'Ověření SMS rozhraní selhalo, kontaktujte nás prosím',//短信接口验证失败，请联系我们
  RS4401: 'Chyba parametru SMS rozhraní, kontaktujte nás prosím',//短信接口参数错误，请联系我们
  RS4402: 'Každé číslo mobilního telefonu může posílat SMS pouze třikrát denně',//每个手机号一天只能发送三次短信
  RS4403: 'Odeslání selhalo. Zkuste to prosím znovu později',//发送失败，请稍后再试
  RS4404: 'Odesílání je příliš časté, počkejte prosím 120 sekund',//发送太频繁了，请间隔120秒
  RS4405: 'selže odeslání',//发送失败
  RS5000: 'Selhání serveru',//服务器故障
  RS5001: 'Certifikát neexistuje',//证书不存在
  RS5002: 'Chyba informací o hlavičce',//请求头信息错误
  RS5003: 'Platnost certifikátu',//证书失效
  RS5004: 'Chyba ověření klíče generace',//生成密钥校验错误
  RS5005: 'Výjimka parametrů',//参数异常
  RS5009: 'Chyba kódu WeChat, chyba šifrování AES a dešifrování',//微信code错误、AES加解密错误
  RS5010: 'Token vypršel',//token 已过期
  RS5011: 'Chyba tokenu',//token 错误
  RS5012: 'Token nemá povolení.',//token 无权限
  RS5013: 'Nebudu to podporovat',//不支持
  RS5014: 'Provoz je příliš častý',//操作太频繁
  RS4050: 'Provoz je příliš častý',//操作太频繁
  phoneCodeTip: "Zadejte prosím získaný ověřovací kód mobilního telefonu",//======
  phoneNotFind: "Zadané číslo mobilu neexistuje, zkontrolujte prosím",//=====
  phoneFormatError: "Chyba formátu mobilního čísla, prosím zkontrolujte",//======
  phoneSendSuccess: "Zkontrolujte, zda byl ověřovací kód odeslán úspěšně",//=======
  hadPhone: "Vaše registrované mobilní číslo již existuje", //======
  phoneRule: "Zadejte prosím své mobilní číslo",//======
  placeTip:"Zadejte prosím sériové číslo",
  deleteAll:"Dávkové smazání",

   // nettimeout:"网络超时",
  accessTokenfail:"Časový limit služby, přístupový token selhal",
  userinfo2faile:"Časový limit služby, získávání informací o uživateli selhalo",
  getcodefail:"Časový limit služby, získání ověřovacího kódu selhalo",
  getmailfail:"Časový limit služby, změna selhala",
  getdevicefail:"Časový limit služby, nelze získat seznam zařízení",
  getareacodefail:"Časový limit služby, nelze získat seznam čísel oblasti",
  rulenamefail:"Časový limit služby, ověření uživatelského jména selhalo",
  rulephonefail:"Časový limit služby, ověření čísla mobilního telefonu uživatele selhalo",
  ruleregisterfail:"Časový limit služby, registrace selhala",
  ruleemailfail:"Časový limit služby, ověření poštovní schránky selhalo",
  getdevicestatusfail:"Časový limit služby, nelze získat stav zařízení",

  oldequipmentpassword:"Původní heslo",
  oldpasswordrule:"Zadejte prosím původní heslo zařízení. Pokud ne, nemusíte jej zadávat.",
  newequipmentpassword:"Nové heslo",
  newpasswordrule:"Zadejte prosím nové heslo pro zařízení. Pokud ne, nemusíte jej zadávat.",
  confirmequippassword:"Potvrdit heslo",
  confirmeqpasswordrule:"Udržujte prosím soulad s novým heslem",
};
