export const  showmessage = (item) =>{
    let arr =[ 'RS2000',//执行成功
    'RS4000',//用户名或密码错误
    'RS4010',//验证码错误
    'RS4011',//密码不一致
    'RS4012',//用户名已被注册
    'RS4013',//用户名为空
    'RS4014',//密码为空
    'RS4015',//确认密码为空
    'RS4016',//手机号为空
    'RS4017',//用户名格式不正确
    'RS4018',//密码格式不正确
    'RS4019',//确认密码格式不正确
    'RS4020',//手机号格式不正确
    'RS4021',//手机号已注册
    'RS4022',//手机号未注册
    'RS4023',//邮箱已注册
    'RS4024',//邮箱未注册
    'RS4025',//用户名未注册
    'RS4026',//原密码错误
    'RS4027',//修改密码失败
    'RS4029',//用户ID为空
    'RS4030',//验证码为空
    'RS4031',//邮箱为空
    'RS4032',//邮箱格式不正确
    'RS4033',//邮箱格式不正确
    'RS4034',//微信没有绑定
    'RS4035',//绑定微信失败
    'RS4036',//绑定手机号失败
    'RS4037',//绑定邮箱失败
    'RS4038',//发送验证码超过最大次数
    'RS4039',//注册失败
    'RS4040',//微信已绑定用户
    'RS4041',//没有权限修改用户名（仅针对生成的匿名用户修改）
    'RS4042',//USER_NOT_FB_BIND 用户没有绑定facebook
    'RS4043',//USER_FAIL_FB_BIND 用户绑定facebook失败
    'RS4044',//USER_NOT_GG_BIND 用户没有google绑定
    'RS4045',//USER_FAIL_GG_BIND 用户绑定google失败
    'RS4046',//Line账户未绑定
    'RS4047',//Line账户绑定失败
    'RS4100',//设备非法不允许添加
    'RS4101',//设备已存在
    'RS4102',//删除设备失败
    'RS4103',//修改失败
    'RS4104',//设备uuid参数异常
    'RS4105',//设备用户名参数异常
    'RS4106',//设备密码参数异常
    'RS4116',//将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限
    'RS4117',//不是主账户
    'RS4118',//用户没有此设备
    'RS4119',//添加设备不唯一，其它账户已添加
    'RS4120',//添加设备最大限制(100个)
    'RS4126',//设备支持令牌只能被一个账户添加
    'RS4127',//缺少设备令牌
    'RS4300',//发送失败
    'RS4302',//发送注销验证码成功
    'RS4400',//短信接口验证失败，请联系我们
    'RS4401',//短信接口参数错误，请联系我们
    'RS4402',//每个手机号一天只能发送三次短信
    'RS4403',//发送失败，请稍后再试
    'RS4404',//发送太频繁了，请间隔120秒
    'RS4405',//发送失败
    'RS5000',//服务器故障
    'RS5001',//证书不存在
    'RS5002',//请求头信息错误
    'RS5003',//证书失效
    'RS5004',//生成密钥校验错误
    'RS5005',//参数异常
    'RS5009',//微信code错误、AES加解密错误
    'RS5010',//token 已过期
    'RS5011',//token 错误
    'RS5012',//token 无权限
    'RS5013',//不支持
    'RS5014',//操作太频繁
    'RS4050',//操作太频繁
]
    if(item.code =="2000"){
        return 'RS'+item.code
    } else {
        if(arr.indexOf('RS'+item.code) == -1){
            return item.msg
        }else{
            return 'RS'+item.code
        }
        
    }
    // return item
}