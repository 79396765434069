module.exports = {
  //2022-6 新增
  cloudinfo: {
    normal: "Uso normale",
    timeOut: "è scaduto",
    nonactivated: "non attivato",
    notSupport: "non supporto",
    noRemainingFlow: "nessun traffico rimasto",
    VipStatus: "Stato VIP",
    totalFlow: "flusso totale",
    residualFlow: "flusso residuo",
    expireDate: "Data di scadenza"
  },
  //新增
  cloud: "archiviazione cloud",
  cloudTimeOut: "Archiviazione cloud (scaduta)",
  cloudNormal: "Archiviazione cloud (in uso)",
  cloudNonActivated: "Archiviazione cloud (non aperta)",
  cloudnotSupported:"Archiviazione cloud (non disponibile)",
  vipTimeOut: "Traffico 4G (scaduto)",
  vipNormal: "Traffico 4G (in uso)",
  vipNonActivated: "Traffico 4G (non aperto)",
  vipnotSupported:"Traffico 4G (non disponibile)",
  // 意大利语
  // 登录页
  userLogin: "Accesso utente", //用户登录
  equipLogin: "Accesso apparato", //设备登录
  login: "Accedi", //登录
  regist: "Iscriviti subito", //立即注册
  findPassword: "Recupera password", //找回密码
  userRule: "Si prega di inserire il nome utente", //请输入用户名
  passwordRule: "Si prega di inserire la password", //请输入密码
  codeRule: "Per favore inserisci il codice di verifica", //请输入验证码
  equipIdRule: "Si prega di inserire il numero di serie del dispositivo", //请输入设备序列号
  appDownload: "App scaricare", //App 下载
  webUnitDownload: "Download ActiveX", //Web控件下载
  callUs: "Contattaci", //联系我们
  officialEdition: "Versione classica", //经典版
  proEdition: "Versione PRO", //PRO版
  codeLenRule: "Inserisci il codice di verifica di 4 caratteri", //请输入4位验证码
  notEmpty: "Si prega di non inserire spazi", //请勿输入空格

  // 注册
  userRegist: "Creare account", //创建账户
  emailRule: "Si prega di inserire la tua email", //请输入邮箱
  emailtrue:
    "Si prega di inserire il numero della casella di posta nel formato corretto", //请输入正确格式的邮箱号
  phonetrue:"Inserisci un numero di cellulare nel formato corretto",//请输入正确的手机号码
  confirmPasswordRule: "Si prega di confermare la password", //请再次输入密码
  hadCount: "Già un account, accedi ora", //已有账号，立即登录
  registCount: "Registrati", //注册
  hadUser: "Il tuo nome utente registrato esiste già", //您注册的用户名已存在
  hadMail: "La tua email registrata esiste già", //您注册的邮箱已存在
  sendSuccess: "Posta inviata con successo", //邮件发送成功
  mailFormatError: "Errore nel formato dell'e-mail, per favore controlla", //邮箱格式错误，请检查
  codeError: "Errore codice di verifica", //验证码错误
  registerSuccess: "Registrazione riuscita", //注册成功
  mailNotFind:
    "L'indirizzo email che hai inserito non esiste, per favore controlla", //您输入的邮箱不存在，请检查
  userNameLen: "Inserisci un nome utente da 4 a 8 caratteri", //请输入4~8位用户名
  userPwdLen: "Si prega di inserire una password di 8~32 cifre", //请输入8~32位密码
  privacyPolicy:"Leggi e seleziona l'informativa sulla privacy",//阅读并选择隐私政策
  emailRegistration:"Registrazione via e-mail",//邮箱注册
  mobileRegistration:"Registrazione mobile",//手机注册

  // 找回密码
  phoneRetrievePassword:"Telefono cellulare per recuperare la password",//手机找回密码
  mailboxRetrievalPassword:"Recupero password della casella di posta",//邮箱找回密码
  findThePassword: "Recupera password", //找回密码
  passwordText:
    "Si prega di inserire l'indirizzo e-mail utilizzato durante la registrazione dell'account per recuperare la password", //请输入注册账号时使用的邮箱来找回密码
  exit: "Smettere", //退出
  nextStep: "Passo successivo", //下一步
  reset: "Reset", //重新设置
  getCode: "Inviare", //获取验证码
  someTime: "Rispedisci", //秒后重新获取
  ok: "Confermare", //确定
  mailSendMuch:
    "La posta può essere inviata solo tre volte al giorno per casella di posta", //邮件每个邮箱一天只能发送三次
  mailChangeSuccess: "Modificato con successo", //修改成功
  pwdError: "Formato password non corretta", //密码格式不正确
  codeTimeout: "Codice di verifica scaduto", //验证码过期
  codeErrorMuch: "Troppi errori, riprova più tardi", //错误次数过多,请稍后再试
  backLogin: "Torna al login", //返回登录
  mailFast:
    "Le e-mail ripetitive non sono consentite entro un breve periodo di tempo", //短时间内不允许重复发送邮件

  //主页面
  refresh: "Aggiorna", //刷新
  myDevice: "Il mio dispositivo", //我的设备
  personalInformation: "Informazione personale", //个人信息
  deviceManagement: "Gestione delle attrezzature", //设备管理
  VIPTraffic:"Traffico VIP",//VIP流量
  changePassword: "Cambia password", //修改密码
  changePasswordSuccess: "Cambiare la password con successo", //修改密码成功
  LogOut: "disconnessione", //退出登录
  shurLogOut: "Sei sicuro di voler uscire", //你确定要退出吗
  oldPassword: "Vecchia password", //旧密码
  newPassword: "Nuova password", //新密码
  inputNewPassword: "Inserisci di nuovo la nuova password", //再次输入新密码
  errorRefresh: "Aggiornamento non riuscito", //刷新失败
  online: "Online", //在线
  offline: "Offline", //离线
  UidLength:
    "Inserisci un numero di serie del dispositivo di 16 o 20 cifre composto da lettere maiuscole e minuscole e numeri", //请输入16或20位由大,小写字母,数字组成的设备序列号
    PwordLength: "Inserire una password del dispositivo con non più di 100 caratteri, composta da lettere maiuscole e minuscole e numeri",
    passwordrule: "Si prega di non inserire simboli speciali", //请勿输入特殊符号
  equipOffline:
    "Il dispositivo è temporaneamente offline, scegli un altro dispositivo", //设备暂时不在线请选择其它设备吧
  BrowserSupport:
    "Questo browser non supporta la riproduzione di plug-in, utilizza Google Chrome", //此浏览器不支持插件播放，请使用IE或360浏览器
  embedBrowserFail:
    "Impossibile associare il browser, non cambiare pagina rapidamente durante l'accesso", //绑定浏览器失败，请在登录时勿快速切换页面
  noPlugin: "Plugin non rilevato", //未检测到插件
  CancelAccount: "Annulla account",
  CancelAccountTitle: "Sei sicuro di voler cancellare il tuo account?",
  CancelSuccess: "Cancellare l'account con successo",

  // 设备列表、
  order: "SN", //SN
  equipmentPwd: "Password del dispositivo", //设备密码
  equipmentOrder: "Numero di serie", //序列号
  equipmentName: "Nome dell'attrezzatura", //设备名称
  equipmentUser: "Nome utente dispositivo", //设备用户名
  option: "Operare", //操作
  addEquipment: "Aggiungi dispositivo", //添加设备
  changeEquipmentMessage: "Modifica informazioni", //修改设备信息
  changePasswordMessage:"Modifica le informazioni della password",//修改密码信息
  deleteEquipment:"Elimina dispositivo",//删除设备
  delmessage: "Sei sicuro di voler eliminare", //你确定要删除吗
  all: "In totale", //共
  tips: "dati", //条数据
  add: "Aggiungi a", //添加
  equipmentSuccess: "Informazioni sul dispositivo modificate con successo", //设备信息修改成功
  addUid:
    "Inserisci il numero di serie del dispositivo che desideri aggiungere", //请输入你要添加的设备序列号
  addid: "Si prega di inserire il numero di serie che si desidera aggiungere", //请输入你要添加的序号
  addname:
    "Si prega di inserire il nome del dispositivo che si desidera aggiungere", //请输入你要添加的设备名
  addUsername:
    "Inserisci il nome utente del dispositivo che desideri aggiungere", //请输入你要添加的设备用户名

  // 用户信息
  userinfo: "Info Utenti", //用户信息
  userName: "Nome utente", //用户名
  usercontact: "Numero di telefono", //联系方式
  useremail: "E-mail", //电子邮箱

  // 全局提示
  successLogin: "Accedi suceesfully", //登录成功
  errorLogin1: "Accesso non riuscito, nome utente o password errati", //登录失败，用户名或密码错误
  errorLogin2: "Accesso non riuscito, errore del codice di verifica", //登录失败，验证码错误
  errorLogin3: "Accesso non riuscito, codice di verifica scaduto", //登录失败，验证码过期
  errorLogin4: "Troppi errori di accesso, account bloccato per 10 minuti", //登录失败次数过多，账户锁定10分钟
  delsucess: "Cancellato con successo", //删除成功
  addSuccess: "Aggiunto con successo", //添加成功
  addFail: "Aggiunta non riuscita", //添加失败
  timeOut: "Timeout di accesso, passa automaticamente alla pagina di accesso", //登录超时，自动跳转登录页
  changeEquipmentPwdError:
    "La password originale del dispositivo è stata inserita in modo errato", //原设备密码输入错误

  // 网络提示
  accessnot: "Accesso negato", //访问被拒绝
  resourcenot: "Risorsa non disponibile", //资源不可用
  nopage: "Pagina non trovata", //找不到页面
  methoderror: "Metodi non consentiti", //不允许此方法
  serveerror: "Errore del server", //服务器错误
  unknownerror: "Errore sconosciuto", //未知错误

  // 表单提示
  emailTip: "**@**.***",
  phoneTip:"Inserisci il numero di cellulare corretto",
  confirmTip: "Si prega di mantenerlo coerente con la password", //请与密码保持一致
  equipmentIdTip:
    "Inserisci il numero di serie del dispositivo a 16 o 20 cifre", //请输入16或20位设备序列号
  equipmentUserTip: "Inserisci il nome utente del dispositivo", //请输入设备用户名
  equipmentPasswordTip1: "Si prega di inserire la password del dispositivo", //请输入设备密码
  equipmentPasswordTip:
    "Si prega di inserire la password del dispositivo, non è necessario inserire la password se è vuota", //请输入设备密码，没有则无需输入
  codeTip: "Si prega di inserire il codice di verifica dell'e-mail ottenuto", //请输入获取的邮箱验证码
  equipmentPwdTip:
    "La password del dispositivo è vuota per impostazione predefinita", //设备密码默认为空
  equipmentNameTip: "Si prega di inserire il nome del dispositivo", //请输入设备名称
  equipmentUserNameTip: "Il nome utente del dispositivo predefinito è “admin”", //设备用户名默认为“admin”
  pagetip1:
    "Fare clic sul dispositivo in “Il mio dispositivo” a sinistra per visualizzare in anteprima, riprodurre, configurare e altre operazioni sul dispositivo", //单击左边“我的设备”中的设备，对设备进行预览，回放，配置等操作
  pagetip2:
    "Se questa è la tua prima visita, scarica il plug-in. Potrebbero volerci diversi minuti. Attendi pazientemente", //如果您是第一次访问，会自动下载插件，可能需要花费几分钟时间，请耐心等待
  passwordChangeTip: "Se non cambi la password, lasciala in bianco", //若不修改密码请保持为空
  loginUseNameTip:
    "Può utilizzare nome utente, e-mail, telefono cellulare per accedere", //可使用用户名、邮箱号、手机号登录

  placeholderUser: "Nome utente/e-mail/numero di telefono", //用户名/邮箱号/手机号
  placeholderPassword: "Password", //密码
  placeholderCode: "Codice di verifica", //验证码
  placeholderEquipId: "Numero di serie", //设备序列号
  placeholderEquipAdmin: "Nome utente dispositivo", //设备用户名
  placeholderEquipPassword: "Password del dispositivo", //设备密码
  placeholderRegistUser: "Nome utente", //用户名
  placeholderRegistMail: "Email", //邮箱
  placeholderRegistPhone: "telefono cellulare", //手机
  placeholderRegistCode: "Codice di verifica", //验证码
  placeholderRegistPwd: "Parola d'ordine", //密码
  placeholderRegistPwd2: "Conferma password", //确认密码

  supportTitle: "Contatto per l'assistenza tecnica", //技术支持联系方式
  supportPhone: "Hotline di servizio", //服务热线
  supportMail: "Cassetta postale di servizio", //服务邮箱
  supportTime:
    "Orari di servizio: dal lunedì al sabato 8:30-17:30 (esclusi i giorni festivi)", //服务时间：周一到周六8:30--17:30（法定节假日除外）
  userPwdRule: "Il formato della password è sbagliato", //密码格式错误
  userNameRule: "Formato del nome utente errato", //用户名格式错误
  Ok: "Conferma", //确认
  cancel: "Annulla", //取消
  backOld: "Vecchia versione", //回到旧版

  cookie:
    "Il nostro sito Web utilizza i cookie per migliorare il nostro sito Web e migliorare l'esperienza dell'utente. Se continui a navigare su questo sito senza modificare le impostazioni dei cookie del browser, accetti la nostra politica di utilizzo dei cookie.", //我们的网站使用cookies以改进我们的网站和改善用户体验。如您继续浏览本网站而未更改浏览器cookie设置，视为同意我们的cookies使用策略。
  cookieLink: "polizza", //政策
  agree: "Concordare", //同意
  notAgree: "Dissentire", //不同意

  cookiePolicy: "Politica sui cookie", //Cookie 政策
  cookieUse: "Come utilizziamo i cookie e tecnologie simili", //我们如何使用Cookie和同类技术
  cookieWhy:
    "Per garantire il normale funzionamento del sito Web, memorizzeremo piccoli file di dati chiamati Cookie sul tuo computer o dispositivo mobile. I cookie di solito contengono identificatori, nomi di siti e alcuni numeri e caratteri. Con l'aiuto dei cookie, i siti Web possono memorizzare dati come le tue preferenze.", //为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据档。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。
  cookieFor:
    "Non utilizzeremo i cookie per scopi diversi da quelli indicati in questa politica. Puoi gestire o eliminare i cookie in base alle tue preferenze. Per i dettagli, vedere AboutCookies.org. Puoi cancellare tutti i cookie salvati sul tuo computer e la maggior parte dei browser Web ha una funzione per bloccare i cookie. Ma se lo fai, devi modificare personalmente le impostazioni dell'utente ogni volta che visiti il ​​nostro sito web. Per saperne di più su come modificare le impostazioni del browser, visita la relativa pagina delle impostazioni del browser che stai utilizzando. Tuttavia, tieni presente che se non accetti i cookie, potresti non essere in grado di provare pienamente alcune funzionalità del sito web.", //我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。但请注意，如果您不接受cookie，可能无法全面体验网站的某些功能。
  cookieList:
    "Di seguito è riportato un elenco dei tipi di cookie utilizzati dal sito web:", //以下是网站使用的cookie种类列表：
  cookiePurpose: "Spiegare", //目的
  cookieDescribe: "Descrivere", //描述
  cookieType: "Tipo e data di scadenza", //种类和失效时间
  cookiePerformance: "Prestazioni (browser utente)", //性能（用户浏览器）
  cookieLogin:
    "Registra lo stato di accesso dell'utente e non è necessario eseguire nuovamente l'accesso entro 7 giorni", //记录用户登录状态，以及7天内不用重新登录
  cookieDay: "7 giorni", //7天
  cookieCnzz: "CNZZ", //CNZZ
  cookieTime: "Registra il numero di volte che l'utente visita il sito web", //记录用户访问网站的次数
  cookieForever: "permanente", //永久
  cookieTrack:
    "Molti browser Web dispongono di una funzione Do Not Track, che può inviare richieste Do NotTrack ai siti Web. Attualmente, le principali organizzazioni di standard Internet non hanno stabilito politiche pertinenti per regolamentare il modo in cui i siti Web dovrebbero rispondere a tali richieste. Ma se il tuo browser abilita il Do Not Track, il nostro sito web rispetterà la tua scelta.", //很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do NotTrack请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站都会尊重您的选择。
  backHome: "torna alla homepage", //回到首页
    // RS
    RS2000: 'Esecuzione riuscita',//执行成功
    RS4000: "Nome utente o password errati",//用户名或密码错误
    RS4010: 'Errore del codice di verifica',//验证码错误
    RS4011: 'La password non è coerente',//密码不一致
    RS4012: "Nome utente registrato",//用户名已被注册
    RS4013: "Il nome utente è vuoto",//用户名为空
    RS4014: 'La password è vuota',//密码为空
    RS4015: 'Conferma che la password è vuota',//确认密码为空
    RS4016: 'Il numero di cellulare è vuoto',//手机号为空
    RS4017: "Formato del nome utente errato",//用户名格式不正确
    RS4018: 'Formato password errato',//密码格式不正确
    RS4019: 'Confermare che il formato della password non è corretto',//确认密码格式不正确
    RS4020: 'Il formato del numero di cellulare non è corretto',//手机号格式不正确
    RS4021: 'Numero di cellulare registrato',//手机号已注册
    RS4022: 'Il numero di cellulare non è registrato',//手机号未注册
    RS4023: 'Email registrata',//邮箱已注册
    RS4024: 'La casella di posta non è registrata',//邮箱未注册
    RS4025: "Il nome utente non è registrato",//用户名未注册
    RS4026: 'La password originale non è corretta',//原密码错误
    RS4027: 'Modifica password non riuscita',//修改密码失败
    RS4029: "ID utente vuoto",//用户ID为空
    RS4030: 'Il codice di verifica è vuoto',//验证码为空
    RS4031: 'La cassetta postale è vuota',//邮箱为空
    RS4032: 'Formato della cassetta postale errato',//邮箱格式不正确
    RS4033: 'Formato della cassetta postale errato',//邮箱格式不正确
    RS4034: "WeChat non è vincolato",//微信没有绑定
    RS4035: 'Legare WeChat non riuscito',//绑定微信失败
    RS4036: 'Legare il numero di telefono cellulare non riuscito',//绑定手机号失败
    RS4037: 'Legare la casella postale non riuscita',//绑定邮箱失败
    RS4038: 'Invia codice di verifica più del numero massimo di volte',//发送验证码超过最大次数
    RS4039: 'login non riuscito',//注册失败
    RS4040: "WeChat ha legato gli utenti",//微信已绑定用户
    RS4041: "Nessuna autorizzazione per modificare il nome utente (solo per gli utenti anonimi generati)",//没有权限修改用户名（仅针对生成的匿名用户修改）
    RS4042: "USER_ NOT_ FB_ L'utente BIND non è vincolato a Facebook",//USER_NOT_FB_BIND 用户没有绑定facebook
    RS4043: "USER_ FAIL_ FB_ Utente BIND non riuscito a legare a Facebook",//USER_FAIL_FB_BIND 用户绑定facebook失败
    RS4044: "USER_ NOT_ GG_ Gli utenti BIND non hanno Google binding",//USER_NOT_GG_BIND 用户没有google绑定
    RS4045: "USER_ FAIL_ GG_ Utente BIND non riuscito a legare a Google",//USER_FAIL_GG_BIND 用户绑定google失败
    RS4046: "Il conto linea non è vincolato",//Line账户未绑定
    RS4047: 'Legatura del conto riga non riuscita',//Line账户绑定失败
    RS4100: "Il dispositivo non può essere aggiunto illegalmente",//设备非法不允许添加
    RS4101: "Dispositivo già esistente",//设备已存在
    RS4102: 'Eliminazione dispositivo non riuscita',//删除设备失败
    RS4103: 'Modifica non riuscita',//修改失败
    RS4104: 'Eccezione del parametro uuid del dispositivo',//设备uuid参数异常
    RS4105: 'Eccezione del parametro del nome utente del dispositivo',//设备用户名参数异常
    RS4106: "Il parametro della password del dispositivo è anormale",//设备密码参数异常
    RS4116: "Consegna dell'account master del dispositivo a un altro utente non riuscita. Verificare se l'utente dispone del dispositivo e se l'utente dispone dell'autorità dell'account master del dispositivo",//将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限
    RS4117: 'Non un account master',//不是主账户
    RS4118: "L'utente non ha questo dispositivo",//用户没有此设备
    RS4119: "Il dispositivo aggiunto non è univoco. Altri account sono stati aggiunti",//添加设备不唯一，其它账户已添加
    RS4120: "Limite massimo di aggiunta di dispositivi (100)",//添加设备最大限制(100个)
    RS4126: 'Il token di supporto del dispositivo può essere aggiunto solo da un account',//设备支持令牌只有一个账户添加
    RS4127: "token dispositivo mancante",//缺少设备令牌
    RS4300: "invio non riuscito",//发送失败
    RS4302: 'Codice di verifica della cancellazione inviato con successo',//发送注销验证码成功
    RS4400: "Verifica dell'interfaccia SMS non riuscita, vi preghiamo di contattarci.",//短信接口验证失败，请联系我们
    RS4401: "Errore del parametro dell'interfaccia SMS, vi preghiamo di contattarci",//短信接口参数错误，请联系我们
    RS4402: 'Ogni numero di cellulare può inviare SMS solo tre volte al giorno',//每个手机号一天只能发送三次短信
    RS4403: "Invio non riuscito. Riprova più tardi",//发送失败，请稍后再试
    RS4404: "L'invio è troppo frequente, attendere 120 secondi",//发送太频繁了，请间隔120秒
    RS4405: "invio non riuscito",//发送失败
    RS5000: "Errore del server",//服务器故障
    RS5001: "Certificato non esiste",//证书不存在
    RS5002: "Errore di informazione dell'intestazione richiesta",//请求头信息错误
    RS5003: "Scadenza del certificato",//证书失效
    RS5004: "Errore di verifica della chiave di generazione",//生成密钥校验错误
    RS5005: 'Eccezione dei parametri',//参数异常
    RS5009: 'Errore di codice WeChat, crittografia AES e errore di decrittografia',//微信code错误、AES加解密错误
    RS5010: 'Token scaduto',//token 已过期
    RS5011: 'Errore token',//token 错误
    RS5012: 'Token non ha il permesso',//token 无权限
    RS5013: "Non lo sosterrò",//不支持
    RS5014: "L'operazione è troppo frequente",//操作太频繁
    RS4050: "L'operazione è troppo frequente",//操作太频繁
    phoneCodeTip: "Inserisci il codice di verifica del telefono cellulare ottenuto",//======请输入获取的手机验证码
    phoneNotFind: "Il numero di cellulare che hai inserito non esiste, controlla",//=====您输入的手机号码不存在，请检查
    phoneFormatError: "Errore di formato del numero mobile, controllare",//======手机号码格式错误，请检查
    phoneSendSuccess: "Verificare se il codice di verifica è stato inviato correttamente",//=======验证码发送成功请注意查收
    hadPhone: "Il tuo numero di cellulare registrato esiste già", //======您的注册手机号码已存在
    phoneRule: "Inserisci il tuo numero di cellulare",//======请输入手机号码

    useNameTip:"4-15 caratteri, tra cui cinese, lettere, numeri_ Composto da due o più voci", //4-15位字符，可由中文、数字、_和-组成
    passwordTip:"Lunghezza 8-32 caratteri, che può essere composta da lettere inglesi, numeri, sottolineature e - li o più", //8-32位字符长度，由英文字母、数字、下划线和-组成
    NameLength: "Inserire un nome del dispositivo con non più di 255 caratteri composto da lettere maiuscole e minuscole, numeri e cinese",
    UnameLength: "Inserire un nome utente del dispositivo con non più di 255 caratteri composto da lettere maiuscole e minuscole, numeri e cinese",
    placeTip:"Inserire il numero di serie",
    deleteAll:"Eliminazione batch",

    // nettimeout:"网络超时",
  accessTokenfail:"Timeout del servizio, token di accesso non riuscito",
  userinfo2faile:"Timeout del servizio, acquisizione delle informazioni utente non riuscita",
  getcodefail:"Timeout del servizio, ottenimento del codice di verifica non riuscito",
  getmailfail:"Timeout del servizio, modifica non riuscita",
  getdevicefail:"Timeout del servizio, impossibile ottenere l'elenco dei dispositivi",
  getareacodefail:"Timeout del servizio, impossibile ottenere l'elenco dei prefisso",
  rulenamefail:"Timeout del servizio, verifica del nome utente non riuscita",
  rulephonefail:"Timeout del servizio, verifica del numero di telefono cellulare dell'utente non riuscita",
  ruleregisterfail:"Timeout del servizio, registrazione non riuscita",
  ruleemailfail:"Timeout del servizio, verifica della casella postale non riuscita",
  getdevicestatusfail:"Timeout del servizio, impossibile ottenere lo stato del dispositivo",

  oldequipmentpassword:"Password originale",
  oldpasswordrule:"Inserisci la password originale del dispositivo. In caso contrario, non è necessario inserirla",
  newequipmentpassword:"Nuova password",
  newpasswordrule:"Inserisci una nuova password per il dispositivo. In caso contrario, non è necessario inserirla",
  confirmequippassword:"Conferma password",
  confirmeqpasswordrule:"Si prega di mantenere coerente con la nuova password",
  };
