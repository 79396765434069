import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const mutations = {
  SaveUserInfo: (state, userInfo) => {
    state.userInfo = userInfo;
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
  },
  videoInfo: (state, videoInfo) => {
    state.videoInfo = videoInfo;
  },
  ChangeLan: (state, changeLan) => {
    state.changeLan = changeLan;
  },
  ChangeAccesstoken:(state,accessToken) =>{
    state.accessToken = accessToken;
    sessionStorage.setItem("accessToken", JSON.stringify(accessToken));
  }
};
const state = {
  userInfo: "" || JSON.parse(sessionStorage.getItem("userInfo")),
  videoInfo: "",
  changeLan: "",
  accessToken:""|| JSON.parse(sessionStorage.getItem("accessToken")),
};
const getters = {
  userInfo: state => state.userInfo,
  videoInfo: state => state.videoInfo,
  changeLan: state => state.changeLan,
  accessToken:state=>state.accessToken
};
const store = new Vuex.Store({
  mutations,
  state,
  getters
});
export default store;
