module.exports = {
  //2022-6 新增
  cloudinfo: {
    normal: "Normaler Gebrauch", //正常使用
    timeOut: "ist abgelaufen", //已过期
    nonactivated: "nicht aktiviert",
    notSupport: "nicht unterstützt",
    noRemainingFlow: "kein Verkehr übrig",
    VipStatus: "VIP-Status",
    totalFlow: "Gesamtfluss",
    residualFlow: "Restfluss",
    expireDate: "Ablaufdatum"
  },
  //新增
  cloud: "Cloud-Speicher",
  cloudTimeOut: "Cloud-Speicher (abgelaufen)",
  cloudNormal: "Cloud-Speicher (im Einsatz)",
  cloudNonActivated: "Cloud-Speicher (nicht geöffnet)",
  cloudnotSupported:"Cloud-Speicher (nicht verfügbar)",
  vipTimeOut: "4G-Verkehr (abgelaufen)",
  vipNormal: "4G-Verkehr (in Betrieb)",
  vipNonActivated: "4G-Verkehr (nicht geöffnet)",
  vipnotSupported:"4G-Verkehr (nicht verfügbar)",
  // 德语
  // 登录页
  userLogin: "Benutzer-Anmeldung", //用户登录
  equipLogin: "Gerät Login", //设备登录
  login: "Einloggen", //登录
  regist: "Jetzt anmelden", //立即注册
  findPassword: "Passwort abrufen", //找回密码
  userRule: "Bitte geben Sie den Benutzernamen", //请输入用户名
  passwordRule: "Bitte geben Sie das Passwort ein", //请输入密码
  codeRule: "Bitte eingeben Bestätigungscode", //请输入验证码
  equipIdRule: "Bitte geben Sie die Seriennummer des Geräts ein", //请输入设备序列号
  appDownload: "App-Download", //App 下载
  webUnitDownload: "Download der Webkontrolle", //Web控件下载
  callUs: "kontaktiere uns", //联系我们
  officialEdition: "Classic Version", //经典版
  proEdition: "PRO Version", //PRO版
  codeLenRule: "Bitte geben Sie den 4-stelligen Sicherheitscode", //请输入4位验证码
  notEmpty: "Bitte keine Leerzeichen eingeben", //请不要输入空格

  // 注册
  userRegist: "Benutzerkonto erstellen", //创建账户
  emailRule: "Bitte geben Sie die Mailbox-Nummer", //请输入邮箱
  emailtrue: "Bitte geben Sie die Mailbox-Nummer im richtigen Format", //请输入正确格式的邮箱号
  phonetrue:"Bitte geben Sie eine Handynummer im richtigen Format ein",//请输入正确的手机号码
  confirmPasswordRule: "Kennwort bestätigen", //请再次输入密码
  hadCount: "Habe schon einen Account", //已有账号，立即登录
  registCount: "Registrieren", //注册
  hadUser: "Ihr registrierter Benutzername existiert bereits", //您注册的用户名已存在
  hadMail: "Ihre registrierte E-Mail existiert bereits", //您注册的邮箱已存在
  sendSuccess: "Mail erfolgreich gesendet", //邮件发送成功
  mailFormatError: "E-Mail-Format Fehler, überprüfen Sie bitte,", //邮箱格式错误，请检查
  codeError: "Fehler im Bestätigungscode", //验证码错误
  registerSuccess: "Registrierung erfolgreich", //注册成功
  mailNotFind:
    "Die von Ihnen eingegebene E-Mail-Adresse existiert nicht, bitte überprüfen Sie", //您输入的邮箱不存在，请检查
  userNameLen: "Bitte geben Sie einen Benutzernamen mit 4 bis 8 Zeichen ein", //请输入4~8位用户名
  userPwdLen: "Bitte geben Sie ein 8~32-stelliges Passwort ein", //请输入8~32位密码
  privacyPolicy:"Lesen und wählen Sie die Datenschutzerklärung",//阅读并选择隐私政策
  
  emailRegistration:"E-Mail Registrierung",//邮箱注册
  mobileRegistration:"Mobile Registrierung",//手机注册
  // 找回密码
  phoneRetrievePassword:"Handy zum Abrufen des Passworts",//手机找回密码
  mailboxRetrievalPassword:"Abruf des Postfachpassworts",//邮箱找回密码
  findThePassword: "Passwort abrufen", //找回密码
  passwordText:
    "Bitte geben Sie die E-Mail-Adresse ein, die bei der Registrierung des Kontos verwendet wurde, um das Passwort abzurufen", //请输入注册账号时使用的邮箱来找回密码
  exit: "Ausloggen", //退出
  nextStep: "Nächster Schritt", //下一步
  reset: "Zurücksetzen", //重新设置
  getCode: "senden", //发送
  someTime: "reacquire", //重新获取
  ok: "bestätigen Sie", //确认
  mailSendMuch: "Post kann nur dreimal täglich pro Postfach versendet werden", //邮件每个邮箱一天只能发送三次
  mailChangeSuccess: "modifizierte erfolgreich", //修改成功
  pwdError: "Falsches Passwortformat", //密码格式不正确
  codeTimeout: "Bestätigungscode abgelaufen", //验证码过期
  codeErrorMuch: "Zu viele Fehler, bitte versuchen Sie es später noch einmal", //错误次数过多,请稍后再试
  backLogin: "Zurück zum Login", //返回登录
  mailFast: "Wiederholte E-Mails innerhalb kurzer Zeit sind nicht erlaubt", //短时间内不允许重复发送邮件

  //主页面
  refresh: "Aktualisierung", //刷新
  myDevice: "Mein Gerät", //我的设备
  personalInformation: "Persönliche Angaben", //个人信息
  deviceManagement: "Geräteverwaltung", //设备管理
  VIPTraffic:"VIP-Verkehr",//VIP流量
  changePassword: "ändere das Passwort", //修改密码
  changePasswordSuccess: "Passwort erfolgreich ändern", //修改密码成功
  LogOut: "Ausloggen", //退出登录
  shurLogOut: "Möchten Sie sich wirklich abmelden", //你确定要退出吗
  oldPassword: "Altes Passwort", //旧密码
  newPassword: "Neues Kennwort", //新密码
  inputNewPassword: "Kennwort bestätigen", //再次输入新密码
  errorRefresh: "Aktualisierung fehlgeschlagen", //刷新失败
  online: "Online", //在线
  offline: "Offline", //离线
  UidLength:
    "Bitte geben Sie eine 16- oder 20-stellige Geräteseriennummer bestehend aus Groß- und Kleinbuchstaben sowie Zahlen ein", //请输入16或20位由大,小写字母,数字组成的设备序列号
  //  NameLength : "Bitte geben Sie einen Gerätenamen mit maximal 255 Zeichen ein, bestehend aus Groß- und Kleinbuchstaben und Zahlen",
    // UnameLength: "Bitte geben Sie einen Gerätenamen mit maximal 255 Zeichen ein, bestehend aus Groß- und Kleinbuchstaben und Zahlen",
    PwordLength: "Bitte geben Sie ein Gerätepasswort mit maximal 100-Zeichen ein, bestehend aus Groß- und Kleinbuchstaben und Zahlen",
    passwordrule: "Bitte keine Sonderzeichen eingeben", //请勿输入特殊符号
  equipOffline:
    "Das Gerät ist vorübergehend offline, bitte wählen Sie ein anderes Gerät", //设备暂时不在线请选择其它设备吧
  BrowserSupport:
    "Dieser Browser unterstützt keine Plug-in-Wiedergabe, bitte verwenden Sie Google Chrome", //此浏览器不支持插件播放，请使用IE或360浏览器
  embedBrowserFail:
    "Fehler beim Binden des Browsers, bitte wechseln Sie beim Anmelden nicht schnell die Seite", //绑定浏览器失败，请在登录时勿快速切换页面
  noPlugin: "Plugin nicht erkannt", //未检测到插件
  CancelAccount: "Account löschen",
  CancelAccountTitle: "Möchten Sie das Konto wirklich löschen?",
  CancelSuccess: "Konto erfolgreich stornieren",

  // 设备列表、
  order: "", //序号
  equipmentPwd: "Gerätepasswort", //设备密码
  equipmentOrder: "Seriennummer", //序列号
  equipmentName: "Geräte Name", //名称
  equipmentUser: "Nutzername", //设备用户名
  option: "Betreiben", //操作
  addEquipment: "Gerät hinzufügen", //添加设备
  changeEquipmentMessage: "Geräteinformationen ändern", //修改设备信息
  changePasswordMessage:"Kennwortinformationen ändern",//修改密码信息
  deleteEquipment:"Gerät löschen",//删除设备
  delmessage: "Möchten Sie wirklich löschen", //你确定要删除吗
  all: "In Summe", //共
  tips: "Daten", //条数据
  add: "Hinzufügen", //添加
  equipmentSuccess: "Geräteinformationen modifiziert erfolgreich", //设备信息修改成功
  addUid:
    "Bitte geben Sie die Seriennummer des Geräts ein, das Sie hinzufügen möchten", //请输入你要添加的设备序列号
  addid: "Bitte geben Sie die Nummer ein, die Sie hinzufügen möchten", //请输入你要添加的序号
  addname:
    "Bitte geben Sie den Namen des Geräts ein, das Sie hinzufügen möchten", //请输入你要添加的设备名
  addUsername:
    "Bitte geben Sie den Benutzernamen des Geräts ein, das Sie hinzufügen möchten", //请输入你要添加的设备用户名

  // 用户信息
  userinfo: "Benutzerinformation", //用户信息
  userName: "Nutzername", //用户名
  usercontact: "Telefonnummer", //联系方式
  useremail: "Email", //电子邮箱

  // 全局提示
  successLogin: "erfolgreich anmelden", //登录成功
  errorLogin1:
    "Anmeldung fehlgeschlagen, Benutzername oder Passwort ist falsch", //登录失败，用户名或密码错误
  errorLogin2: "Anmeldung fehlgeschlagen, Verifizierungscodefehler", //登录失败，验证码错误
  errorLogin3: "Anmeldung fehlgeschlagen, abgelaufen Bestätigungscode", //登录失败，验证码过期
  errorLogin4: "Zu viele Login Ausfälle, Konto für 10 Minuten gesperrt", //登录失败次数过多，账户锁定10分钟
  delsucess: "Erfolgreich gelöscht", //删除成功
  addSuccess: "Erfolgreich hinzugefügt", //添加成功
  addFail: "Hinzufügen fehlgeschlagen", //添加失败
  timeOut: "Login-Timeout, automatisch zur Login-Seite springen", //登录超时，自动跳转登录页
  changeEquipmentPwdError:
    "Das ursprüngliche Gerätepasswort wurde falsch eingegeben", //原设备密码输入错误

  // 网络提示
  accessnot: "Zugriff abgelehnt", //访问被拒绝
  resourcenot: "Ressource nicht erhältlich", //资源不可用
  nopage: "Seite nicht gefunden", //找不到页面
  methoderror: "Methode nicht erlaubt", //不允许此方法
  serveerror: "Server-Fehler", //服务器错误
  unknownerror: "unbekannter Fehler", //未知错误

  // 表单提示

  emailTip: "**@**.***",
  phoneTip:"Bitte geben Sie die richtige Handynummer ein",
  // passwordTip:
  //   "8~32 Zeichen lang, bestehend aus englischen Buchstaben, Zahlen, _ und-", //8-32位字符长度，由英文字母、数字、_和-组成
  confirmTip: "Bitte das gleiche wie das Passwort sein", //请与密码相同
  equipmentIdTip:
    "Bitte geben Sie die 16- oder 20-stellige Geräteseriennummer ein", //请输入16或20位设备序列号
  equipmentUserTip: "Bitte geben Sie den Gerätebenutzernamen ein", //请输入设备用户名
  equipmentPasswordTip1: "Bitte geben Sie das Gerätepasswort ein", //请输入设备密码
  equipmentPasswordTip:
    "Bitte geben Sie das Gerätepasswort ein, wenn nicht, ist keine Eingabe erforderlich", //请输入设备密码，没有则无需输入
  codeTip: "Bitte geben Sie die erhaltene ein E-Mail-Bestätigungscode", //请输入获取的邮箱验证码
  equipmentPwdTip: "Das Gerätekennwort ist standardmäßig leer", //设备密码默认为空
  equipmentNameTip: "Bitte geben Sie den Gerätenamen ein", //请输入设备名称
  equipmentUserNameTip: "Die Gerätebenutzernamen standardmäßig auf „admin“", //设备用户名默认为“admin”
  pagetip1:
    "Klicken Sie auf das Gerät in ”Mein Gerät“ auf der linken Seite, um eine Vorschau, Wiedergabe, Konfiguration und andere Vorgänge auf dem Gerät anzuzeigen", //单击左边“我的设备”中的设备，对设备进行预览，回放，配置等操作
  pagetip2:
    "Wenn dies Ihr erster Besuch ist, laden Sie bitte das Plugin herunter. Es kann einige Minuten dauern. Bitte warten Sie geduldig", //如果您是第一次访问，会自动下载插件，可能需要花费几分钟时间，请耐心等待
  passwordChangeTip:
    "Wenn Sie das Passwort nicht ändern, lassen Sie es bitte leer", //若不修改密码请保持为空
  loginUseNameTip:
    "Sie können mit Ihrem Benutzernamen anmelden, E-Mail-Nummer und Handynummer", //可使用用户名、邮箱号、手机号登录

  placeholderUser: "Benutzername / E-Mail Nummer / Handynummer", //用户名/邮箱号/手机号
  placeholderPassword: "Passwort", //密码
  placeholderCode: "Bestätigungscode", //验证码
  placeholderEquipId: "Seriennummer des Geräts", //设备序列号
  placeholderEquipAdmin: "Gerätebenutzername", //设备用户名
  placeholderEquipPassword: "Gerätepasswort", //设备密码
  placeholderRegistUser: "Nutzername", //
  placeholderRegistMail: "Email", //邮箱
  placeholderRegistPhone: "Handy", //手机
  placeholderRegistCode: "Bestätigungscode", //验证码
  placeholderRegistPwd: "Passwort", //密码
  placeholderRegistPwd2: "Kennwort bestätigen", //确认密码
 

  supportTitle: "Kontakt zum technischen Support", //技术支持联系方式
  supportPhone: "Service-Hotline", //服务热线
  supportMail: "Service-Postfach", //服务邮箱
  supportTime:
    "Servicezeiten: Montag bis Samstag 8:30-17:30 (außer an gesetzlichen Feiertagen)", //服务时间：周一到周六8:30--17:30（法定节假日除外）
  userPwdRule: "Passwortformat ist falsch", //密码格式错误
  userNameRule: "Falsches Benutzernamenformat", //用户名格式错误
  Ok: "bestätigen Sie", //确认
  cancel: "Stornieren", //取消
  backOld: "Zurück zur alten Version", //回到旧版

  cookie:
    "Unsere Website verwendet Cookies, um unsere Website zu verbessern und die Benutzererfahrung zu verbessern. Wenn Sie weiterhin auf dieser Website surfen, ohne die Cookie-Einstellungen Ihres Browsers zu ändern, stimmen Sie unserer Cookie-Nutzungsrichtlinie zu.", //我们的网站使用cookies以改进我们的网站和改善用户体验。如您继续浏览本网站而未更改浏览器cookie设置，视为同意我们的cookies使用策略。
  cookieLink: "Regel", //政策
  agree: "Zustimmen", //zustimmen
  notAgree: "Nicht zustimmen", //不同意

  cookiePolicy: "Cookie-Richtlinie", //Cookie 政策
  cookieUse: "Wie wir Cookies und ähnliche Technologien verwenden", //我们如何使用Cookie和同类技术
  cookieWhy:
    "Um den normalen Betrieb der Website zu gewährleisten, speichern wir kleine Dateien, sogenannte Cookies, auf Ihrem Computer oder Mobilgerät. Cookies enthalten normalerweise Kennungen, Site-Namen und einige Zahlen und Zeichen. Mithilfe von Cookies können Websites Daten wie Ihre Präferenzen speichern.", //为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据档。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。
  cookieFor:
    "Wir werden Cookies nicht für andere Zwecke als die in dieser Richtlinie angegebenen verwenden. Sie können Cookies nach Ihren Wünschen verwalten oder löschen. Einzelheiten finden Sie unter AboutCookies.org. Sie können alle auf Ihrem Computer gespeicherten Cookies löschen und die meisten Webbrowser verfügen über eine Funktion zum Blockieren von Cookies. In diesem Fall müssen Sie jedoch bei jedem Besuch unserer Website die Benutzereinstellungen persönlich ändern. Um mehr darüber zu erfahren, wie Sie Browsereinstellungen ändern können, besuchen Sie bitte die entsprechende Einstellungsseite des von Ihnen verwendeten Browsers. Bitte beachten Sie jedoch, dass Sie einige Funktionen der Website möglicherweise nicht vollumfänglich nutzen können, wenn Sie keine Cookies akzeptieren.", //我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。但请注意，如果您不接受cookie，可能无法全面体验网站的某些功能。
  cookieList:
    "Im Folgenden finden Sie eine Liste der Arten von Cookies, die von der Website verwendet werden:", //以下是网站使用的cookie种类列表：
  cookiePurpose: "Zweck", //目的
  cookieDescribe: "beschreiben", //描述
  cookieType: "Typ und Ablaufzeit", //种类和失效时间
  cookiePerformance: "Leistung (Benutzerbrowser)", //性能（用户浏览器）
  cookieLogin:
    "Erfassen Sie den Anmeldestatus des Benutzers und müssen Sie sich innerhalb von 7 Tagen nicht erneut anmelden", //记录用户登录状态，以及7天内不用重新登录
  cookieDay: "7 Tage", //7天
  cookieCnzz: "CNZZ", //CNZZ
  cookieTime: "Notieren Sie, wie oft der Benutzer die Website besucht", //记录用户访问网站的次数
  cookieForever: "dauerhaft", //永久
  cookieTrack:
    "Viele Webbrowser verfügen über eine Do-Not-Track-Funktion, die Do-NotTrack-Anfragen an Websites senden kann. Derzeit haben die großen Internet-Standardisierungsorganisationen keine relevanten Richtlinien aufgestellt, um zu regeln, wie Websites auf solche Anfragen reagieren sollen. Wenn Ihr Browser jedoch Do Not Track aktiviert, wird unsere Website Ihre Wahl respektieren.", //很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do NotTrack请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站都会尊重您的选择。
  backHome: "Zurück zur Homepage", //回到首页
  // RS
  RS2000: 'Ausführung erfolgreich',//执行成功
  RS4000: 'Falscher Benutzername oder falsches Passwort',//用户名或密码错误
  RS4010: 'Fehler im Verifizierungscode',//验证码错误
  RS4011: 'Passwort ist inkonsistent',//密码不一致
  RS4012: 'Benutzername wurde registriert',//用户名已被注册
  RS4013: 'Benutzername ist leer',//用户名为空
  RS4014: 'Passwort ist leer',//密码为空
  RS4015: 'Bestätigen, dass das Passwort leer ist',//确认密码为空
  RS4016: 'Mobilfunknummer ist leer',//手机号为空
  RS4017: 'Falsches Format des Benutzernamens',//用户名格式不正确
  RS4018: 'Falsches Kennwortformat',//密码格式不正确
  RS4019: 'Bestätigen Sie, dass das Kennwortformat falsch ist',//确认密码格式不正确
  RS4020: 'Das Format der Mobiltelefonnummer ist falsch',//手机号格式不正确
  RS4021: 'Mobilfunknummer registriert',//手机号已注册
  RS4022: 'Mobilfunknummer ist nicht registriert',//手机号未注册
  RS4023: 'E-Mail registriert',//邮箱已注册
  RS4024: 'Das Postfach ist nicht registriert',//邮箱未注册
  RS4025: 'Benutzername ist nicht registriert',//用户名未注册
  RS4026: 'Das ursprüngliche Passwort ist falsch',//原密码错误
  RS4027: 'Kennwort ändern fehlgeschlagen',//修改密码失败
  RS4029: 'Benutzer-ID ist leer',//用户ID为空
  RS4030: 'Der Verifizierungscode ist leer',//验证码为空
  RS4031: 'Das Postfach ist leer',//邮箱为空
  RS4032: 'Falsches Postfachformat',//邮箱格式不正确
  RS4033: 'Falsches Postfachformat',//邮箱格式不正确
  RS4034: 'WeChat ist nicht gebunden',//微信没有绑定
  RS4035: 'Bindung von WeChat fehlgeschlagen',//绑定微信失败
  RS4036: 'Bindung der Mobiltelefonnummer fehlgeschlagen',//绑定手机号失败
  RS4037: 'Binden des Postfachs fehlgeschlagen',//绑定邮箱失败
  RS4038: 'Bestätigungscode mehr als die maximale Anzahl von Fällen senden',//发送验证码超过最大次数
  RS4039: 'Anmeldung fehlgeschlagen',//注册失败
  RS4040: 'WeChat hat Benutzer gebunden',//微信已绑定用户
  RS4041: 'Keine Berechtigung zum Ändern des Benutzernamens (nur für generierte anonyme Benutzer)',//没有权限修改用户名（仅针对生成的匿名用户修改）
  RS4042: 'NUTZER_ NOT_ FB_ Der BIND-Nutzer ist nicht an Facebook gebunden',//USER_NOT_FB_BIND 用户没有绑定facebook
  RS4043: 'NUTZER_ FAIL_ FB_ BIND-Benutzer konnte sich nicht an Facebook binden',//USER_FAIL_FB_BIND 用户绑定facebook失败
  RS4044: 'NUTZER_ NOT_ GG_ BIND-Nutzer haben keine Google-Bindung',//USER_NOT_GG_BIND 用户没有google绑定
  RS4045: 'NUTZER_ FAIL_ GG_ BIND-Benutzer konnte sich nicht an Google binden',//USER_FAIL_GG_BIND 用户绑定google失败
  RS4046: 'Linienkonto ist nicht gebunden',//Line账户未绑定
  RS4047: 'Bindung von Zeilenkonten fehlgeschlagen',//Line账户绑定失败
  RS4100: 'Das Gerät darf nicht illegal hinzugefügt werden',//设备非法不允许添加
  RS4101: 'Gerät existiert bereits',//设备已存在
  RS4102: 'Gerät konnte nicht gelöscht werden',//删除设备失败
  RS4103: 'Änderung fehlgeschlagen',//修改失败
  RS4104: 'Ausnahme von Geräteuuid-Parametern',//设备uuid参数异常
  RS4105: 'Ausnahme für Gerätebenutzernamen',//设备用户名参数异常
  RS4106: 'Gerätekennwort-Parameter ist anormal',//设备密码参数异常
  RS4116: 'Übergabe des Gerätestammkontos an einen anderen Benutzer fehlgeschlagen Überprüfen Sie, ob der Benutzer über das Gerät verfügt und der Benutzer über die Berechtigung des Gerätestammkontos verfügt',//将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限
  RS4117: 'Kein Hauptkonto',//不是主账户
  RS4118: 'Der Benutzer hat dieses Gerät nicht',//用户没有此设备
  RS4119: 'Das hinzugefügte Gerät ist nicht eindeutig. Andere Konten wurden hinzugefügt',//添加设备不唯一，其它账户已添加
  RS4120: 'Höchstgrenze für Zusatzeinrichtungen (100)',//添加设备最大限制(100个)
  RS4126: 'Das Gerätesupport-Token kann nur von einem Konto hinzugefügt werden',//设备支持令牌只有一个账户添加
  RS4127: '	Fehlendes Gerätetoken',//缺少设备令牌
  RS4300: 'Senden fehlschlägt',//发送失败
  RS4302: 'Bestätigungscode für die Stornierung erfolgreich gesendet',//发送注销验证码成功
  RS4400: 'SMS Interface Verifizierung fehlgeschlagen, kontaktieren Sie uns bitte.',//短信接口验证失败，请联系我们
  RS4401: 'SMS Interface Parameter Fehler, bitte kontaktieren Sie uns',//短信接口参数错误，请联系我们
  RS4402: 'Jede Mobiltelefonnummer kann nur dreimal am Tag SMS senden',//每个手机号一天只能发送三次短信
  RS4403: 'Senden fehlgeschlagen. Bitte versuchen Sie es später erneut',//发送失败，请稍后再试
  RS4404: 'Senden ist zu häufig, bitte warten Sie 120 Sekunden',//发送太频繁了，请间隔120秒
  RS4405: 'Senden fehlschlägt',//发送失败
  RS5000: 'Serverfehler',//服务器故障
  RS5001: 'Zertifikat existiert nicht',//证书不存在
  RS5002: 'Fehler in der Kopfzeile anfordern',//请求头信息错误
  RS5003: 'Ablauf des Zertifikats',//证书失效
  RS5004: 'Fehler bei der Überprüfung des Generierungsschlüssels',//生成密钥校验错误
  RS5005: 'Parameterausnahme',//参数异常
  RS5009: 'WeChat-Codefehler, AES-Verschlüsselungs- und Entschlüsselungsfehler',//微信code错误、AES加解密错误
  RS5010: 'Token abgelaufen',//token 已过期
  RS5011: 'Token-Fehler',//token 错误
  RS5012: 'Token hat keine Erlaubnis.',//token 无权限
  RS5013: 'Ich werde es nicht unterstützen',//不支持
  RS5014: 'Der Betrieb ist zu häufig',//操作太频繁
  RS4050: 'Der Betrieb ist zu häufig',//操作太频繁
  phoneCodeTip: "Bitte geben Sie den erhaltenen Handyverifizierungscode ein",//======
  phoneNotFind: "Die von Ihnen eingegebene Handynummer existiert nicht, überprüfen Sie bitte",//=====
  phoneFormatError: "Fehler im Format der Mobiltelefonnummer, bitte überprüfen",//======
  phoneSendSuccess: "Bitte überprüfen Sie, ob der Bestätigungscode erfolgreich gesendet wurde",//=======
  hadPhone: "Ihre registrierte Handynummer existiert bereits", //======
  phoneRule: "Bitte geben Sie Ihre Handynummer ein",//======

  useNameTip:
  "4-15 Zeichen, einschließlich Chinesisch, Buchstaben, Zahlen_ Bestehend aus zwei oder mehr Artikeln", //4-15位字符，可由中文、数字、_和-组成
  passwordTip:
  "8-32 Zeichenlänge, die aus englischen Buchstaben, Zahlen, Unterstrichen und Ampelli oder mehr bestehen kann", //8-32位字符长度，由英文字母、数字、_和-组成
  NameLength : "Bitte geben Sie einen Gerätenamen mit maximal 255 Zeichen ein, der aus Groß- und Kleinbuchstaben, Zahlen und Chinesisch besteht",
  UnameLength: "Bitte geben Sie einen Gerätenamen mit maximal 255 Zeichen ein, der aus Groß- und Kleinbuchstaben, Zahlen und chinesischen ",
  placeTip:"Bitte geben Sie die Seriennummer ein",
  deleteAll:"Stapellöschung",


   // nettimeout:"网络超时",
   accessTokenfail:"Dienstzeitüberschreitung, Zugriffstoken fehlgeschlagen",
   userinfo2faile:"Dienstzeitüberschreitung, Benutzerinformationserfassung fehlgeschlagen",
   getcodefail:"Dienstzeitüberschreitung, Abrufen des Verifizierungscodes fehlgeschlagen",
   getmailfail:"Dienstzeitüberschreitung, Änderung fehlgeschlagen",
   getdevicefail:"Service Timeout, Geräteliste kann nicht abgerufen werden",
   getareacodefail:"Service-Timeout, kann keine Vorwahlliste erhalten",
   rulenamefail:"Dienstzeitüberschreitung, Überprüfung des Benutzernamens fehlgeschlagen",
   rulephonefail:"Service Timeout, Überprüfung der Mobiltelefonnummer des Benutzers fehlgeschlagen",
   ruleregisterfail:"Dienstzeitüberschreitung, Registrierung fehlgeschlagen",
   ruleemailfail:"Dienstzeitüberschreitung, Überprüfung des Postfachs fehlgeschlagen",
   getdevicestatusfail:"Servicezeitüberschreitung, Gerätestatus kann nicht abgerufen werden",
 
   oldequipmentpassword:"Originalpasswort",
   oldpasswordrule:"Bitte geben Sie das Originalpasswort des Geräts ein. Wenn nicht, müssen Sie es nicht eingeben.",
   newequipmentpassword:"Neues Passwort",
   newpasswordrule:"Bitte geben Sie ein neues Passwort für das Gerät ein. Wenn nicht, müssen Sie es nicht eingeben.",
   confirmequippassword:"Passwort bestätigen",
   confirmeqpasswordrule:"Bitte beachten Sie das neue Passwort",
};
