import Vue from "vue";
import VueRouter from "vue-router";
const Login = () => import("@/views/login/Login.vue");
const Regist = () => import("@/views/regist/Regist.vue");
const Fpassword = () => import("@/views/Fpassword.vue");
const Admin = () => import("@/views/Admin.vue");
import MyEquip from "@/views/MyEquip.vue";
import MyList from "@/views/MyList.vue";
const LoginByToken = () => import("@/views/LoginByToken.vue");
const Maclogin = () => import("@/views/Maclogin.vue");
const Cookies = () => import("@/views/Cookies.vue");
const Privacy = () => import("@/views/Privacy.vue");
const originalReplace = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalReplace.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login
  },
  {
    path: "/regist",
    name: "regist",
    component: Regist
  },
  {
    path: "/fpassword",
    name: "fpassword",
    component: Fpassword
  },
  {
    path: "/maclogin",
    name: "maclogin",
    component: Maclogin
  },
  {
    path: "/loginByToken",
    name: "loginByToken",
    component: LoginByToken
  },
  {
    path: "/cookies",
    name: "cookies",
    component: Cookies
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    redirect: "/myequip",
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: "/myequip",
        name: "MyEquip",
        component: MyEquip,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/mylist",
        name: "MyList",
        component: MyList,
        meta: {
          requireAuth: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.requireAuth)) {
    if (localStorage["XM-Token"] && sessionStorage["userInfo"]) {
      next();
    } else {
      next({
        path: "/"
      });
    }
  } else {
    next();
  }
});
export default router;
