// // 引入路由和storage工具函数
import storage from "../utils/storage";
import router from "../router";

let lastTime = new Date().getTime();
let currentTime = new Date().getTime();
let timeOut = 60 * 60 * 1000; //设置超时时间: 30分钟

window.onload = function() {
  window.document.onmousedown = function() {
    storage.setItem("lastTime", new Date().getTime());
  };
};

function checkTimeout() {
  currentTime = new Date().getTime(); //更新当前时间
  lastTime = storage.getItem("lastTime");
  if (currentTime - lastTime > timeOut) {
    //判断是否超时

    // 跳到登陆页
    if (router.history.current.fullPath == "/") {
      return;
    } // 当前已经是登陆页时不做跳转
    else {
      // 清除storage的数据(登陆信息和token)
      storage.removeItem("XM-Token");
      sessionStorage.removeItem("index");
      sessionStorage.removeItem("userInfo");
      var url = window.location.href;
      var newUrl = url.match(/(\S*)#/)[1];
      window.open(newUrl + "#/", "_self");
    }

    // window.location.reload();
  }
}

export default function() {
  /* 定时器 间隔30秒检测是否长时间未操作页面 */
  window.setInterval(checkTimeout, 60000);
}
