module.exports = {
  //2022-6 新增
  cloudinfo: {
    normal: "Utilisation normale",
    timeOut: "expiré",
    nonactivated: "non activée",
    notSupport: "ne prend pas en charge",
    noRemainingFlow: "Aucun débit restant",
    VipStatus: "Statut VIP",
    totalFlow: "débit total",
    residualFlow: "débit résiduel",
    expireDate: "Date de fin de validité"
  },
  //新增
  cloud: "stockage en ligne",
  cloudTimeOut: "Stockage cloud (expiré)",
  cloudNormal: "Stockage Cloud (en cours d'utilisation)",
  cloudNonActivated: "Stockage en nuage (non ouvert)",
  cloudnotSupported:"Stockage dans le Cloud (non disponible)",
  vipTimeOut: "Trafic 4G (expiré)",
  vipNormal: "Trafic 4G (en cours d'utilisation)",
  vipNonActivated: "Trafic 4G (non ouvert)",
  vipnotSupported:"Trafic 4G (non disponible)",
  // 法语
  // 登录页
  userLogin: "Utilisateur", //用户登录
  equipLogin: "Grâce à lappareil", //设备登录
  login: "Connexion", //登录
  regist: "S'inscrire maintenant", //立即注册
  findPassword: "Oublier le mot de passe", //找回密码
  userRule: "S'il vous plaît entrer le nom d'utilisateur", //请输入用户名
  passwordRule: "S'il vous plaît entrer le mot de passe", //请输入密码
  codeRule: "S'il vous plaît entrez le code de vérification", //请输入验证码
  equipIdRule: "S'il vous plaît entrer le numéro de série de l'appareil", //请输入设备序列号
  appDownload: "APP télécharger", //App 下载
  webUnitDownload: "Télécharger", //Web控件下载
  callUs: "Nous contacter", //联系我们
  officialEdition: "Version classique", //经典版
  proEdition: "Version PRO", //PRO版
  codeLenRule: "Veuillez saisir le code de vérification à 4 chiffres", //请输入4位验证码
  notEmpty: "N'entrez pas d'espaces", //请勿输入空格

  // 注册
  userRegist: "Créer un compte", //创建账户
  emailRule: "Veuillez saisir votre email", //请输入邮箱
  emailtrue: "Veuillez entrer le numéro de boîte aux lettres au format correct", //请输入正确格式的邮箱号
  phonetrue:"Veuillez entrer un numéro de téléphone portable dans le bon format",//请输入正确的手机号码
  confirmPasswordRule: "Veuillez confirmer votre mot de passe", //请再次输入密码
  hadCount: "Vous avez déjà un compte", //已有账号，立即登录
  registCount: "S'inscrire", //注册
  hadUser: "Votre nom d'utilisateur enregistré existe déjà", //您注册的用户名已存在
  hadMail: "Votre email enregistré existe déjà", //您注册的邮箱已存在
  sendSuccess: "Courrier envoyé avec succès", //邮件发送成功
  mailFormatError: "Erreur de format d'e-mail, veuillez vérifier", //邮箱格式错误，请检查
  codeError: "Erreur de code de vérification", //验证码错误
  registerSuccess: "Réussite de l'inscription", //注册成功
  mailNotFind:
    "L'adresse e-mail que vous avez saisie n'existe pas, veuillez vérifier", //您输入的邮箱不存在，请检查
  userNameLen: "Veuillez saisir un nom d'utilisateur de 4 à 8 caractères", //请输入4~8位用户名
  userPwdLen: "Veuillez saisir un mot de passe de 8 à 32 chiffres", //请输入8~32位密码
  privacyPolicy:"Lire et sélectionner la politique de confidentialité",//阅读并选择隐私政策
  emailRegistration:"Inscription à la boîte aux lettres",//邮箱注册
  mobileRegistration:"Enregistrement mobile",//手机注册
  // 找回密码
  phoneRetrievePassword:"Téléphone portable récupérer le mot de passe",//手机找回密码
  mailboxRetrievalPassword:"Mailbox récupérer le mot de passe",//邮箱找回密码
  findThePassword: "Oublier le mot de passe", //找回密码
  passwordText:
    "Veuillez saisir l'adresse e-mail utilisée lors de l'enregistrement du compte pour récupérer le mot de passe", //请输入注册账号时使用的邮箱来找回密码
  exit: "quitter", //退出
  nextStep: "L'étape suivante", //下一步
  reset: "Réinitialiser", //重新设置
  getCode: "Envoyer", //获取验证码
  someTime: "Réacquérir", //秒后重新获取
  ok: "Confirmer", //确定
  mailSendMuch:
    "Le courrier ne peut être envoyé que trois fois par jour par boîte aux lettres", //邮件每个邮箱一天只能发送三次
  mailChangeSuccess: "Modifié avec succès", //修改成功
  pwdError: "Format de mot de passe incorrect", //密码格式不正确
  codeTimeout: "Le code de vérification est expiré", //验证码过期
  codeErrorMuch: "Trop d'erreurs, veuillez réessayer plus tard", //错误次数过多,请稍后再试
  backLogin: "Retour à la connexion", //返回登录
  mailFast:
    "Les e-mails répétitifs ne sont pas autorisés dans un court laps de temps", //短时间内不允许重复发送邮件

  //主页面
  refresh: "Rafraîchir", //刷新
  myDevice: "Mon appareil", //我的设备
  personalInformation: "Information personnelle", //个人信息
  deviceManagement: "Gestion des équipements", //设备管理
  VIPTraffic:"Trafic VIP",//VIP流量
  changePassword: "Changer le mot de passe", //修改密码
  changePasswordSuccess: "Changer le mot de passe avec succès", //修改密码成功
  LogOut: "Se déconnecter", //退出登录
  shurLogOut: "Êtes-vous sûr de vouloir quitter", //你确定要退出吗
  oldPassword: "Ancien mot de passe", //旧密码
  newPassword: "Nouveau mot de passe", //新密码
  inputNewPassword: "Confirmez le mot de passe", //再次输入新密码
  errorRefresh: "Échec de l'actualisation", //刷新失败
  online: "Travail", //在线
  offline: "Hors ligne", //离线
  UidLength:
    "Veuillez saisir un numéro de série de l'appareil à 16 ou 20 chiffres composé de lettres majuscules et minuscules et de chiffres", //请输入16或20位由大,小写字母,数字组成的设备序列号
    PwordLength: "Veuillez entrer le mot de passe de l'appareil d'au plus 100 chiffres composé de grandes lettres minuscules et de chiffres",
    passwordrule: "N'entrez pas de symboles spéciaux", //请勿输入特殊符号
  equipOffline:
    "L'appareil est temporairement hors ligne, veuillez choisir un autre appareil", //设备暂时不在线请选择其它设备吧
  BrowserSupport:
    "Ce navigateur ne prend pas en charge la lecture plug-in, veuillez utiliser Google Chrome", //此浏览器不支持插件播放，请使用IE或360浏览器
  embedBrowserFail:
    "Échec de la liaison du navigateur, veuillez ne pas changer de page rapidement lors de la connexion", //绑定浏览器失败，请在登录时勿快速切换页面
  noPlugin: "Plugin non détecté", //未检测到插件
  CancelAccount: "Annuler le compte",
  CancelAccountTitle: "Êtes-vous sûr de vouloir supprimer le compte?",
  CancelSuccess: "Résilier le compte avec succès",

  // 设备列表、
  order: "", //序号
  equipmentPwd: "Mot de passe de l'appareil", //设备密码
  equipmentOrder: "Numéro de série", //序列号
  equipmentName: "Nom de lappareil", //名称
  equipmentUser: "Nom dutilisateur", //设备用户名
  option: "Opérer", //操作
  addEquipment: "Ajouter un appareil", //添加设备
  changeEquipmentMessage: "Modifier les informations de l'appareil", //修改设备信息
  changePasswordMessage:"Modifier les informations de mot de passe",//修改密码信息
  deleteEquipment:"Supprimer un appareil",//删除设备
  delmessage: "Etes-vous sûr que vous voulez supprimer", //你确定要删除吗
  all: "Au total", //共
  tips: "données", //条数据
  add: "Ajouter à", //添加
  equipmentSuccess: "Informations sur l'appareil modifiées avec succès", //设备信息修改成功
  addUid:
    "Veuillez entrer le numéro de série de l'appareil que vous souhaitez ajouter", //请输入你要添加的设备序列号
  addid: "Veuillez entrer le numéro de série que vous souhaitez ajouter", //请输入你要添加的序号
  addname: "Veuillez saisir le nom de l'appareil que vous souhaitez ajouter", //请输入你要添加的设备名
  addUsername:
    "Veuillez saisir le nom d'utilisateur de l'appareil que vous souhaitez ajouter", //请输入你要添加的设备用户名

  // 用户信息
  userinfo: "Infos utilisateurs", //用户信息
  userName: "Nom d'utilisateur", //用户名
  usercontact: "Coordonnées", //联系方式
  useremail: "Email", //电子邮箱

  // 全局提示
  successLogin: "connectez-vous avec succès", //登录成功
  errorLogin1:
    "Échec de la connexion, le nom d'utilisateur ou le mot de passe est incorrect", //登录失败，用户名或密码错误
  errorLogin2: "Échec de la connexion, erreur de code de vérification", //登录失败，验证码错误
  errorLogin3: "Échec de la connexion, code de vérification expiré", //登录失败，验证码过期
  errorLogin4: "Trop d'échecs de connexion, compte bloqué pendant 10 minutes", //登录失败次数过多，账户锁定10分钟
  delsucess: "supprimé avec succès", //删除成功
  addSuccess: "Ajouté avec succès", //添加成功
  addFail: "échec de l'ajout", //添加失败
  timeOut:
    "délai d'attente de connexion, sauter automatiquement à la page de connexion", //登录超时，自动跳转登录页
  changeEquipmentPwdError:
    "Le mot de passe d'origine de l'appareil a été mal saisi", //原设备密码输入错误

  // 网络提示
  accessnot: "Accès refusé", //访问被拒绝
  resourcenot: "Ressource indisponible", //资源不可用
  nopage: "Page non trouvée", //找不到页面
  methoderror: "Méthode non autorisée", //不允许此方法
  serveerror: "Erreur de serveur", //服务器错误
  unknownerror: "Erreur inconnue", //未知错误

  // 表单提示
  emailTip: "**@**.***",
  phoneTip:"Veuillez entrer le bon numéro de téléphone portable",
  confirmTip: "Veuillez être cohérent avec le mot de passe", //请与密码保持一致
  equipmentIdTip:
    "Veuillez saisir le numéro de série de l'appareil à 16 ou 20 chiffres", //请输入16或20位设备序列号
  equipmentUserTip: "Veuillez saisir le nom d'utilisateur de l'appareil", //请输入设备用户名
  equipmentPasswordTip1: "Veuillez saisir le mot de passe de l'appareil", //请输入设备密码
  equipmentPasswordTip:
    "Veuillez saisir le mot de passe de l'appareil, vous n'avez pas besoin de le saisir si vous n'en avez pas", //请输入设备密码，没有则无需输入
  codeTip: "Veuillez entrer le code de vérification de l'e-mail obtenu", //请输入获取的邮箱验证码
  equipmentPwdTip: "Le mot de passe de l'appareil est vide par défaut", //设备密码默认为空
  equipmentNameTip: "Veuillez saisir le nom de l'appareil", //请输入设备名称
  equipmentUserNameTip:
    "Le nom d'utilisateur de l'appareil par défaut est « admin »", //设备用户名默认为“admin”
  pagetip1:
    "Cliquez sur l'appareil dans “Mon appareil” sur la gauche pour prévisualiser, rejouer, configurer et d'autres opérations sur l'appareil", //单击左边“我的设备”中的设备，对设备进行预览，回放，配置等操作
  pagetip2:
    "Si c'est votre première visite, téléchargez le plugin, cela peut prendre quelques minutes, soyez patient", //如果您是第一次访问，会自动下载插件，可能需要花费几分钟时间，请耐心等待
  passwordChangeTip:
    "Si vous ne modifiez pas le mot de passe, veuillez le laisser vide", //若不修改密码请保持为空
  loginUseNameTip:
    "Vous pouvez vous connecter avec votre nom d'utilisateur, votre numéro de courriel et votre numéro de téléphone portable", //可使用用户名、邮箱号、手机号登录

  placeholderUser:
    "Nom d'utilisateur/numéro de courriel/numéro de téléphone portable", //用户名/邮箱号/手机号
  placeholderPassword: "Mot de passe", //密码
  placeholderCode: "Code de vérification", //验证码
  placeholderEquipId: "Numéro de série", //设备序列号
  placeholderEquipAdmin: "Nom d'utilisateur de l'appareil", //设备用户名
  placeholderEquipPassword: "Mot de passe de l'appareil", //设备密码
  placeholderRegistUser: "Nom d'utilisateur", //用户名
  placeholderRegistMail: "Email", //邮箱
  placeholderRegistPhone: "Téléphone portable", //手机
  placeholderRegistCode: "Code de vérification", //验证码
  placeholderRegistPwd: "Mot de passe", //密码
  placeholderRegistPwd2: "Confirmer mot de passe", //确认密码

  supportTitle: "Coordonnées de l'assistance technique", //技术支持联系方式
  supportPhone: "Service d'assistance téléphonique", //服务热线
  supportMail: "Boîte aux lettres de services", //服务邮箱
  supportTime:
    "Heures de service : du lundi au samedi de 8h30 à 17h30 (sauf jours fériés)", //服务时间：周一到周六8:30--17:30（法定节假日除外）
  userPwdRule: "Format de mot de passe est erroné", //密码格式错误
  userNameRule: "Format de nom d'utilisateur incorrect", //用户名格式错误
  Ok: "Confirmer", //确认
  cancel: "Annuler", //取消
  backOld: "Ancienne version", //回到旧版

  cookie:
    "Notre site Web utilise des cookies pour améliorer notre site Web et améliorer l'expérience utilisateur. Si vous continuez à naviguer sur ce site Web sans modifier les paramètres de cookies de votre navigateur, vous êtes réputé accepter notre politique d'utilisation des cookies.", //我们的网站使用cookies以改进我们的网站和改善用户体验。如您继续浏览本网站而未更改浏览器cookie设置，视为同意我们的cookies使用策略。
  cookieLink: "Termes", //政策
  agree: "être d'accord", //同意
  notAgree: "être en désaccord", //不同意

  cookiePolicy: "Politique de cookies", //Cookie 政策
  cookieUse: "Comment nous utilisons les cookies et technologies similaires", //我们如何使用Cookie和同类技术
  cookieWhy:
    "Pour assurer le fonctionnement normal du site Web, nous stockerons de petits fichiers de données appelés Cookies sur votre ordinateur ou appareil mobile. Les cookies contiennent généralement des identifiants, des noms de sites et quelques chiffres et caractères. À l'aide de cookies, les sites Web peuvent stocker des données telles que vos préférences.", //为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据档。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。
  cookieFor:
    "Nous n'utiliserons pas de cookies à d'autres fins que celles indiquées dans cette politique. Vous pouvez gérer ou supprimer les cookies selon vos préférences. Pour plus de détails, voir AboutCookies.org. Vous pouvez effacer tous les cookies enregistrés sur votre ordinateur, et la plupart des navigateurs Web ont une fonction pour bloquer les cookies. Mais si vous faites cela, vous devez modifier personnellement les paramètres de l'utilisateur chaque fois que vous visitez notre site Web. Pour en savoir plus sur la façon de modifier les paramètres du navigateur, veuillez visiter la page des paramètres correspondants du navigateur que vous utilisez. Cependant, veuillez noter que si vous n'acceptez pas les cookies, vous ne pourrez peut-être pas profiter pleinement de certaines fonctionnalités du site Web.", //我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。但请注意，如果您不接受cookie，可能无法全面体验网站的某些功能。
  cookieList: "Voici une liste des types de cookies utilisés par le site Web :", //以下是网站使用的cookie种类列表：
  cookiePurpose: "But", //目的
  cookieDescribe: "décris", //描述
  cookieType: "Type et date d'expiration", //种类和失效时间
  cookiePerformance: "Performances (navigateur utilisateur)", //性能（用户浏览器）
  cookieLogin:
    "Enregistrez le statut de connexion de l'utilisateur et n'avez pas besoin de vous reconnecter dans les 7 jours", //记录用户登录状态，以及7天内不用重新登录
  cookieDay: "7 jours", //7天
  cookieCnzz: "CNZZ", //CNZZ
  cookieTime:
    "Enregistrez le nombre de fois que l'utilisateur visite le site Web", //记录用户访问网站的次数
  cookieForever: "permanent", //永久
  cookieTrack:
    "De nombreux navigateurs Web ont une fonction Do Not Track, qui peut publier des demandes Do NotTrack sur des sites Web. Actuellement, les principales organisations de normalisation Internet n'ont pas établi de politiques pertinentes pour réglementer la manière dont les sites Web doivent répondre à de telles demandes. Mais si votre navigateur active Do Not Track, notre site Web respectera votre choix.", //很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do NotTrack请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站都会尊重您的选择。
  backHome: "Retour à la page d'accueil", //回到首页
  // RS
  RS2000: 'Exécution réussie',//执行成功
  RS4000: "Nom d'utilisateur ou mot de passe incorrect",//用户名或密码错误
  RS4010: 'Erreur de code de vérification',//验证码错误
  RS4011: 'Incohérence du mot de passe',//密码不一致
  RS4012: "Le nom d'utilisateur est enregistré",//用户名已被注册
  RS4013: "Le nom d'utilisateur est vide",//用户名为空
  RS4014: 'Le mot de passe est vide',//密码为空
  RS4015: 'Confirmez que le mot de passe est vide',//确认密码为空
  RS4016: 'Le numéro de téléphone est vide',//手机号为空
  RS4017: "Format incorrect du nom d'utilisateur",//用户名格式不正确
  RS4018: 'Le format du mot de passe est incorrect',//密码格式不正确
  RS4019: 'Confirmez que le format du mot de passe est incorrect',//确认密码格式不正确
  RS4020: 'Format incorrect du numéro de téléphone',//手机号格式不正确
  RS4021: 'Numéro de téléphone enregistré',//手机号已注册
  RS4022: 'Numéro de téléphone non enregistré',//手机号未注册
  RS4023: 'La boîte aux lettres est enregistrée',//邮箱已注册
  RS4024: 'Boîte aux lettres non enregistrée',//邮箱未注册
  RS4025: "Nom d'utilisateur non enregistré",//用户名未注册
  RS4026: 'Erreur dans le mot de passe original',//原密码错误
  RS4027: 'La modification du mot de passe a échoué',//修改密码失败
  RS4029: "L'ID utilisateur est vide",//用户ID为空
  RS4030: 'Le Code de vérification est vide',//验证码为空
  RS4031: 'La boîte aux lettres est vide',//邮箱为空
  RS4032: 'Format de boîte aux lettres incorrect',//邮箱格式不正确
  RS4033: 'Format de boîte aux lettres incorrect',//邮箱格式不正确
  RS4034: "Wechat n'est pas lié",//微信没有绑定
  RS4035: 'Lier Wechat a échoué',//绑定微信失败
  RS4036: 'La liaison du numéro de téléphone a échoué',//绑定手机号失败
  RS4037: 'La liaison de la boîte aux lettres a échoué',//绑定邮箱失败
  RS4038: 'Envoyer un code de vérification plus que le nombre maximal de fois',//发送验证码超过最大次数
  RS4039: 'Enregistrement échoué',//注册失败
  RS4040: "Wechat a lié l'utilisateur",//微信已绑定用户
  RS4041: "Aucun droit de modifier le nom d'utilisateur (uniquement pour les utilisateurs anonymes générés)",//没有权限修改用户名（仅针对生成的匿名用户修改）
  RS4042: 'User Non FB Les utilisateurs de BIND ne sont pas liés à Facebook',//USER_NOT_FB_BIND 用户没有绑定facebook
  RS4043: 'User Fail FB BIND utilisateur lié Facebook a échoué',//USER_FAIL_FB_BIND 用户绑定facebook失败
  RS4044: "User Non Gg Les utilisateurs de BIND n'ont pas Google BIND",//USER_NOT_GG_BIND 用户没有google绑定
  RS4045: "User Fail Gg BIND l'utilisateur lié à Google a échoué",//USER_FAIL_GG_BIND 用户绑定google失败
  RS4046: "Le compte line n'est pas lié",//Line账户未绑定
  RS4047: 'La liaison de compte Line a échoué',//Line账户绑定失败
  RS4100: "L'équipement n'est pas autorisé à ajouter illégalement",//设备非法不允许添加
  RS4101: "L'équipement existe déjà",//设备已存在
  RS4102: 'La suppression du périphérique a échoué',//删除设备失败
  RS4103: 'La modification a échoué',//修改失败
  RS4104: 'Device uuid paramètres exceptions',//设备uuid参数异常
  RS4105: 'Paramètre Device username exception',//设备用户名参数异常
  RS4106: "Paramètres du mot de passe de l'appareil exceptions",//设备密码参数异常
  RS4116: "Le transfert du compte principal de l'appareil à un autre utilisateur échoue, vérifiez que l'utilisateur possède l'appareil et que l'utilisateur dispose des autorisations du compte principal de l'appareil",//将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限
  RS4117: 'Pas le compte principal',//不是主账户
  RS4118: "L'utilisateur n'a pas cet appareil",//用户没有此设备
  RS4119: "Ajouter un appareil n'est pas unique, d'autres comptes ont été ajoutés",//添加设备不唯一，其它账户已添加
  RS4120: "Ajouter une limite maximale d'appareils (100)",//添加设备最大限制(100个)
  RS4126: "Le jeton de support de l'appareil ne peut être ajouté que par un seul compte",//设备支持令牌只有一个账户添加
  RS4127: "Manque de jetons d'équipement",//缺少设备令牌
  RS4300: "L'envoi a échoué",//发送失败
  RS4302: 'Envoyer le Code de vérification de déconnexion avec succès',//发送注销验证码成功
  RS4400: "La vérification de l'interface SMS a échoué, veuillez nous contacter",//短信接口验证失败，请联系我们
  RS4401: "Paramètres d'interface SMS incorrects, contactez - nous",//短信接口参数错误，请联系我们
  RS4402: 'Chaque numéro de téléphone ne peut envoyer que trois SMS par jour',//每个手机号一天只能发送三次短信
  RS4403: "L'envoi a échoué, Veuillez réessayer plus tard",//发送失败，请稍后再试
  RS4404: "Envoyer trop souvent, s'il vous plaît espacer de 120 secondes",//发送太频繁了，请间隔120秒
  RS4405: "L'envoi a échoué",//发送失败
  RS5000: "Défaillance du serveur",//服务器故障
  RS5001: "Le certificat n'existe pas",//证书不存在
  RS5002: "Erreur dans les informations d'en - tête de demande",//请求头信息错误
  RS5003: "Expiration du certificat",//证书失效
  RS5004: "Générer une erreur de contrôle de clé",//生成密钥校验错误
  RS5005: 'Anomalies des paramètres',//参数异常
  RS5009: 'Erreur de code Wechat, erreur de déchiffrement AES plus',//微信code错误、AES加解密错误
  RS5010: 'Le token a expiré',//token 已过期
  RS5011: 'Erreur token',//token 错误
  RS5012: 'Token sans droits',//token 无权限
  RS5013: "Pas de soutien",//不支持
  RS5014: 'Opération trop fréquente',//操作太频繁
  RS4050: 'Opération trop fréquente',//操作太频繁
  phoneCodeTip: "Veuillez entrer le Code de vérification du téléphone obtenu",//======请输入获取的手机验证码
  phoneNotFind: "Le numéro de téléphone que vous avez entré n'existe pas, vérifiez",//=====您输入的手机号码不存在，请检查
  phoneFormatError: "Le format du numéro de téléphone portable est incorrect, veuillez vérifier",//======手机号码格式错误，请检查
  phoneSendSuccess: "CAPTCHA envoyé avec succès Veuillez noter la réception",//=======验证码发送成功请注意查收
  hadPhone: "Votre numéro de mobile enregistré existe déjà", //======您的注册手机号码已存在
  phoneRule: "Veuillez entrer un numéro de téléphone portable",//======请输入手机号码


  useNameTip:"4 - 15 caractères de BIT, peut être composé de chinois, lettres, chiffres, Et - composé de deux éléments et plus", //4-15位字符，可由中文、数字、_和-组成
  passwordTip:"Longueur de caractères de 8 à 32 bits, peut être composée de lettres anglaises, de chiffres, de tirets bas et de - deux éléments et plus", //8-32位字符长度，由英文字母、数字、下划线和-组成
  NameLength: "Veuillez entrer un nom d'appareil ne dépassant pas 255 bits composé de grandes lettres minuscules, chiffres, chinois",
  UnameLength: "S'il vous plaît entrer pas plus de 255 bits composé de grandes, minuscules lettres, chiffres, chinois nom d'utilisateur de l'appareil ",
  placeTip:"Veuillez entrer le numéro de série",
  deleteAll:"Suppression par lots",


  // nettimeout:"网络超时",
  accessTokenfail:"Délai d'expiration du service, l'obtention d'accesstoken échoue",
  userinfo2faile:"Délai d'expiration du service, l'acquisition d'informations utilisateur échoue",
  getcodefail:"Délai d'expiration du service, l'obtention du CAPTCHA a échoué",
  getmailfail:"Le Service a expiré, la modification a échoué",
  getdevicefail:"Délai d'expiration du service, impossible d'obtenir la liste des périphériques",
  getareacodefail:"Délai d'expiration du service, impossible d'obtenir la liste des indicatifs régionaux",
  rulenamefail:"Délai d'expiration du service, échec de la vérification du nom d'utilisateur",
  rulephonefail:"Délai d'expiration du service, échec de la vérification du numéro de téléphone de l'utilisateur",
  ruleregisterfail:"Le Service a expiré, l'inscription a échoué",
  ruleemailfail:"Délai d'expiration du service, échec de la vérification de la boîte aux lettres",
  getdevicestatusfail:"Délai d'expiration du service, impossible d'obtenir l'état de l'appareil",

  oldequipmentpassword:"Le mot de passe original",
  oldpasswordrule:"Veuillez entrer le mot de passe original de l'appareil, aucun n'est nécessaire",
  newequipmentpassword:"Nouveau mot de passe",
  newpasswordrule:"Veuillez entrer le nouveau mot de passe de l'appareil, aucun n'est nécessaire",
  confirmequippassword:"Confirmer le mot de passe",
  confirmeqpasswordrule:"Veuillez rester cohérent avec votre nouveau mot de passe",
};
