<template>
  <div class="adminbox">
    <a-layout>
      <a-layout-header>
        <!-- logo -->
        <div @click="gohome()">
          <img src="../assets/image/logo.png" alt="" />
        </div>
        <!-- item链接 -->
        <div class="item">
          <div class="menu">
            <a-menu mode="horizontal" v-model="current">
              <a-menu-item key="/myequip" @click="changeMenu('/myequip')"
                >{{ $t(`myDevice`) }}
              </a-menu-item>
              <a-menu-item key="/mylist" @click="changeMenu('/mylist')"
                >{{ $t(`deviceManagement`) }}
              </a-menu-item>
              <a-menu-item @click="toCloudBuyvip"
                >{{ $t(`VIPTraffic`) }}
              </a-menu-item>
            </a-menu>
          </div>
          <!-- 语言选择 -->
          <div class="header-right">
            <!--下载工具-->
            <div class="download" style="cursor: pointer">
              <span class="download-span" @click="Download()">
                {{ $t(`webUnitDownload`) }}
              </span>
            </div>
            <!-- 用户功能 -->
            <div class="person">
              <img
                style="width: 20px; padding-bottom: 5px"
                src="../assets/image/person.png"
                alt=""
              />
            </div>
            <div class="avator">
              <a-dropdown placement="bottomCenter" size="large">
                <a> {{ showuserName }}</a>
                <a-menu slot="overlay" size="large">
                  <a-menu-item key="1" @click="showUseModal" size="large">
                    <a-icon type="user" size="22px" />{{ $t(`userinfo`) }}
                  </a-menu-item>

                  <a-menu-item key="2" @click="showModal" size="large">
                    <a-icon type="lock" size="22px" />{{
                      $t(`changePassword`)
                    }}</a-menu-item
                  >

                  <a-menu-item key="4" @click="beginCancelAccount" size="large">
                    <a-icon type="poweroff" size="22px" />
                    {{ $t(`CancelAccount`) }}
                  </a-menu-item>
                  <a-menu-item key="3" @click="showConfirm" size="large">
                    <a-icon type="logout" size="22px" />{{
                      $t(`LogOut`)
                    }}</a-menu-item
                  >
                </a-menu>
              </a-dropdown>
              <!-- 修改密码模态框 -->
              <a-modal
                :title="$t('changePassword')"
                :visible="visible"
                @ok="handleSubmit"
                @cancel="handleCancel"
              >
                <a-form :form="form">
                  <!-- 旧密码 -->
                  <a-form-item :label="$t(`oldPassword`)" has-feedback>
                    <a-tooltip placement="right">
                      <template slot="title"
                        ><span class="tips">{{ $t(`passwordTip`) }}</span>
                      </template>
                      <a-input-password
                        v-decorator="[
                          'oldPass',
                          {
                            rules: [
                              {
                                required: true,
                                message: this.$t('passwordRule'),
                              },
                              {
                                validator: validateToNextPassword,
                              },
                              {
                                pattern: /^[^\s]*$/,
                                message: this.$t('notEmpty'),
                              },
                              {
                                pattern:/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\u4e00-\u9fa5a]+$)(?![-_]+$)[\u4e00-\u9fa5a-zA-Z0-9_-]{8,32}$/,
                                message: this.$t('userPwdRule'),
                              },
                            ],
                          },
                        ]"
                        type="password"
                      />
                    </a-tooltip>
                  </a-form-item>
                  <!-- 新密码 -->
                  <a-form-item :label="$t(`newPassword`)" has-feedback>
                    <a-tooltip placement="right">
                      <template slot="title"
                        ><span class="tips">{{ $t(`passwordTip`) }}</span>
                      </template>
                      <a-input-password
                        v-decorator="[
                          'newPass',
                          {
                            rules: [
                              {
                                required: true,
                                message: this.$t('passwordRule'),
                              },
                              {
                                validator: validateToNextPassword,
                              },
                              {
                                pattern: /^[^\s]*$/,
                                message: this.$t('notEmpty'),
                              },
                              {
                                pattern:/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\u4e00-\u9fa5a]+$)(?![-_]+$)[\u4e00-\u9fa5a-zA-Z0-9_-]{8,32}$/,
                                message: this.$t('userPwdRule'),
                              },
                            ],
                          },
                        ]"
                        type="password"
                      />
                    </a-tooltip>
                  </a-form-item>

                  <!-- 再次输入新密码  -->
                  <a-form-item :label="$t(`inputNewPassword`)" has-feedback>
                    <a-tooltip placement="right">
                      <template slot="title"
                        ><span class="tips">{{ $t(`confirmTip`) }}</span>
                      </template>
                      <a-input-password
                        v-decorator="[
                          'confirmPass',
                          {
                            rules: [
                              {
                                required: true,
                                message: this.$t('confirmPasswordRule'),
                              },
                              {
                                validator: compareToFirstPassword,
                              },
                              {
                                pattern: /^[^\s]*$/,
                                message: this.$t('notEmpty'),
                              },
                            ],
                          },
                        ]"
                        type="password"
                        @blur="handleConfirmBlur"
                      />
                    </a-tooltip>
                  </a-form-item>
                </a-form>
              </a-modal>
              <!-- 显示用户信息模态框 -->

              <!-- 用户注销 -->
              <a-modal
                :maskClosable="false"
                :keyboard="false"
                v-model="isCancelAccount"
                :title="$t('CancelAccount')"
                @ok="verificationFunction"
                @cancel="hideModal"
              >
                <a-form-model ref="ruleForm" :model="formInline" :rules="rules">
                  <a-form-model-item
                    :label="$t('placeholderCode')"
                    prop="CancelAccountCode"
                  >
                    <a-input
                      v-model="formInline.CancelAccountCode"
                      :placeholder="$t('placeholderCode')"
                    />
                  </a-form-model-item>
                </a-form-model>
              </a-modal>
            </div>
          </div>
        </div>
      </a-layout-header>
      <!-- 侧边设备 -->
      <a-layout>
        <a-layout-sider
          class="slider-box"
          :style="{ minWidth: 230 + width + 'px' }"
        >
          <div class="contanerBox">
            <a-affix
              :offset-top="62"
              :style="{ minWidth: 230 + width + 'px' }"
              style="
                flex: 0 0 230px;
                max-width: 230px;
                min-width: 0px;
                width: 230px;
              "
            >
              <div class="statue-box" :style="{ minWidth: 230 + width + 'px' }">
                <a-tag class="statue1">{{ $t(`online`) }}:{{ online }}</a-tag>
                <a-tag>{{ $t(`offline`) }}:{{ offline }}</a-tag>
                <a-tag style="cursor: pointer" color="blue" @click="autoRefresh"
                  ><a-icon v-if="loading" type="sync" />
                  <a-icon v-else type="sync" spin
                /></a-tag>
              </div>
            </a-affix>

            <ul class="videolist">
              <li
                v-for="(item, index) in showEquipList"
                :key="item.id"
                :class="[index == active ? 'active' : '']"
                @click="
                  getMac(
                    item.username,
                    item.password,
                    item.uuid,
                    item.status,
                    item.devToken,
                    item.tcsToken,
                    $event,
                    index
                  )
                "
              >
                <a-icon
                  type="video-camera"
                  :id="[item.wakeUpStatus == 0 ? 'orange' : '123']"
                  :class="[item.status ? 'online' : 'offline', 'camera']"
                />
                <span class="title">
                  <a-tooltip placement="bottom">
                    <template slot="title">
                      {{ item.nickname }}
                    </template>
                    {{ item.nickname }}
                  </a-tooltip></span
                >

                <span
                  :title="$t('cloudNormal')"
                  class="cloud-icon-box"
                  v-if="item.cssStatus == 1"
                  @click.stop="toCloudBuy(item.uuid)"
                  ><img src="../assets/image/hcloud@2x.png" alt=""
                /></span>
                <span
                  :title="$t('cloudNonActivated')"
                  class="cloud-icon-box"
                  v-if="item.cssStatus == 3"
                  @click.stop="toCloudBuy(item.uuid)"
                  ><img src="../assets/image/ncloud@2x.png" alt=""
                /></span>
                <span
                  :title="$t('cloudTimeOut')"
                  class="cloud-icon-box"
                  v-if="item.cssStatus == 2"
                  @click.stop="toCloudBuy(item.uuid)"
                  ><img src="../assets/image/scloud@2x.png" alt=""
                /></span>
                <span
                  :title="$t('cloudnotSupported')"
                  class="cloud-icon-box"
                  v-if="item.cssStatus == 4"
                  @click.stop="toCloudBuy(item.uuid)"
                  ><img src="../assets/image/nvcloud.png" alt=""
                /></span>
                <span
                  :title="$t('vipNormal')"
                  class="cloud-icon-box1"
                  v-if="item.celStatus == 1"
                  @click.stop="toCloudBuy(item.uuid)"
                  ><img src="../assets/image/htraffic@2x.png" alt=""
                /></span>
                <span
                  :title="$t('vipTimeOut')"
                  class="cloud-icon-box1"
                  v-if="item.celStatus == 2"
                  @click.stop="toCloudBuy(item.uuid)"
                  ><img src="../assets/image/straffic@2x.png" alt=""
                /></span>
                <!-- 1正常 2过期 3不可用 4不支持 -->
                <span
                  :title="$t('vipNonActivated')"
                  class="cloud-icon-box1"
                  v-if="item.celStatus == 3"
                  @click.stop="toCloudBuy(item.uuid)"
                  ><img src="../assets/image/ntraffic@2x.png" alt=""
                /></span>
                <span
                  :title="$t('vipnotSupported')"
                  class="cloud-icon-box1"
                  v-if="item.celStatus == 4"
                  @click.stop="toCloudBuy(item.uuid)"
                  ><img src="../assets/image/nvtraffic.png" alt=""
                /></span>
              </li>
            </ul>
          </div>
        </a-layout-sider>
        <a-layout-content class="viewbox">
          <router-view> </router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <UserInfo ref="userInfo" />
  </div>
</template>

<script>
import { showmessage } from "../utils/showMessage";
import { encrypt, decrypt } from "@/utils/aes";
import { selectlan, selectUnitLang } from "../utils/selectLanguage";
import UserInfo from "./modules/user-info.vue";

let updatePostionInfoCallback;
let onVisibilityCallback;
let hidePluginCallback;
let g_bPageHide = false;
export function updatePostionInfo(event) {
  if (typeof updatePostionInfoCallback == "function") {
    updatePostionInfoCallback(event, 0);
  }
}
function onVisibility() {
  if (document.visibilityState !== "visible" || document.hidden == true) {
    g_bPageHide = true;
  } else {
    g_bPageHide = false;
  }
  if (typeof onVisibilityCallback == "function") {
    onVisibilityCallback(g_bPageHide);
  }
}
function getHiddenProp() {
  let arr = ["webkit", "moz", "ms", "o"];
  if ("hidden" in document) {
    return "hidden";
  }
  for (let i = 0x0; i < arr["length"]; i++) {
    if (arr[i] + "Hidden" in document) {
      return arr[i] + "Hidden";
    }
  }
  return null;
}
let visProp = getHiddenProp();
if (visProp) {
  if (document.addEventListener) {
    document.addEventListener(
      visProp["replace"](/[H|h]idden/, "") + "visibilitychange",
      onVisibility,
      ![]
    );
  } else if (document.attachEvent) {
    document.attachEvent(
      visProp["replace"](/[H|h]idden/, "") + "onvisibilitychange",
      onVisibility
    );
  }
}
let BrowseType = {
  BrowseMSIE: 0,
  BrowseSafari: 1,
  BrowseChrome: 2,
  BrowseMacOS: 3,
  BrowseFirefox: 4,
  BrowseEdge: 5,
  BrowseOpera: 6,
  BrowseMozilla: 7,
  Browse360: 8,
  BrowseQQ: 9,
  BrowseBaidu: 10,
  BrowseSougou: 11,
  BrowseUnknow: 12,
};
export default {
  components: {
    UserInfo,
  },
  created() {
    this.current[0] = sessionStorage.getItem("index")
      ? sessionStorage.getItem("index")
      : "/myequip";
    this.boxWidth = sessionStorage.getItem("boxWidth")
      ? sessionStorage.getItem("boxWidth")
      : this.boxWidth;
    this.autoRefresh();

    this.detectPort(54455, 54465, (port) => {
      this.pluginPort = port;
    });

    setInterval(() => {
      //保活
      let obj = [
        {
          uuid:
            this.equipListoffline &&
            this.equipListoffline[0] &&
            this.equipListoffline[0].uuid,
        },
      ];
      console.log("admin create setinterval", obj);
      this.$http.post("/transfer/devstatus/v1", obj, {
        headers: {
          "Access-Token": localStorage.getItem("XM-Token"),
        },
      });
    }, 600000);
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "changePwd" });
  },
  data() {
    return {
      timeid: null,
      width: 0,
      boxWidth: true,
      current: ["/myequip"],
      loading: true,
      equipList: [],
      list: [],
      equipListonline: [],
      equipListoffline: [],
      uuidlist: [],
      showEquipList: [],
      // userName: "",
      userPhone: "",
      userEmail: "",
      visible: false,
      visibleUser: false,
      online: 0,
      offline: 0,
      active: -1,
      isCancelAccount: false,
      formInline: {
        CancelAccountCode: "",
      },
      rules: {
        CancelAccountCode: [
          {
            required: true,
            message: this.$t("codeRule"),
            trigger: "blur",
          },
          {
            pattern: /^[^\s]*$/,
            message: this.$t("notEmpty"),
          },
          {
            len: 4,
            message: this.$t("codeLenRule"),
          },
        ],
      },
      accessToken: "",
      showuserName: "", //右上角名字
    };
  },
  methods: {
    // 回到首页
    gohome() {
      // console.log("admin gohome ");
      this.$router.push({ path: "/" });
      this.$store.commit("ChangeAccesstoken", {
        accessToken: this.$store.state.accessToken.accessToken,
        nsname: this.$store.state.accessToken.nsname,
        nspass: this.$store.state.accessToken.nspass,
        gohome: true,
      });
    },
    //获取云存储Token
    getCloudToken() {
      const value = {
        account: this.$store.state.userInfo.uname,
        pass: this.$store.state.userInfo.upass,
      };
      console.log("getLoginToken");
      this.$http.post("/transfer/getLoginToken", value).then((res) => {
        if (res) {
          if (
            res.accessToken != undefined &&
            res.accessToken != null &&
            res.accessToken.length > 0
          ) {
            this.accessToken = res.accessToken;
          } else {
            this.$message.destroy();
            this.$message.error(this.$t("accessTokenfail"));
          }
        } else {
          this.$message.destroy();
          this.$message.error(this.$t("accessTokenfail"));
        }
      });
    },
    //跳转到云存储界面
    toCloudBuy(uuid) {
      let appKey = "3620c5b0249341058d270c2bb62047f7";
      this.getCloudToken();
      window.open(
        `https://boss22-api.xmcsrv.net/index?authorization=${this.accessToken}&appKey=${appKey}&sn=${uuid}&channel=`
      );
    },
    toCloudBuyvip() {
      let appKey = "3620c5b0249341058d270c2bb62047f7";
      this.getUseMessage(appKey);
    },
    // 获取用户信息
    getUseMessage(appKey) {
      const value = {
        Authorization: this.$store.state.accessToken.accessToken,
        name: this.$store.state.userInfo.uname,
        password: this.$store.state.userInfo.upass,
      };
      this.$http.post("/transfer/userinfo2/v1", value).then((res) => {
        if (res) {
          if (res.code != undefined && res.code != null && res.code == 2000) {
            this.sn = res.data.userId;
            window.open(
              `https://boss22-api.xmcsrv.net/index?authorization=${this.accessToken}&appKey=${appKey}&sn=${this.sn}&channel=`
            );
          } else {
            this.$message.destroy();
            this.$message.error(this.$t("" + showmessage(res)));
          }
        } else {
          this.$message.destroy();
          this.$message.error(this.$t("userinfo2faile"));
        }
      });
    },
    // 获取用户信息
    getUseMessageR() {
      const value = {
        Authorization: this.$store.state.accessToken.accessToken,
        name: this.$store.state.userInfo.uname,
        password: this.$store.state.userInfo.upass,
      };
      this.$http.post("/transfer/userinfo2/v1", value).then((res) => {
        if (res) {
          if (res.code != undefined && res.code != null && res.code == 2000) {
            this.showuserName = res.data.username;
            //将时间戳格式转换成年月日时分秒
            // document.title =
            //   "jftech-" +
            //   res.data.username +
            //   "-" +
            //   this.$store.state.userInfo.strDate; //网站title修改
      document.title = "MyEye" + "-" + this.$store.state.userInfo.strDate; //网站title修改

          } else {
            this.$message.destroy();
            this.$message.error(this.$t("" + showmessage(res)));
          }
        } else {
          this.$message.destroy();
          this.$message.error(this.$t("userinfo2faile"));
        }
      });
    },
    //侧边栏拖动
    /* CreateImageHttp(port, t) {
      let n = new Image();
      n.crossOrigin = "anonymous";
      (n.onload = () => {
        t.success && t.success(port);
      }),
        (n.onerror = () => {
          t.error && t.error();
        }),
        (n.onabort = () => {
          t.abort && t.abort();
        });
      let i = "http://127.0.0.1:" + port + "/Cfg/tip.png?update=" + t.timeStamp;
      n.src = i;
    },*/

    CheckPluginVersionExist(port, b) {
      var req = { MainType: 1, SubType: 0, WebStyle: "Xmeye" };
      this.$http
        .post("http://127.0.0.1:" + port + "/Cmd-WebLocalCtrl", req)
        .then((res) => {
          if (res == undefined) {
            b(-1);
          } else {
            b(port);
          }
        });
    },
    detectPort(s, e, callback) {
      let o = [];
      for (let a = s; a <= e; a++) o.push(a);
      // let u = new Date().getTime();
      let g = 0;
      let l = (t) => {
        setTimeout(() => {
          this.CheckPluginVersionExist(o[t], function (e) {
            if (e == -1) {
              g++;
            } else {
              callback(e);
            }
            if (g == o.length) {
              callback(-1);
            }
          });
          /*this.CreateImageHttp(o[t], {
            timeStamp: u + t,
            success: (g) => {
              callback(g);
            },
            error: () => {
              s++;
              if (o.length === s) {
                callback(-1);
              }
            },
          });*/
        }, 100);
      };
      for (let i = 0; i < o.length; i++) {
        l(i);
      }
    },

    // 提交密码修改
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const send = {
            newPass: encrypt(values.newPass),
            confirmPass: encrypt(values.confirmPass),
            // upass: this.$store.state.userInfo.upass,
            upass:encrypt(values.oldPass),
            uname: this.$store.state.userInfo.uname,
          };

          this.$http
            .post("/transfer/usercp/v1", send, {
              headers: {
                "Access-Token": localStorage.getItem("XM-Token"),
              },
            })
            .then((res) => {
              if (res) {
                if (res.code == 2000) {
                  this.visible = false;
                  this.form.resetFields();
                  this.$message.destroy();
                  this.$message.success(this.$t("changePasswordSuccess"));
                  this.$store.commit("SaveUserInfo", {
                    uname: encrypt(this.userName),
                    upass: encrypt(values.newPass),
                  });
                } else {
                  this.$message.destroy();
                  this.$message.error(this.$t("" + showmessage(res)));
                }
                if (typeof hidePluginCallback == "function") {
                  hidePluginCallback(false);
                }
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("getmailfail"));
              }
            });
        }
      });
    },
    // 表单验证
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    // 表单验证
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("newPass")) {
        callback(this.$t(`confirmTip`));
      } else {
        callback();
      }
    },
    // 表单验证
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },

    showModal() {
      this.visible = true;
      if (typeof hidePluginCallback == "function") {
        hidePluginCallback(true);
      }
    },
    showUseModal() {
      this.$refs.userInfo.showUserInfo();

      if (typeof hidePluginCallback == "function") {
        hidePluginCallback(true);
      }
    },

    handleCancel() {
      this.visible = false;
      if (typeof hidePluginCallback == "function") {
        hidePluginCallback(false);
      }
      this.form.resetFields();
    },
    verificationFunction() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.cancelAccount(this.formInline.CancelAccountCode);
        }
      });
    },
    hideModal() {
      if (typeof hidePluginCallback == "function") {
        hidePluginCallback(false);
      }
    },
    /**
     * @description 进入函数
     */
    beginCancelAccount() {
      if (typeof hidePluginCallback == "function") {
        hidePluginCallback(true);
      }
      var that = this;
      this.$confirm({
        title: this.$t("CancelAccountTitle"),
        onOk() {
          that.cancelAccount();
        },
        onCancel() {
          if (typeof hidePluginCallback == "function") {
            hidePluginCallback(false);
          }
        },
      });
    },
    /**
     * @description  注销账户  当用户账号与手机号相关联时弹出输入验证码框 ， 当没有关联时直接注销
     */
    cancelAccount(code) {
      const value = {
        uname: this.$store.state.userInfo.uname,
        upass: this.$store.state.userInfo.upass,
        language: selectlan(),
        code: code,
      };
      this.$http
        .post("/transfer/userecancel/v1", value, {
          headers: {
            "Access-Token": localStorage.getItem("XM-Token"),
          },
        })
        .then((res) => {
          if (res.code === 4302) {
            this.isCancelAccount = true;
            this.$message.success(this.$t(`sendSuccess`));
          } else if (res.code == 2000) {
            this.$message.success(this.$t(`CancelSuccess`));
            this.showConfirm();
          } else if (res.code === 4402) {
            this.$message.error(this.$t(`mailSendMuch`));
            if (typeof hidePluginCallback == "function") {
              hidePluginCallback(false);
            }
          } else if (res.code === 4010) {
            this.$message.error(this.$t(`codeError`));
          } else {
            this.$message.error(this.$t("" + showmessage(res)));
          }
        });
    },

    // 路由跳转
    changeMenu(route) {
      this.$router.push(route);
      sessionStorage.setItem("index", route);
      this.$store.commit("videoInfo", {
        uuid: "",
      });
    },
    // 提交修改密码表单 验证成功

    // 退出界面
    showConfirm() {
      var that = this;
      that.$router.push({ path: "/" });
      sessionStorage.removeItem("index");
      sessionStorage.removeItem("userInfo");
      sessionStorage.removeItem("accessToken");
      localStorage.removeItem("XM-Token");
      this.$store.commit("ChangeAccesstoken", { accessToken: null });
      this.$store.commit("videoInfo", {
        uuid: "",
      });
      document.title = "MyEye";
    },

    /**
     * 获取设备列表
     */
    getDeviceList() {
      this.equipList = [];
      const send = {
        uname: this.$store.state.userInfo.uname,
        upass: this.$store.state.userInfo.upass,
      };
      console.log("getDeviceList");
      this.$http
        .post("/transfer/mdlistCode/v1", send, {
          headers: {
            "Access-Token": localStorage.getItem("XM-Token"),
          },
        })
        .then((res) => {
          if (res) {
            if (res.code == 2000) {
              this.equipList = res.data.data || [];
              // 如果列表为空则不继续查询相关的设备状态
              if (this.equipList.length == 0) {
                return 
              }
              this.fliterUuid();
              this.getCloudStatus();
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("" + showmessage(res)));
            }
          } else {
            this.$message.destroy();
            this.$message.error(this.$t("getdevicefail"));
          }
        });
    },
    //循环uuid 获取是否有云存储
    getCloudStatus() {
      let that = this;
      let uuidlist = [];
      async function getData() {
        that.equipList.forEach((item) => {
          uuidlist.push(item.uuid);
        });
        return uuidlist;
      }
      getData().then((result) => {
        const params = {
          ver: 2,
          sn: result,
          caps: ["xmc.css", "net.cellular", "net.dat"],
        };
        that.$http
          .post("/caps/status", params, {
            headers: {
              "Access-Token": localStorage.getItem("XM-Token"),
            },
          })
          .then((res) => {
            if (res) {
              if (res.code === 200) {
                let temres = res.data.capsData;
                if (
                  that.equipList != undefined &&
                  that.equipList != null &&
                  that.equipList.length > 0
                ) {
                  that.equipList.forEach((item) => {
                    if (
                      temres != undefined &&
                      temres != null &&
                      temres.length > 0
                    ) {
                      temres.forEach((ele) => {
                        if (item.uuid == ele.sn) {
                          that.$set(
                            item,
                            "cssStatus",
                            ele.cssNetVo.cssStatus || false
                          );
                          that.$set(item, "celStatus", ele.celVo.celStatus);
                        }
                      });
                    }
                  });
                }
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("getdevicestatusfail"));
            }
          });
      });
    },
    //过滤出设备uuid 用于查询设备状态
    fliterUuid() {
      let list = this.equipList;
      if(this.equipList.length==0){
        return
      }
      console.log("filteruuid", list);
      let that = this;
      async function getData() {
        that.uuidlist = list.map((t) => {
          let obj = {};
          obj.uuid = t.uuid;
          return obj;
        });
        return that.uuidlist;
      }
      getData().then((result) => {
        console.log(result, "promise fliterUuid   result");
        that.getOnlineEquip(result);
      });
    },
    //查询设备状态并将其进行分组
    getOnlineEquip(uuidlist) {
      console.log("admin getOnlineEquip ", uuidlist);
      this.$http
        .post("/transfer/devstatus/v1", uuidlist, {
          headers: {
            "Access-Token": localStorage.getItem("XM-Token"),
          },
        })
        .then((res) => {
          if (res) {
            if (res.code == 2000) {
              this.equipListoffline = [];
              res.data.map((item) => {
                for (let i = 0; i < this.equipList.length; i++) {
                  if (this.equipList[i].uuid == item.uuid) {
                    if (item.status == "online") {
                      this.equipList[i].status = true;
                      this.equipList[i]["wakeUpStatus"] = item.wakeUpStatus;
                      this.equipListonline.push(this.equipList[i]);
                    } else if (item.status == "notfound") {
                      this.equipList[i].status = false;
                      this.equipListoffline.push(this.equipList[i]);
                    }
                  }
                }
              });
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("" + showmessage(res)));
            }
          } else {
            this.$message.destroy();
            this.$message.error(this.$t("getdevicestatusfail"));
          }
        });
    },
    /**
     *自动刷新
     */
    autoRefresh() {
      // 节流
      let currentTime = Date.now();
      if (currentTime - this.timeid > 3000) {
        this.timeid = currentTime;
        console.log("autorefresh go");
      } else {
        console.log("autorefresh return");
        return;
      }
      this.showEquipList = [];
      this.loading = false;
      this.getDeviceList();
      this.equipListonline = [];
      setTimeout(() => {
        this.fliterUuid(this.equipListoffline);
        (this.online = this.equipListonline.length),
          (this.offline = this.equipListoffline.length),
          (this.showEquipList = this.equipListonline.concat(
            this.equipListoffline
          ));
        this.loading = true;
        console.log("autorefresh data this.showEquipList", this.showEquipList);
      }, 3000);
    },
    getElePosition(ele) {
      let offset = ele.getBoundingClientRect();
      return { left: offset.left, top: offset.top };
    },
    //点击后将获取到的设备信息传递到播放页面
    getMac(username, password, uuid, status, devToken, tcsToken, event, index) {
      this.active = index;
      const positionInfo = {};
      positionInfo.screenX = window.screenX || window.screenLeft;
      positionInfo.screenY = window.screenY || window.screenTop;
      positionInfo.MousePos = {};
      positionInfo.MousePos.clientX = event.clientX;
      positionInfo.MousePos.clientY = event.clientY;
      positionInfo.MousePos.screenX = event.screenX;
      positionInfo.MousePos.screenY = event.screenY;
      positionInfo.browserScalingRadio =
        window.devicePixelRatio ||
        window.screen.deviceXDPI / window.screen.logicalXDPI;
      this.$router.push({ name: "MyEquip" });
      this.current = ["/myequip"];
      sessionStorage.setItem("index", this.current);
      if (this.pluginPort > 0) {
        let req = {
          MainType: 31,
          SubType: 0,
          BrowseType: this.browser(),
          Title: document.title,
        };
        this.$http
          .post(
            "http://127.0.0.1:" + this.pluginPort + "/Cmd-WebLocalCtrl",
            req
          )
          .then((res) => {
            if (res.Ret != 100) {
              this.$message.destroy();
              this.$message.warning(this.$t("embedBrowserFail"));
            } else {
              setTimeout(() => {
                this.username = username;
                this.password = password;
                this.uuid = uuid;
                this.devToken = devToken;
                this.tcsToken = tcsToken;
                this.title = document.title;
                if (status == false) {
                  this.$message.destroy();
                  this.$message.warning(this.$t("equipOffline"));
                } else {
                  console.log(this.browser(),'this.browser()')
                  if (this.browser() !== 0) { //对于ie的限制
                    window.videoInfo = () => {
                      return {
                        username: this.username,
                        password: this.password,
                        mac: this.uuid,
                        devToken: this.devToken,
                        tcsToken: this.tcsToken,
                        title: this.title,
                        offset: this.getElePosition(
                          document.querySelector(".playBox")
                        ),
                        pluginPort: this.pluginPort,
                        EmbedRet: res.Ret,
                        lang: selectUnitLang(),
                        setUpdatePostionInfoCallback: (callback) => {
                          updatePostionInfoCallback = callback;
                        },
                        positionInfo: positionInfo,
                        setOnVisibilityCallback: (callback) => {
                          onVisibilityCallback = callback;
                        },
                        pageHide: g_bPageHide,
                        setHidePluginCallback: (callback) => {
                          hidePluginCallback = callback;
                        },
                      };
                    };
                    this.$store.commit("videoInfo", {
                      uuid: this.uuid,
                    });
                  } else {
                    this.$message.destroy();
                    this.$message.warning(this.$t("BrowserSupport"));
                  }
                }
              }, 10);
            }
          });
      } else {
        this.$message.destroy();
        this.$message.warning(this.$t("noPlugin"));
        // this.Download()
      }
    },
    // 判断浏览器类型
    browser() {
      let userAgent = navigator.userAgent.toLowerCase(); //取得浏览器的userAgent字符串
      console.log(userAgent,'userAgent')
      if (userAgent.indexOf("metasr") > -1) {
        //判断是否搜狗浏览器
        return BrowseType.BrowseSougou;
      }
      if (userAgent.indexOf("opera") > -1) {
        //判断是否Opera浏览器
        return BrowseType.BrowseOpera;
      }
      if (userAgent.indexOf("firefox") > -1) {
        //判断是否Firefox浏览器
        return BrowseType.BrowseFirefox;
      }

      if (userAgent.indexOf("chrome") > -1) {
        return BrowseType.BrowseChrome;
      }
      if (userAgent.indexOf("safari") > -1) {
        //判断是否Safari浏览器
        return BrowseType.BrowseSafari;
      }
      if (!!window.ActiveXObject || "ActiveXObject" in window) {
        console.log('IE')
        return BrowseType.BrowseMSIE;
      }
      if (userAgent.indexOf("edge") > 0) {
        return BrowseType.BrowseEdge;
      }
    },
    Download() {
      window.open("/download/XmeyeVideoPlayToolSetup.exe");
    },
  },
  watch: {
    $route(to, from) {
      this.getCloudToken();
      console.log("@@@@@@@@@@@@@@@@", to, from);
      if (to.name === "MyList") {
        this.active = -1;
      }
    },
    equipList:{
      handler(newval, oldval) { //这是vue的规定写法，当你watch的值发生变化的时候，就会触发这个handler，这是vue内部帮你做的事情
        console.log(newval, oldval)
        if(this.equipList.length>0){
          console.log('watch equiplist 长度大于零',this.equipList)
          console.log('长度大于零jjjjjjjjjjj')
          if(this.showEquipList.length==0){
            console.log('watch equiplist 长度大于零11111',this.equipList)
            this.getCloudToken();
            this.getUseMessageR();
            this.autoRefresh();
          }
          
        }else{
          console.log('watch equiplist 长度小于零',this.equipList)
        }
      },
      deep: true,  // 可以深度检测到 obj 对象的属性值的变化
      immediate: true //刷新加载  立马触发一次handler
    }
  },
  mounted() {
    this.getCloudToken();
    this.getUseMessageR();
    this.autoRefresh();
  },
  computed: {
    userName: function () {
      return decrypt(this.$store.state.userInfo.uname);
    },
  },
  provide() {
    return {
      autoRefresh: this.autoRefresh,
    };
  },
};
</script>
<style scoped>
body,
html {
  -ms-overflow-style: none;
  /* overflow: -moz-hidden-unscrollable; */
  /* scrollbar-width: none; */
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
}
.adminbox {
  width: 100vw;
  height: 100vh;
  text-align: center;
}
.tips {
  display: block;
  font-size: 13px;
  max-width: 185px;
}
ul {
  padding: 0;
  margin: 0;
}
li::marker {
  color: #fff;
}
.aaa {
  background-color: #409eff;
  color: red;
}

/* asdasdasdas */
.item {
  float: right;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 64px;
}
.ant-layout-header {
  display: flex;
  justify-content: space-between;
  height: 64px;
  background-color: #fff;
  box-shadow: 0 0 2px 10px #000;
}
.changetitle {
  text-align: center;
}
.ant-menu {
  background-color: transparent !important;
  color: #414141;
}
.menu {
  display: inline-block;
  min-height: 60px;
  float: left;
}

.header-right {
  float: right;
  /* margin-right: 3vw; */
}
.header-right > div {
  float: left;
}
.language {
  margin-left: -1vw;
  display: none;
}
.avator a {
  margin-left: 10px;
  color: #414141;
  /* font-size: 20px; */
  font-size: 16px;
}
.loginOut {
  /* font-size: 20px; */
  color: #414141;
  cursor: pointer;
  margin-right: -4vw;
  margin-left: 1vw;
  font-size: 16px;
}
.download {
  margin-right: 30px;
}
.download span {
  color: #414141;
  /* font-size: 20px; */
  font-size: 16px;
}
/* .menu>>> */
h1 {
  float: left;
}
.slider-box {
  float: left;
}
.ant-menu {
  /* font-size: 20px; */
  font-size: 16px;
  color: #414141;
}
.ant-menu-item-selected {
  /* color: #fff; */
  height: 100%;
}

/* .title /deep/ .ant-tooltip-placement-right {
  left: 170px !important;
  z-index: 999999;
} */
.ant-menu-horizontal > .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.205);
  border-bottom: none;
  height: 100%;
}

.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.342);
  border-bottom: none;
}
.ant-menu-horizontal > .ant-menu-item:visited {
  background-color: rgba(255, 255, 255, 0.342);
  border-bottom: none;
}

.ant-menu-horizontal {
  height: 100%;
}

.name:hover {
  color: #fffffe;
}
.ant-menu-horizontal {
  border-bottom: none;
}
.ant-menu-horizontal > .ant-menu-item {
  border-bottom: none;
  height: 100%;
  padding-top: 2.5%;
}

.ant-dropdown-open {
  color: #dedede;
}
.dragzindex {
  position: absolute;
  z-index: 999999;
}
/* dsadasdasd */

/* dasdasdasdas */
.ant-layout-sider {
  width: 12vw;
  height: calc(100vh - 60px);
  background-color: #f2f2f2;
  overflow: auto;
  position: relative;
  border-right: 1px solid #dedede;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.contanerBox {
  position: relative;
  height: 100%;
  width: 100%;
}

/*滚动条的宽度*/

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  display: none;
}

/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/

::-webkit-scrollbar-track {
  width: 6px;
  background-color: #ffffff;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  display: none;
}
/*滚动条的设置*/
::-webkit-scrollbar-thumb {
  background-color: #02020254;
  background-clip: padding-box;
  height: 15px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  display: none;
}
/*滚动条移上去的背景*/

::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}
.button-box {
  position: fixed;
  padding: 5px 20px;
  background-color: #edebe9;
  z-index: 10;
  width: 199px;
  height: 8vh;
}
.button {
  width: 160px;
  margin: 10px auto;
  z-index: 10;
}
.statue-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  padding: 4px 0;
  background-color: #ffffff;
  margin: 4px 0;
}

.videolist > li {
  background-color: white;
  /* padding: 15px 2px; */
  height: 45px;
  margin: 4px 0;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
}
.videolist > li:first-child {
  margin-top: 0;
}

.videolist .active {
  background-image: linear-gradient(
    to right,
    #00c8ff42 0%,
    #0073ff9f 51%,
    #0073ff5b 99%,
    #409eff 100%
  ) !important;
}

.videolist .active:hover {
  background-position: right center !important; /* change the direction of the change here */
}

.videolist > li:hover {
  background-color: rgba(255, 255, 255, 0.589);
}
.videolist > li:active {
  background-color: rgba(214, 211, 211, 0.589);
}
.videolist > li:visited {
  background-color: rgba(214, 211, 211, 0.589);
}
.videolist > li::marker {
  text-align: left;
}
.videolist .camera {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 25px;
}

.title {
  width: 92px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  top: 36%;
  left: 22%;
  font-size: 12px;
  color: #707070;
  text-align: left;
}
.title > span {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: block;
}
.online {
  color: #409eff;
}
#orange {
  color: orange;
}
.offline {
  color: #929292;
}
.statue {
  position: absolute;
  top: 35%;
  right: 10px;
  font-size: 10px;
}

.viewbox {
  width: 95%;
  height: 90vh\0;
}
.cloud-icon-box1 {
  padding: 5px;
  position: absolute;
  right: 21%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 1px solid #dededede;
  transition: all 0.6s ease;
}
.cloud-icon-box1:hover {
  background-color: #dadada;
}
.cloud-icon-box1 img {
  width: 20px;
  height: 20px;
}
.cloud-icon-box {
  padding: 5px;
  position: absolute;
  right: 4%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 1px solid #dededede;
  transition: all 0.6s ease;
}
.cloud-icon-box:hover {
  background-color: #dadada;
}
.cloud-icon-box img {
  width: 20px;
  height: 20px;
}
.statue1 {
  color: #409eff;
}
.ant-tag {
  border: none;
  background: none;
}
.ant-dropdown-trigger {
  margin-left: 0 !important;
}
</style>
