export const selectlan = () => {
  const lan = sessionStorage.getItem("languagevalue")
    ? sessionStorage.getItem("languagevalue")
    : "zh";
  if (lan == "zh") {
    return 1;
  } else if (lan == "zh_TW") {
    return 1;
  } else if (lan == "en") {
    return 2;
  } else if (lan == "de_US") {
    return 4;
  } else if (lan == "fr_US") {
    return 5;
  } else if (lan == "cs_US") {
    return 7;
  } else if (lan == "es_US") {
    return 8;
  } else if (lan == "pt_BR") {
    return 9;
  } else if (lan == "it_US") {
    return 11;
  } else if (lan == "ko_KR") {
    return 2;
  } else if (lan == "ru_CN") {
    return 2;
  } else if (lan == "ja") {
    return 2;
  } else if (lan == "pt") {
    return 9;
  }
};

export const selectUnitLang = () => {
  const lan = sessionStorage.getItem("languagevalue")
    ? sessionStorage.getItem("languagevalue")
    : "zh";
  if (lan == "zh") {
    return 101;
  } else if (lan == "zh_TW") {
    return 102;
  } else if (lan == "en") {
    return 100;
  } else if (lan == "de_US") {
    return 108;
  } else if (lan == "fr_US") {
    return 107;
  } else if (lan == "cs_US") {
    return 189;
  } else if (lan == "es_US") {
    return 104;
  } else if (lan == "pt_BR") {
    return 109;
  } else if (lan == "it_US") {
    return 103;
  } else if (lan == "ko_KR") {
    return 116;
  } else if (lan == "ru_CN") {
    return 106;
  } else if (lan == "ja") {
    return 105;
  } else if (lan == "pt") {
    return 109;
  }
};
