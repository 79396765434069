module.exports = {
  //2022-6 新增
  cloudinfo: {
    normal: "uso normal",
    timeOut: "venció",
    nonactivated: "no activado",
    notSupport: "no apoyo",
    noRemainingFlow: "Sin flujo restante",
    VipStatus: "estatus VIP",
    totalFlow: "Todo el tráfico",
    residualFlow: "flujo residual",
    expireDate: "Fecha de caducidad"
  },
  //新增
  cloud: "almacenamiento en la nube",
  cloudTimeOut: "Almacenamiento en la nube (caducado)",
  cloudNormal: "Almacenamiento en la nube (en uso)",
  cloudNonActivated: "Almacenamiento en la nube (no abierto)",
  cloudnotSupported:"Almacenamiento en la nube (no proporcionado)",
  vipTimeOut: "Tráfico 4G (caducado)",
  vipNormal: "Tráfico 4G (en uso)",
  vipNonActivated: "Tráfico 4G (no abierto)",
  vipnotSupported:"Tráfico 4G (no proporcionado)",
  // 西班牙语
  // 登录页
  userLogin: "Login usuario", //用户登录
  equipLogin: "Login del dispositivo", //设备登录
  login: "Iniciar sesión", //登录
  regist: "Inscríbete ahora", //立即注册
  findPassword: "Olvida la contraseña", //找回密码
  userRule: "Por favor, introduzca el nombre de usuario", //请输入用户名
  passwordRule: "Por favor introduzca la contraseña", //请输入密码
  codeRule: "Por favor ingrese el código de verificación", //请输入验证码
  equipIdRule: "Por favor, introduzca el número de serie del dispositivo", //请输入设备序列号
  appDownload: "APP Descarga", //App 下载
  webUnitDownload: "ActiveX Descarga", //Web控件下载
  callUs: "Contáctenos", //联系我们
  officialEdition: "Classic Edition", //经典版
  proEdition: "PRO Edition", //PRO版
  codeLenRule: "Ingrese el código de verificación de 4 dígitos", //请输入4位验证码
  notEmpty: "No entre en espacios", //请勿输入空格

  // 注册
  userRegist: "Crear Cuenta", //创建账户
  emailRule: "por favor ingrese su correo electrónico", //请输入邮箱
  emailtrue: "Por favor, introduzca el número de buzón en el formato correcto", //请输入正确格式的邮箱号
  phonetrue:"Introduzca el número de teléfono en el formato correcto",//请输入正确的手机号码
  confirmPasswordRule: "Por favor, introduzca la contraseña de nuevo", //请再次输入密码
  hadCount: "Ya tengo una cuenta", //已有账号，立即登录
  registCount: "Registrar", //注册
  hadUser: "Su nombre de usuario registrado ya existe", //您注册的用户名已存在
  hadMail: "Su correo electrónico registrado ya existe", //您注册的邮箱已存在
  sendSuccess: "El correo enviado con éxito", //邮件发送成功
  mailFormatError: "Error de formato de correo electrónico, verifique", //邮箱格式错误，请检查
  codeError: "Error de código de verificación", //验证码错误
  registerSuccess: "Registración exitosa", //注册成功
  mailNotFind:
    "La dirección de correo electrónico que ha introducido no existe, compruebe", //您输入的邮箱不存在，请检查
  userNameLen: "Ingrese un nombre de usuario de 4 a 8 caracteres", //请输入4~8位用户名
  userPwdLen: "Ingrese una contraseña de 8 ~ 32 dígitos", //请输入8~32位密码

  // 找回密码
  findThePassword: "Olvida la contraseña", //找回密码
  passwordText:
    "Ingrese la dirección de correo electrónico utilizada al registrar la cuenta para recuperar la contraseña", //请输入注册账号时使用的邮箱来找回密码
  exit: "Cerrar sesión", //退出
  nextStep: "Próximo paso", //下一步
  reset: "Reiniciar", //重新设置
  getCode: "Enviar", //获取验证码
  someTime: "Enviar de nuevo", //秒后重新获取
  ok: "confirmar", //确定
  mailSendMuch: "El correo solo se puede enviar tres veces al día por buzón", //邮件每个邮箱一天只能发送三次
  mailChangeSuccess: "Modificado con éxito", //修改成功
  pwdError: "Formato de contraseña incorrecto", //密码格式不正确
  codeTimeout: "Código de verificación expirado", //验证码过期
  codeErrorMuch: "Demasiados errores, por favor intente de nuevo más tarde", //错误次数过多,请稍后再试
  backLogin: "Atrás para iniciar sesión", //返回登录
  mailFast:
    "No se permiten correos electrónicos repetitivos en un período corto de tiempo.", //短时间内不允许重复发送邮件

  //主页面
  refresh: "Refrescar", //刷新
  myDevice: "Mi dispositivo", //我的设备
  personalInformation: "Informacion personal", //个人信息
  deviceManagement: "Gestion dispositivos", //设备管理
  VIPTraffic:"Tráfico VIP",//VIP流量
  changePassword: "Cambiar contraseña", //修改密码
  changePasswordSuccess: "Con éxito el cambio de contraseña", //修改密码成功
  LogOut: "Cerrar sesión", //退出登录
  shurLogOut: "¿Estás seguro de que quieres cerrar la sesión?", //你确定要退出吗
  oldPassword: "Contraseña anterior", //旧密码
  newPassword: "Nueva contraseña", //新密码
  inputNewPassword: "Ingrese la nueva contraseña nuevamente", //再次输入新密码
  errorRefresh: "Actualización fallida", //刷新失败
  online: "En línea", //在线
  offline: "no en línea", //离线
  UidLength:
    "Ingrese un número de serie del dispositivo de 16 o 20 dígitos que consta de letras mayúsculas y minúsculas y números", //请输入16或20位由大,小写字母,数字组成的设备序列号
    PwordLength: "Introduzca una contraseña de dispositivo de no más de 100 dígitos compuesta por letras grandes, minúsculas y números",
    passwordrule: "No ingrese símbolos especiales", //请勿输入特殊符号
  equipOffline:
    "El dispositivo está temporalmente fuera de línea, elija otro dispositivo", //设备暂时不在线请选择其它设备吧
  BrowserSupport:
    "Este navegador no es compatible con la reproducción de complementos, use Google Chrome", //此浏览器不支持插件播放，请使用IE或360浏览器
  embedBrowserFail:
    "No se pudo vincular el navegador, no cambie de página rápidamente al iniciar sesión", //绑定浏览器失败，请在登录时勿快速切换页面
  noPlugin: "Complemento no detectado", //未检测到插件
  CancelAccount: "Cancelar cuenta",
  CancelAccountTitle: "¿Está seguro de que quiere eliminar la cuenta?",
  CancelSuccess: "Cancelar la cuenta con éxito",

  // 设备列表、
  order: "Número", //序号
  equipmentPwd: "Contraseña del dispositivo", //设备密码
  equipmentOrder: "Número de serie", //序列号
  equipmentName: "Nombre dispositivo", //设备名称
  equipmentUser: "Nombre de usuario", //设备用户名
  option: "Operaciones", //操作
  addEquipment: "Añadir dispositivo", //添加设备
  changeEquipmentMessage: "Modificar la información del dispositivo", //修改设备信息
  changePasswordMessage:"Modificar la información de la contraseña",//修改密码信息
  deleteEquipment:"Eliminar dispositivos",//删除设备
  delmessage: "Estas seguro que quieres borrarlo", //你确定要删除吗
  all: "En total", //共
  tips: "datos", //条数据
  add: "añadir", //添加
  equipmentSuccess: "información dispositivo modificado con éxito", //设备信息修改成功
  addUid:
    "Por favor, introduzca el número de serie del dispositivo que desea añadir", //请输入你要添加的设备序列号
  addid: "Por favor, introduzca el número de serie que desea añadir", //请输入你要添加的序号
  addname: "Por favor, introduzca el nombre del dispositivo que desea añadir", //请输入你要添加的设备名
  addUsername:
    "Por favor, introduzca el nombre de usuario del dispositivo que desea añadir", //请输入你要添加的设备用户名

  // 用户信息
  userinfo: "Información de usuario", //用户信息
  userName: "Nombre de usuario", //用户名
  usercontact: "Número de teléfono", //联系方式
  useremail: "Email", //电子邮箱

  // 全局提示
  successLogin: "Inicio de sesión correcto", //登录成功
  errorLogin1:
    "No se pudo iniciar sesión, el nombre de usuario o la contraseña son incorrectos", //登录失败，用户名或密码错误
  errorLogin2: "Error de inicio de sesión, error de código de verificación", //登录失败，验证码错误
  errorLogin3: "No se pudo iniciar sesión, el código de verificación caducó", //登录失败，验证码过期
  errorLogin4:
    "Demasiados errores de entrada, cuenta bloqueada durante 10 minutos", //登录失败次数过多，账户锁定10分钟
  delsucess: "Eliminado con éxito", //删除成功
  addSuccess: "Agregado exitosamente", //添加成功
  addFail: "Añadir fallado", //添加失败
  timeOut:
    "Tiempo de espera de inicio de sesión, salta automáticamente a la página de inicio de sesión", //登录超时，自动跳转登录页
  changeEquipmentPwdError:
    "La contraseña original del dispositivo se ingresó incorrectamente", //原设备密码输入错误

  // 网络提示
  accessnot: "Acceso denegado", //访问被拒绝
  resourcenot: "Recurso no disponible", //资源不可用
  nopage: "Página no encontrada", //找不到页面
  methoderror: "Método no permitido", //不允许此方法
  serveerror: "Error del Servidor", //服务器错误
  unknownerror: "Error desconocido", //未知错误

  // 表单提示
  emailTip: "**@**.***",
  phoneTip:"Introduzca el número de teléfono correcto",
  confirmTip: "Por favor, ser coherente con la contraseña", //请与密码保持一致
  equipmentIdTip:
    "Ingrese el número de serie del dispositivo de 16 o 20 dígitos", //请输入16或20位设备序列号
  equipmentUserTip: "Ingrese el nombre de usuario del dispositivo", //请输入设备用户名
  equipmentPasswordTip1: "Ingrese la contraseña del dispositivo", //请输入设备密码
  equipmentPasswordTip:
    "Ingresa la contraseña del dispositivo, no es necesario que la ingreses si no tienes una", //请输入设备密码，没有则无需输入
  codeTip: "Ingrese el código de verificación de correo electrónico recibido", //请输入收到的邮箱验证码
  equipmentPwdTip:
    "La contraseña del dispositivo está en blanco de forma predeterminada", //设备密码默认为空
  equipmentNameTip: "Ingrese el nombre del dispositivo", //请输入设备名称
  equipmentUserNameTip:
    "El nombre de usuario del dispositivo predeterminado es ”admin“.", //设备用户名默认为“admin”
  pagetip1:
    "Haga clic en el dispositivo en ”Mi dispositivo“ a la izquierda de vista previa, reproducción, configurar y otras operaciones en el dispositivo", //单击左边“我的设备”中的设备，对设备进行预览，回放，配置等操作
  pagetip2:
    "Si es la primera vez que visita, descargue el plug - in, que puede tardar unos minutos, por favor espere pacientemente", //如果您是第一次访问，会自动下载插件，可能需要花费几分钟时间，请耐心等待
  passwordChangeTip: "Si no cambia la contraseña, déjela en blanco", //若不修改密码请保持为空
  loginUseNameTip:
    "Puede usar nombre de usuario, correo electrónico, teléfono móvil para iniciar sesión", //可使用用户名、邮箱号、手机号登录

  placeholderUser: "Nombre de usuario / correo electrónico / móvil", //用户名/邮箱号/手机号
  placeholderPassword: "Contraseña", //密码
  placeholderCode: "Código de verificación", //验证码
  placeholderEquipId: "Número de serie", //设备序列号
  placeholderEquipAdmin: "Nombre de usuario", //设备用户名
  placeholderEquipPassword: "Contraseña", //设备密码
  placeholderRegistUser: "Nombre de usuario", //用户名
  placeholderRegistMail: "Correo", //邮箱
  placeholderRegistPhone: "Teléfono móvil", //手机
  placeholderRegistCode: "Código de verificación", //验证码
  placeholderRegistPwd: "Contraseña", //密码
  placeholderRegistPwd2: "confirmar Contraseña", //确认密码
  privacyPolicy:"Leer y elegir la política de privacidad",//阅读并选择隐私政策
  supportTitle: "Formas de ponerse en contacto con el soporte técnico", //技术支持联系方式
  supportPhone: "Línea directa de servicio", //服务热线
  supportMail: "Buzón de servicio", //服务邮箱
  supportTime:
    "Horario de servicio: de lunes a sábado de 8: 30-17: 30 (excepto festivos legales)", //服务时间：周一到周六8:30--17:30（法定节假日除外）
  userPwdRule: "El formato de la contraseña es incorrecto", //密码格式错误
  userNameRule: "Formato de nombre de usuario incorrecto", //用户名格式错误
  Ok: "Confirmar", //确认
  cancel: "Cancelar", //取消
  backOld: "Versión antigua", //回到旧版

  cookie:
    "Nuestro sitio web utiliza cookies para mejorar nuestro sitio web y mejorar la experiencia del usuario. Si continúa navegando en este sitio web sin cambiar la configuración de cookies de su navegador, se considera que acepta nuestra política de uso de cookies.", //我们的网站使用cookies以改进我们的网站和改善用户体验。如您继续浏览本网站而未更改浏览器cookie设置，视为同意我们的cookies使用策略。
  cookieLink: "póliza", //政策
  agree: "Acordar", //同意
  notAgree: "Discrepar", //不同意

  cookiePolicy: "Política de cookies", //Cookie 政策
  cookieUse: "Cómo usamos las cookies y tecnologías similares", //我们如何使用Cookie和同类技术
  cookieWhy:
    "Para garantizar el funcionamiento normal del sitio web, almacenaremos pequeños archivos de datos denominados Cookies en su computadora o dispositivo móvil. Las cookies suelen contener identificadores, nombres de sitios y algunos números y caracteres. Con la ayuda de las cookies, los sitios web pueden almacenar datos como sus preferencias.", //为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据档。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。
  cookieFor:
    "No usaremos cookies para ningún otro propósito que no sea el indicado en esta política. Puede administrar o eliminar las cookies según sus preferencias. Para obtener más detalles, consulte AboutCookies.org. Puede borrar todas las cookies guardadas en su computadora y la mayoría de los navegadores web tienen una función para bloquear las cookies. Pero si hace esto, debe cambiar personalmente la configuración del usuario cada vez que visite nuestro sitio web. Para obtener más información sobre cómo cambiar la configuración del navegador, visite la página de configuración correspondiente del navegador que está utilizando. Sin embargo, tenga en cuenta que si no acepta las cookies, es posible que no pueda experimentar completamente algunas funciones del sitio web.", //我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。但请注意，如果您不接受cookie，可能无法全面体验网站的某些功能。
  cookieList:
    "A continuación, se muestra una lista de los tipos de cookies que utiliza el sitio web:", //以下是网站使用的cookie种类列表：
  cookiePurpose: "Propósito", //目的
  cookieDescribe: "describir", //描述
  cookieType: "Tipo y tiempo de caducidad", //种类和失效时间
  cookiePerformance: "Rendimiento (navegador de usuario)", //性能（用户浏览器）
  cookieLogin:
    "Registre el estado de inicio de sesión del usuario y no es necesario volver a iniciar sesión en 7 días", //记录用户登录状态，以及7天内不用重新登录
  cookieDay: "7 días", //7天
  cookieCnzz: "CNZZ", //CNZZ
  cookieTime: "Registre el número de veces que el usuario visita el sitio web.", //记录用户访问网站的次数
  cookieForever: "permanente", //永久
  cookieTrack:
    "Muchos navegadores web tienen una función No rastrear, que puede publicar solicitudes de No rastrear en sitios web. Actualmente, las principales organizaciones de estándares de Internet no han establecido políticas relevantes para regular cómo los sitios web deben responder a tales solicitudes. Pero si su navegador habilita No rastrear, nuestro sitio web respetará su elección.", //很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do NotTrack请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站都会尊重您的选择。
  backHome: "volver a la pagina de inicio", //回到首页
  // RS
  RS2000: 'Ejecución exitosa',//执行成功
  RS4000: 'Nombre de usuario o contraseña incorrecta',//用户名或密码错误
  RS4010: 'Error en el Código de verificación',//验证码错误
  RS4011: 'Contraseña inconsistente',//密码不一致
  RS4012: 'El nombre de usuario ha sido registrado',//用户名已被注册
  RS4013: 'El nombre de usuario está vacío',//用户名为空
  RS4014: 'La contraseña está vacía',//密码为空
  RS4015: 'Confirmar que la contraseña está vacía',//确认密码为空
  RS4016: 'El número de teléfono móvil está vacío',//手机号为空
  RS4017: 'El formato del nombre de usuario no es correcto',//用户名格式不正确
  RS4018: 'Formato de contraseña incorrecto',//密码格式不正确
  RS4019: 'Confirmar que el formato de la contraseña no es correcto',//确认密码格式不正确
  RS4020: 'El formato del número de teléfono móvil no es correcto',//手机号格式不正确
  RS4021: 'Número de teléfono móvil registrado',//手机号已注册
  RS4022: 'El número de teléfono móvil no está registrado',//手机号未注册
  RS4023: 'El buzón está registrado',//邮箱已注册
  RS4024: 'El buzón no está registrado',//邮箱未注册
  RS4025: 'El nombre de usuario no está registrado',//用户名未注册
  RS4026: 'Error en la contraseña original',//原密码错误
  RS4027: 'Falló la modificación de la contraseña',//修改密码失败
  RS4029: 'El ID de usuario está vacío',//用户ID为空
  RS4030: 'El Código de verificación está vacío',//验证码为空
  RS4031: 'El buzón está vacío',//邮箱为空
  RS4032: 'El formato del buzón no es correcto',//邮箱格式不正确
  RS4033: 'El formato del buzón no es correcto',//邮箱格式不正确
  RS4034: 'Wechat no está vinculado',//微信没有绑定
  RS4035: 'Falló la vinculación de Wechat',//绑定微信失败
  RS4036: 'Falló la vinculación del número de teléfono móvil',//绑定手机号失败
  RS4037: 'Falló la vinculación del buzón',//绑定邮箱失败
  RS4038: 'Enviar el Código de verificación más allá del número máximo de veces',//发送验证码超过最大次数
  RS4039: 'Falló el registro',//注册失败
  RS4040: 'Wechat tiene usuarios vinculados',//微信已绑定用户
  RS4041: 'No hay permisos para modificar el nombre de usuario (solo para los usuarios anónimos generados)',//没有权限修改用户名（仅针对生成的匿名用户修改）
  RS4042: 'User Not FB Los usuarios de Bind no están vinculados a Facebook',//USER_NOT_FB_BIND 用户没有绑定facebook
  RS4043: 'User Fail FB El usuario Bind falló al vincular facebook',//USER_FAIL_FB_BIND 用户绑定facebook失败
  RS4044: 'User Not Gg Los usuarios de Bind no están vinculados a Google',//USER_NOT_GG_BIND 用户没有google绑定
  RS4045: 'User Fail Gg El usuario Bind falló al vincular Google',//USER_FAIL_GG_BIND 用户绑定google失败
  RS4046: 'La cuenta de línea no está vinculada',//Line账户未绑定
  RS4047: 'Falló la vinculación de la cuenta de línea',//Line账户绑定失败
  RS4100: 'El dispositivo no se permite agregar ilegalmente',//设备非法不允许添加
  RS4101: 'El dispositivo ya existe',//设备已存在
  RS4102: 'Falló la eliminación del dispositivo',//删除设备失败
  RS4103: 'Modificación fallida',//修改失败
  RS4104: 'Los parámetros uuid del dispositivo son anormales',//设备uuid参数异常
  RS4105: 'El parámetro de nombre de usuario del dispositivo es Anormal',//设备用户名参数异常
  RS4106: 'Parámetros de contraseña anormales del dispositivo',//设备密码参数异常
  RS4116: 'Falló la transferencia de la cuenta principal del dispositivo a otro usuario, comprobando si el usuario tiene el dispositivo y que el usuario tiene los permisos de la cuenta principal del dispositivo',//将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限
  RS4117: 'No es la cuenta principal',//不是主账户
  RS4118: 'El usuario no tiene este dispositivo',//用户没有此设备
  RS4119: 'Añadir dispositivo no es único, se han añadido otras cuentas',//添加设备不唯一，其它账户已添加
  RS4120: 'Añadir límite máximo de dispositivos (100)',//添加设备最大限制(100个)
  RS4126: 'El token de soporte del dispositivo solo puede ser agregado por una cuenta',//设备支持令牌只有一个账户添加
  RS4127: 'Falta el Token del dispositivo',//缺少设备令牌
  RS4300: 'Falló el envío',//发送失败
  RS4302: 'Enviar el Código de verificación de cancelación con éxito',//发送注销验证码成功
  RS4400: 'La verificación de la interfaz de SMS falló, por favor póngase en contacto con nosotros.',//短信接口验证失败，请联系我们
  RS4401: 'Los parámetros de la interfaz de SMS son incorrectos, por favor póngase en contacto con nosotros',//短信接口参数错误，请联系我们
  RS4402: 'Cada número de teléfono solo puede enviar tres mensajes de texto al día',//每个手机号一天只能发送三次短信
  RS4403: 'El envío falló, por favor vuelva a intentarlo más tarde',//发送失败，请稍后再试
  RS4404: 'El envío es demasiado frecuente, por favor, intervalo 120 segundos',//发送太频繁了，请间隔120秒
  RS4405: 'Falló el envío',//发送失败
  RS5000: 'Fallo del servidor',//服务器故障
  RS5001: 'El certificado no existe',//证书不存在
  RS5002: 'Error en la información de la cabeza de la solicitud',//请求头信息错误
  RS5003: 'Caducidad del certificado',//证书失效
  RS5004: 'Error de verificación de clave de generación',//生成密钥校验错误
  RS5005: 'Parámetros anormales',//参数异常
  RS5009: 'Error de código de wechat, error de cifrado y descifrado AES',//微信code错误、AES加解密错误
  RS5010: 'Token ha expirado',//token 已过期
  RS5011: 'Error Token',//token 错误
  RS5012: 'Token no tiene permisos',//token 无权限
  RS5013: "Sin apoyo",//不支持
  RS5014: 'Las operaciones son demasiado frecuentes',//操作太频繁
  RS4050: 'Las operaciones son demasiado frecuentes',//操作太频繁
  phoneCodeTip: "Por favor, introduzca el Código de verificación del teléfono móvil obtenido",//======请输入获取的手机验证码
  phoneNotFind: "El número de teléfono móvil que introdujo no existe, por favor verifique",//=====您输入的手机号码不存在，请检查
  phoneFormatError: "El número de teléfono móvil está mal formado, por favor compruebe",//======手机号码格式错误，请检查
  phoneSendSuccess: "El Código de verificación se envió con éxito, por favor, preste atención a la verificación",//=======验证码发送成功请注意查收
  hadPhone: "Su número de teléfono móvil registrado ya existe", //======您的注册手机号码已存在
  phoneRule: "Por favor, introduzca el número de teléfono móvil",//======请输入手机号码

  useNameTip:"4 - 15 caracteres, que pueden ser en chino, letras, números, La composición de he - Li es de dos o más elementos", //4-15位字符，可由中文、数字、_和-组成
  passwordTip:"Longitud de caracteres de 8 - 32 dígitos, que puede estar compuesta por letras, números, subrayado y - dos o más elementos en inglés", //8-32位字符长度，由英文字母、数字、下划线和-组成
  NameLength: "Introduzca un nombre de dispositivo de no más de 255 dígitos compuesto por letras grandes, minúsculas, números y chino",
  UnameLength: "Introduzca un nombre de usuario de dispositivo de no más de 255 dígitos compuesto por letras grandes, minúsculas, números y chino",
  placeTip:"Por favor, introduzca el número de serie",
  deleteAll:"Eliminación por lotes",


  // nettimeout:"网络超时",
  accessTokenfail:"El Servicio se agotó y falló la obtención de accestoken",
  userinfo2faile:"El Servicio se agotó y la obtención de información del usuario falló.",
  getcodefail:"Tiempo de espera del servicio, falló la obtención del Código de verificación",
  getmailfail:"El Servicio se agotó y la modificación falló.",
  getdevicefail:"El Servicio se agotó y no se pudo obtener la lista de dispositivos",
  getareacodefail:"El Servicio se agotó y no se pudo obtener la lista de códigos de área",
  rulenamefail:"El Servicio se agotó y falló la verificación del nombre de usuario",
  rulephonefail:"El Servicio se agotó y falló la verificación del número de teléfono móvil del usuario.",
  ruleregisterfail:"El Servicio se agotó y el registro falló",
  ruleemailfail:"El Servicio se agotó y la verificación del buzón falló.",
  getdevicestatusfail:"El Servicio se agotó y no se pudo obtener el Estado del dispositivo",

  oldequipmentpassword:"Contraseña original",
  oldpasswordrule:"Introduzca la contraseña original del dispositivo, si no, no es necesario introducir",
  newequipmentpassword:"Nueva contraseña",
  newpasswordrule:"Introduzca la nueva contraseña del dispositivo, si no, no es necesario introducir",
  confirmequippassword:"Confirmar contraseña",
  confirmeqpasswordrule:"Por favor, sea consistente con la nueva contraseña",
};
