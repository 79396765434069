<template>
  <div class="videobox">
    <div class="playBox" v-if="show" style="z-index:0">
      <iframe
        :src="src"
        ref="iframe"
        height="100%"
        width="100%"
        frameborder="0"
      >
      </iframe>
    </div>
    <div class="tipBox" v-else>
      <span>{{ $t(`pagetip1`) }}<br />{{ $t(`pagetip2`) }} <div style="display: inline-block;width:20px;height: 20px;cursor: pointer;" @click="Download"> <img style="width: 20px" src="../assets/image/down.png" alt="" /></div></span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      src: ""
      // online:true
    };
  },
  mounted() {
    
  },

  computed: {
    uuid() {
      return this.$store.state.videoInfo.uuid;
    },
    status() {
      return this.$store.state.videoOffLine.status;
    }
  },
  methods: {
    changesrc() {
      this.src = "/video2/index.htm?r=" + Math.random();
    },
    Download() {
      window.open("/download/XmeyeVideoPlayToolSetup.exe");
    },
  },
  watch: {
    uuid(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.show = true;
        this.changesrc();
      }
    }
  }
};
</script>
<style scoped>
.videobox {
  padding: 15px;
  width: auto;
  height: calc(100vh - 65px);
}
.playBox {
  width: 100%;
  height: 100%;
  padding-right: 1.5vw;
}
.tipBox {
  text-align: center;
  position: relative;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.tipBox > span {
  width: 80%;
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 17px;
}
.equipOffline {
  font-size: 22px;
  padding-top: 20%;
}
</style>
