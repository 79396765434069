module.exports = {
  //2022-6 新增
  cloudinfo: {
    normal: "Uso normal",
    timeOut: "expirou",
    nonactivated: "não ativado",
    notSupport: "não suporta",
    noRemainingFlow: "Nenhum fluxo restante",
    VipStatus: "Status VIP",
    totalFlow: "fluxo total",
    residualFlow: "fluxo residual",
    expireDate: "Expirar data"
  },
  //新增
  cloud: "armazenamento na núvem",
  cloudTimeOut: "armazenamento em nuvem (expirado)",
  cloudNormal: "Armazenamento em nuvem (em uso)",
  cloudNonActivated: "Armazenamento na nuvem (não aberto)",
  cloudnotSupported:"Armazenamento na nuvem (não disponível)",
  vipTimeOut: "Tráfego 4G (expirado)",
  vipNormal: "Tráfego 4G (em uso)",
  vipNonActivated: "Tráfego 4G (não aberto)",
  vipnotSupported:"Tráfego 4G (não disponível)",
  // 葡萄牙语
  // 登录页
  userLogin: "Login de usuário", //用户登录
  equipLogin: "Login do dispositivo", //设备登录
  login: "Login", //登录
  regist: "Registre-se agora", //立即注册
  findPassword: "Esqueça a senha", //找回密码
  userRule: "Por favor insira o nome de usuário", //请输入用户名
  passwordRule: "Por favor insira a senha", //请输入密码
  codeRule: "Por favor insira o código de verificação", //请输入验证码
  equipIdRule: "Por favor, insira o número de série do dispositivo", //请输入设备序列号
  appDownload: "APP baixar", //App 下载
  webUnitDownload: "Web Download", //Web控件下载
  callUs: "Contate-Nos", //联系我们
  officialEdition: "versão clássica", //经典版
  proEdition: "versão PRO", //PRO版
  codeLenRule: "Insira um código de verificação de 4 caracteres", //请输入4位验证码
  notEmpty: "Por favor, não entrar em espaços", //请勿输入空格

  // 注册
  userRegist: "Criar Conta", //创建账户
  emailRule: "Por favor insira seu e-mail", //请输入邮箱
  emailtrue:
    "Por favor, digite o número da caixa de correio no formato correto", //请输入正确格式的邮箱号
  confirmPasswordRule: "Por favor digite a senha novamente", //请再次输入密码
  hadCount: "faça login imediatamente", //已有账号，立即登录
  registCount: "Registrar", //注册
  hadUser: "Seu nome de usuário registrado já existe", //您注册的用户名已存在
  hadMail: "Seu email cadastrado já existe", //您注册的邮箱已存在
  sendSuccess: "Correio enviado com sucesso", //邮件发送成功
  mailFormatError: "Erro de formato de e-mail, verifique", //邮箱格式错误，请检查
  codeError: "Erro de código de verificação", //验证码错误
  registerSuccess: "Registro bem sucedido", //注册成功
  mailNotFind: "O endereço de e-mail que você digitou não existe, verifique", //您输入的邮箱不存在，请检查
  userNameLen: "Digite um nome de usuário com 4 a 8 caracteres", //请输入4~8位用户名
  userPwdLen: "Por favor, digite uma senha de 8 ~ 32 caracteres", //请输入8~32位密码
  privacyPolicy:"Leia e selecione a política de privacidade",//阅读并选择隐私政策
  emailRegistration:"Registo por e-mail",//邮箱注册
  mobileRegistration:"Registo móvel",//手机注册
  // 找回密码
  phoneRetrievePassword:"Telemóvel para recuperar a senha",//手机找回密码
  phonetrue:"Introduza um número de telemóvel no formato correcto",//请输入正确的手机号码
  mailboxRetrievalPassword:"Recuperação da senha da caixa de correio",//邮箱找回密码
  findThePassword: "Esqueça a senha", //找回密码
  passwordText:
    "Por favor, digite o endereço de e-mail usado ao registrar a conta para recuperar a senha", //请输入注册账号时使用的邮箱来找回密码
  exit: "Sair", //退出
  nextStep: "Próxima Etapa", //下一步
  reset: "Reiniciar", //重新设置
  getCode: "Mandar", //获取验证码
  someTime: "readquirir", //秒后重新获取
  ok: "Confirmar", //确定
  mailSendMuch:
    "Mails só podem ser enviados três vezes ao dia por caixa de correio", //邮件每个邮箱一天只能发送三次
  mailChangeSuccess: "Modificado com sucesso", //修改成功
  pwdError: "Formato de senha incorreto", //密码格式不正确
  codeTimeout: "Código de verificação expirado", //验证码过期
  codeErrorMuch: "Muitos erros, tente novamente mais tarde", //错误次数过多,请稍后再试
  backLogin: "Volte ao login", //返回登录
  mailFast:
    "E-mails repetitivos não são permitidos dentro de um curto período de tempo", //短时间内不允许重复发送邮件

  //主页面
  refresh: "refrescar", //刷新
  myDevice: "Meu dispositivo", //我的设备
  personalInformation: "Informação pessoal", //个人信息
  deviceManagement: "Gestão de equipamentos", //设备管理
  VIPTraffic:"Tráfego VIP",//VIP流量
  changePassword: "Mudar senha", //修改密码
  changePasswordSuccess: "Mudar a senha com sucesso", //修改密码成功
  LogOut: "Sair da conta", //退出登录
  shurLogOut: "Tem certeza que quer sair?", //你确定要退出吗
  oldPassword: "Senha Antiga", //旧密码
  newPassword: "Nova Senha", //新密码
  inputNewPassword: "Digite a nova senha novamente", //再次输入新密码
  errorRefresh: "atualização falhou", //刷新失败
  online: "Online", //在线
  offline: "Offline", //离线
  UidLength:
    "Insira um número de série do dispositivo de 16 ou 20 dígitos composto por letras maiúsculas e minúsculas e números", //请输入16或20位由大,小写字母,数字组成的设备序列号
    PwordLength: "Insira uma senha do dispositivo com no máximo 100 caracteres, composta por letras maiúsculas e minúsculas e números",
    passwordrule: "Não entre símbolos especiais", //请勿输入特殊符号
  equipOffline:
    "O dispositivo está temporariamente offline, escolha outro dispositivo", //设备暂时不在线请选择其它设备吧
  BrowserSupport:
    "Este navegador não suporta a reprodução de plug-ins, use o Google Chrome", //此浏览器不支持插件播放，请使用IE或360浏览器
  embedBrowserFail:
    "Falha ao vincular o navegador, não mude de página rapidamente ao fazer login", //绑定浏览器失败，请在登录时勿快速切换页面
  noPlugin: "Plugin não detectado", //未检测到插件

  CancelAccount: "Cancelar conta",
  CancelAccountTitle: "Tem certeza de que deseja excluir a conta?",
  CancelSuccess: "Cancelar com sucesso a conta",

  // 设备列表、
  order: "SN", //序号
  equipmentPwd: "Senha do dispositivo", //设备密码
  equipmentOrder: "Número de série", //序列号
  equipmentName: "Nome do equipamento", //名称
  equipmentUser: "Nome do usuário", //设备用户名
  option: "Operar", //操作
  addEquipment: "Adicionar Dispositivo", //添加设备
  changeEquipmentMessage: "informações do dispositivo Modificar", //修改设备信息
  changePasswordMessage:"Modificar as informações da senha",//修改密码信息
  deleteEquipment:"Apagar o dispositivo",//删除设备
  delmessage: "Tem certeza de que deseja excluir?", //你确定要删除吗
  all: "No total", //共
  tips: "Os dados", //条数据
  add: "Adicionar", //添加
  equipmentSuccess: "Informação do dispositivo modificada com sucesso", //设备信息修改成功
  addUid:
    "Por favor, digite o número de série do dispositivo que você deseja adicionar", //请输入你要添加的设备序列号
  addid: "Por favor, insira o número de série que você deseja adicionar", //请输入你要添加的序号
  addname: "Por favor, digite o nome do dispositivo que você deseja adicionar", //请输入你要添加的设备名
  addUsername:
    "Por favor, digite o nome de usuário do dispositivo que você deseja adicionar", //请输入你要添加的设备用户名

  // 用户信息
  userinfo: "Informação de usuário", //用户信息
  userName: "Nome do usuário", //用户名
  usercontact: "Número de telefone", //联系方式
  useremail: "E-mail", //电子邮箱

  // 全局提示
  successLogin: "login bem sucedido", //登录成功
  errorLogin1: "Falha no login, nome de usuário ou senha incorretos", //登录失败，用户名或密码错误
  errorLogin2: "Falha no login, erro no código de verificação", //登录失败，验证码错误
  errorLogin3: "O login falhou, o código de verificação expirou", //登录失败，验证码过期
  errorLogin4: "Muitas falhas de login, conta bloqueada por 10 minutos", //登录失败次数过多，账户锁定10分钟
  delsucess: "Excluído com sucesso", //删除成功
  addSuccess: "Adicionado com sucesso", //添加成功
  addFail: "Adicionar falhou", //添加失败
  timeOut: "Tempo limite de login, pule automaticamente para a página de login", //登录超时，自动跳转登录页
  changeEquipmentPwdError:
    "A senha original do dispositivo foi inserida incorretamente", //原设备密码输入错误

  // 网络提示
  accessnot: "Acesso negado", //访问被拒绝
  resourcenot: "Recurso indisponível", //资源不可用
  nopage: "Página não encontrada", //找不到页面
  methoderror: "Métodos não permitidos", //不允许此方法
  serveerror: "Erro do servidor", //服务器错误
  unknownerror: "Erro desconhecido", //未知错误

  // 表单提示
  emailTip: "**@**.***",
  phoneTip:"Introduza o número de telemóvel correcto",
  confirmTip: "Por favor, seja consistente com a senha", //请与密码保持一致
  equipmentIdTip:
    "Por favor, indique 16 ou 20 dígitos do número de série do dispositivo", //请输入16或20位设备序列号
  equipmentUserTip: "Por favor, insira o nome de usuário do dispositivo", //请输入设备用户名
  equipmentPasswordTip1: "Por favor, insira a senha do dispositivo", //请输入设备密码
  equipmentPasswordTip:
    "Digite a senha do dispositivo, você não precisa digitá-la se não tiver uma", //请输入设备密码，没有则无需输入
  codeTip: "Por favor, insira o código de verificação de e-mail obtido", //请输入获取的邮箱验证码
  equipmentPwdTip: "A senha do dispositivo está em branco por padrão", //设备密码默认为空
  equipmentNameTip: "Por favor, insira o nome do dispositivo", //请输入设备名称
  equipmentUserNameTip:
    "O nome de usuário do dispositivo é padronizado como “admin”", //设备用户名默认为“admin”
  pagetip1:
    "Clique no dispositivo em “Meu Dispositivo” à esquerda para visualizar, reproduzir, configurar e outras operações no dispositivo", //单击左边“我的设备”中的设备，对设备进行预览，回放，配置等操作
  pagetip2:
    "Se esta é a sua primeira visita, por favor, baixe o plug-in. Pode demorar vários minutos. Por favor, aguarde pacientemente", //如果您是第一次访问，会自动下载插件，可能需要花费几分钟时间，请耐心等待
  passwordChangeTip: "Se você não alterar a senha, por favor, deixe em branco", //若不修改密码请保持为空
  loginUseNameTip:
    "Pode usar nome de usuário, e-mail, telefone celular para fazer login", //可使用用户名、邮箱号、手机号登录

  placeholderUser: "Nome de usuário / e-mail / celular", //用户名/邮箱号/手机号
  placeholderPassword: "Senha", //密码
  placeholderCode: "Código de verificação", //验证码
  placeholderEquipId: "Número de série", //设备序列号
  placeholderEquipAdmin: "Nome de usuário do dispositivo", //设备用户名
  placeholderEquipPassword: "Senha do dispositivo", //设备密码
  placeholderRegistUser: "Nome do usuário", //用户名
  placeholderRegistMail: "Email", //邮箱
  placeholderRegistPhone: "telemóvel", //手机
  placeholderRegistCode: "Código de verificação", //验证码
  placeholderRegistPwd: "Senha", //密码
  placeholderRegistPwd2: "Confirme a Senha", //确认密码

  supportTitle: "Informações de contato do suporte técnico", //技术支持联系方式
  supportPhone: "Linha direta de serviço", //服务热线
  supportMail: "Caixa de correio serviço", //服务邮箱
  supportTime:
    "Horário de trabalho: segunda a sábado, das 8h30 às 17h30 (exceto feriados oficiais)", //服务时间：周一到周六8:30--17:30（法定节假日除外）
  userPwdRule: "O formato da senha está errado", //密码格式错误
  userNameRule: "Formato de nome de usuário incorreto", //用户名格式错误
  Ok: "Confirm", //确认
  cancel: "Cancelar", //取消
  backOld: "Versão antiga", //回到旧版

  cookie:
    "Nosso site usa cookies para melhorar nosso site e melhorar a experiência do usuário. Se você continuar a navegar neste site sem alterar as configurações de cookies do seu navegador, será considerado que você concorda com nossa política de uso de cookies.", //我们的网站使用cookies以改进我们的网站和改善用户体验。如您继续浏览本网站而未更改浏览器cookie设置，视为同意我们的cookies使用策略。
  cookieLink: "Política", //政策
  agree: "Concordo", //同意
  notAgree: "Discordar", //不同意

  cookiePolicy: "Política de Cookies", //Cookie 政策
  cookieUse: "Como usamos cookies e tecnologias semelhantes", //我们如何使用Cookie和同类技术
  cookieWhy:
    "Para garantir o funcionamento normal do site, armazenaremos pequenos arquivos de dados chamados Cookies em seu computador ou dispositivo móvel. Os cookies geralmente contêm identificadores, nomes de sites e alguns números e caracteres. Com a ajuda de cookies, os sites podem armazenar dados como suas preferências.", //为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据档。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。
  cookieFor:
    "Não usaremos cookies para nenhuma finalidade diferente das declaradas nesta política. Você pode gerenciar ou excluir cookies de acordo com suas preferências. Para obter detalhes, consulte AboutCookies.org. Você pode limpar todos os cookies salvos em seu computador, e a maioria dos navegadores da web tem uma função para bloquear cookies. Mas, se você fizer isso, precisará alterar pessoalmente as configurações do usuário sempre que visitar nosso site. Para saber mais sobre como alterar as configurações do navegador, visite a página de configurações do navegador que você está usando. No entanto, observe que, se você não aceitar cookies, pode não ser capaz de experimentar todos os recursos do site.", //我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。但请注意，如果您不接受cookie，可能无法全面体验网站的某些功能。
  cookieList:
    "A seguir está uma lista dos tipos de cookies usados ​​pelo site:", //以下是网站使用的cookie种类列表：
  cookiePurpose: "Propósito", //目的
  cookieDescribe: "Detalhes", //描述
  cookieType: "Tipo e prazo de validade", //种类和失效时间
  cookiePerformance: "Desempenho (navegador do usuário)", //性能（用户浏览器）
  cookieLogin:
    "Registre o status de login do usuário e não há necessidade de fazer login novamente em 7 dias", //记录用户登录状态，以及7天内不用重新登录
  cookieDay: "7 dias", //7天
  cookieCnzz: "CNZZ", //CNZZ
  cookieTime: "Registre o número de vezes que o usuário visita o site", //记录用户访问网站的次数
  cookieForever: "permanente", //永久
  cookieTrack:
    "Muitos navegadores da web têm uma função Do Not Track, que pode postar solicitações Do NotTrack em sites. Atualmente, as principais organizações de padrões da Internet não estabeleceram políticas relevantes para regular como os sites devem responder a essas solicitações. Mas se o seu navegador habilitar o Do Not Track, nosso site respeitará sua escolha.", //很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do NotTrack请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站都会尊重您的选择。
  backHome: "Voltar a página inicial", //回到首页
  // RS
  RS2000: 'A execução foi bem sucedida',//执行成功
  RS4000: "Nome de utilizador ou senha errados",//用户名或密码错误
  RS4010: 'Erro no código de verificação',//验证码错误
  RS4011: 'A senha é inconsistente',//密码不一致
  RS4012: "O nome de utilizador foi registado",//用户名已被注册
  RS4013: "O nome do utilizador está vazio",//用户名为空
  RS4014: 'A senha está vazia',//密码为空
  RS4015: 'Confirmar que a senha está vazia',//确认密码为空
  RS4016: 'O número de telemóvel está vazio',//手机号为空
  RS4017: "Formato incorrecto do nome do utilizador",//用户名格式不正确
  RS4018: 'Formato da senha incorreto',//密码格式不正确
  RS4019: 'Confirmar que o formato da senha está incorreto',//确认密码格式不正确
  RS4020: 'O formato do número de telemóvel está incorrecto',//手机号格式不正确
  RS4021: 'Número de telemóvel registado',//手机号已注册
  RS4022: 'O número de telemóvel não está registado',//手机号未注册
  RS4023: 'Email registado',//邮箱已注册
  RS4024: 'A caixa de correio não está registada',//邮箱未注册
  RS4025: "O nome de utilizador não está registado",//用户名未注册
  RS4026: 'A senha original está incorreta',//原密码错误
  RS4027: 'Não foi possível modificar a senha',//修改密码失败
  RS4029: "O ID do Utilizador está vazio",//用户ID为空
  RS4030: 'O código de verificação está vazio',//验证码为空
  RS4031: 'A caixa de correio está vazia',//邮箱为空
  RS4032: 'Formato incorrecto da caixa de correio',//邮箱格式不正确
  RS4033: 'Formato incorrecto da caixa de correio',//邮箱格式不正确
  RS4034: "O WeChat não está ligado",//微信没有绑定
  RS4035: 'Não foi possível ligar o WeChat',//绑定微信失败
  RS4036: 'Não foi possível ligar o número do telemóvel',//绑定手机号失败
  RS4037: 'Não foi possível ligar a caixa de correio',//绑定邮箱失败
  RS4038: 'Enviar código de verificação mais do que o número máximo de vezes',//发送验证码超过最大次数
  RS4039: 'o login falhou',//注册失败
  RS4040: "O WeChat ligou os utilizadores",//微信已绑定用户
  RS4041: "Nenhuma permissão para modificar o nome de usuário (apenas para usuários anônimos gerados)",//没有权限修改用户名（仅针对生成的匿名用户修改）
  RS4042: "USER_ NOT_ FB_ O usuário BIND não está vinculado ao Facebook",//USER_NOT_FB_BIND 用户没有绑定facebook
  RS4043: "USER_ FAIL_ FB_ O utilizador BIND não conseguiu ligar-se ao Facebook",//USER_FAIL_FB_BIND 用户绑定facebook失败
  RS4044: "USER_ NOT_ GG_ Os usuários do BIND não têm vínculo com o Google",//USER_NOT_GG_BIND 用户没有google绑定
  RS4045: "USER_ FAIL_ GG_ O usuário BIND não conseguiu se vincular ao Google",//USER_FAIL_GG_BIND 用户绑定google失败
  RS4046: "A conta de linha não está vinculada",//Line账户未绑定
  RS4047: 'A ligação da conta de linha falhou',//Line账户绑定失败
  RS4100: "O dispositivo não pode ser adicionado ilegalmente",//设备非法不允许添加
  RS4101: "O dispositivo já existe",//设备已存在
  RS4102: 'Não foi possível apagar o dispositivo',//删除设备失败
  RS4103: 'A modificação falhou',//修改失败
  RS4104: 'Excepção do parâmetro uuid do dispositivo',//设备uuid参数异常
  RS4105: 'Excepção do parâmetro nome do utilizador do dispositivo',//设备用户名参数异常
  RS4106: "O parâmetro da senha do dispositivo é anormal",//设备密码参数异常
  RS4116: "Não foi possível entregar a conta mestre do dispositivo a outro usuário. Verifique se o usuário tem o dispositivo e se o usuário tem a autoridade da conta mestre do dispositivo",//将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限
  RS4117: 'Não é uma conta principal',//不是主账户
  RS4118: "O utilizador não tem este dispositivo",//用户没有此设备
  RS4119: "O dispositivo adicionado não é exclusivo. Outras contas foram adicionadas",//添加设备不唯一，其它账户已添加
  RS4120: "Limite máximo de adição de dispositivos (100)",//添加设备最大限制(100个)
  RS4126: 'O token de suporte do dispositivo só pode ser adicionado por uma conta',//设备支持令牌只有一个账户添加
  RS4127: "Ficha de dispositivo em falta",//缺少设备令牌
  RS4300: "falha no envio",//发送失败
  RS4302: 'Código de verificação de cancelamento enviado com sucesso',//发送注销验证码成功
  RS4400: "A verificação da interface SMS falhou, entre em contato conosco",//短信接口验证失败，请联系我们
  RS4401: "Erro do parâmetro da interface SMS, entre em contato conosco",//短信接口参数错误，请联系我们
  RS4402: 'Cada número de telefone celular só pode enviar SMS três vezes por dia',//每个手机号一天只能发送三次短信
  RS4403: "O envio falhou. Tente novamente mais tarde",//发送失败，请稍后再试
  RS4404: "O envio é muito frequente, aguarde 120 segundos",//发送太频繁了，请间隔120秒
  RS4405: "falha no envio",//发送失败
  RS5000: "Falha do servidor",//服务器故障
  RS5001: "O certificado não existe",//证书不存在
  RS5002: "Erro de informação do cabeçalho do pedido",//请求头信息错误
  RS5003: "Expiração do Certificado",//证书失效
  RS5004: "Erro de verificação da chave de geração",//生成密钥校验错误
  RS5005: 'Excepção do parâmetro',//参数异常
  RS5009: 'Erro de código WeChat, criptografia AES e erro de descriptografia',//微信code错误、AES加解密错误
  RS5010: 'Ficha expirou',//token 已过期
  RS5011: 'Erro do token',//token 错误
  RS5012: 'O Token não tem permissão',//token 无权限
  RS5013: "Não vou apoiá-lo",//不支持
  RS5014: "A operação é demasiado frequente",//操作太频繁
  RS4050: "A operação é demasiado frequente",//操作太频繁
  phoneCodeTip: "Introduza o código de verificação do telemóvel obtido",//======请输入获取的手机验证码
  phoneNotFind: "O número de telemóvel que introduziu não existe, por favor verifique",//=====您输入的手机号码不存在，请检查
  phoneFormatError: "Erro no formato do número móvel, verifique por favor",//======手机号码格式错误，请检查
  phoneSendSuccess: "Verifique se o código de verificação foi enviado com sucesso",//=======验证码发送成功请注意查收
  hadPhone: "O seu número de telemóvel registado já existe", //======您的注册手机号码已存在
  phoneRule: "Introduza o seu número de telemóvel",//======请输入手机号码


  useNameTip:"4-15 caracteres, incluindo chinês, letras, números_ Composto por dois ou mais elementos", //4-15位字符，可由中文、数字、_和-组成
  passwordTip:"8-32 caracteres de comprimento, que pode ser composto de letras inglesas, números, sublinhados e - li ou mais", //8-32位字符长度，由英文字母、数字、下划线和-组成
  NameLength: "Insira um nome de dispositivo com no máximo 255 caracteres consistindo em letras maiúsculas e minúsculas, números e chinês",
  UnameLength: "Insira um nome de usuário do dispositivo com no máximo 255 caracteres consistindo em letras maiúsculas e minúsculas, números e chinês.",
  placeTip:"Indique o número de série",
  deleteAll:"Eliminação do lote",

  // nettimeout:"网络超时",
  accessTokenfail:"Tempo limite do serviço, o token de acesso falhou",
  userinfo2faile:"Tempo limite do serviço, a aquisição da informação do utilizador falhou",
  getcodefail:"Tempo limite do serviço, a obtenção do código de verificação falhou",
  getmailfail:"Tempo- limite do serviço, a modificação falhou",
  getdevicefail:"Tempo limite do serviço, não foi possível obter a lista de dispositivos",
  getareacodefail:"Tempo limite do serviço, incapaz de obter a lista de códigos de área",
  rulenamefail:"Tempo limite do serviço, a verificação do nome do utilizador falhou",
  rulephonefail:"Tempo limite de serviço, verificação do número de telefone celular do usuário falhou",
  ruleregisterfail:"Tempo- limite do serviço, o registo falhou",
  ruleemailfail:"Tempo limite do serviço, a verificação da caixa de correio falhou",
  getdevicestatusfail:"Tempo limite do serviço, não foi possível obter o estado do dispositivo",

  oldequipmentpassword:"Senha original",
  oldpasswordrule:"Por favor, insira a senha original do dispositivo. Se não, você não precisa digitá-la",
  newequipmentpassword:"Nova senha",
  newpasswordrule:"Por favor, insira uma nova senha para o dispositivo. Caso contrário, você não precisa digitá-la",
  confirmequippassword:"Confirmar a Senha",
  confirmeqpasswordrule:"Por favor, mantenha-se consistente com a nova senha",
};
