module.exports = {
  //2022-6 新增
  cloudinfo: {
    normal: "正常使用",
    timeOut: "已过期",
    nonactivated: "未开通",
    notSupport: "不支持",
    noRemainingFlow: "无剩余流量",
    VipStatus: "Vip状态",
    totalFlow: "总流量",
    residualFlow: "剩余流量",
    expireDate: "到期时间"
  },

  //新增
  cloud: "云存储",
  cloudTimeOut: "云存储(已过期)",
  cloudNormal: "云存储(使用中)",
  cloudNonActivated: "云存储(未开通)",
  cloudnotSupported: "云存储(不支持)",
  vipTimeOut: "4G流量(已过期)",
  vipNormal: "4G流量(使用中)",
  vipNonActivated: "4G流量(未开通)",
  vipnotSupported: "4G流量(不支持)",
  // 简体中文
  // 登录页
  userLogin: "用户登录",
  equipLogin: "设备登录",
  login: "登录",
  regist: "立即注册",
  findPassword: "忘记密码",
  userRule: "请输入用户名",
  passwordRule: "请输入密码",
  codeRule: "请输入验证码",
  equipIdRule: "请输入设备序列号",
  appDownload: "App 下载",
  webUnitDownload: "Web控件下载",
  officialEdition: "经典版",
  proEdition: "PRO版",
  codeLenRule: "请输入4位验证码",
  notEmpty: "请勿输入空格",
  callUs: "联系我们",

  // 注册
  userRegist: "用户注册",
  emailRule: "请输入邮箱",
  // phoneRule: "请输入手机号码",//======
  emailtrue: "请输入正确格式的邮箱号",
  phonetrue: "请输入正确格式的手机号码",//请输入正确的手机号码
  confirmPasswordRule: "请再次输入密码",
  hadCount: "已有账号，立即登录",
  registCount: "注册",
  hadUser: "您注册的用户名已存在",
  hadMail: "您注册的邮箱已存在",
  // hadPhone: "您的注册手机号码已存在", //======
  sendSuccess: "邮件发送成功请注意查收",
  // phoneSendSuccess: "验证码发送成功请注意查收",//=======
  mailFormatError: "邮箱格式错误，请检查",
  // phoneFormatError: "手机号码格式错误，请检查",//======
  codeError: "验证码错误",
  registerSuccess: "注册成功",
  mailNotFind: "您输入的邮箱不存在，请检查",
  // phoneNotFind: "您输入的手机号码不存在，请检查",//=====
  userNameLen: "请输入4~8位用户名",
  userPwdLen: "请输入8~32位密码",
  privacyPolicy: "阅读并选择隐私政策",//阅读并选择隐私政策
  emailRegistration: "邮箱注册",//邮箱注册
  mobileRegistration: "手机注册",//手机注册

  // 找回密码
  phoneRetrievePassword: "手机找回",//手机找回密码
  mailboxRetrievalPassword: "邮箱找回",//邮箱找回密码
  findThePassword: "找回密码",
  passwordText: "请输入注册账号时使用的邮箱来找回密码",
  exit: "退出",
  nextStep: "下一步",
  reset: "重新设置",
  getCode: "获取验证码",
  someTime: "秒后重新获取",
  ok: "确定",
  mailSendMuch: "邮件每个邮箱一天只能发送三次",
  phoneSendMuch: "验证码一天只能发送三次",
  mailChangeSuccess: "修改成功",
  pwdError: "密码格式不正确",
  codeTimeout: "验证码过期",
  codeErrorMuch: "验证码错误次数过多,请稍后再试",
  backLogin: "返回登录",
  mailFast: "短时间内不允许重复发送邮件",
  phoneFast: "短时间内不允许重复发送短信",

  //主页面
  refresh: "刷新",
  myDevice: "我的设备",
  personalInformation: "个人信息",
  deviceManagement: "设备管理",
  VIPTraffic: "VIP流量",//VIP流量
  changePassword: "修改密码",
  changePasswordSuccess: "修改密码成功",
  LogOut: "退出登录",
  shurLogOut: "你确定要退出吗?",
  oldPassword: "旧密码",
  newPassword: "新密码",
  inputNewPassword: "再次输入新密码",
  errorRefresh: "刷新失败",
  online: "在线",
  offline: "离线",
  UidLength: "请输入16或20位由大,小写字母,数字组成的设备序列号",
  
  PwordLength: "请输入不大于100位由大,小写字母,数字组成的设备密码",
  passwordrule: "请勿输入特殊符号",
  equipOffline: "设备暂时不在线请选择其它设备吧",
  BrowserSupport: "此浏览器不支持插件播放，请使用谷歌浏览器",
  embedBrowserFail: "绑定浏览器失败，请在登录时勿快速切换页面",
  noPlugin: "未检测到插件",

  CancelAccount: "注销账户",
  CancelAccountTitle: "你确定要注销账户吗？",
  CancelSuccess: "注销成功",
  // 设备列表、
  order: "序号",
  equipmentPwd: "设备密码",
  equipmentOrder: "序列号",
  equipmentName: "名称",
  equipmentUser: "用户名",
  option: "操作",
  addEquipment: "添加设备",
  changeEquipmentMessage: "修改设备信息",
  changePasswordMessage:"修改密码信息",//修改密码信息
  deleteEquipment:"删除设备",//删除设备
  delmessage: "你确定要删除吗",
  all: "共",
  tips: "条数据",
  add: "添加",
  equipmentSuccess: "设备信息修改成功",
  addUid: "请输入你要添加的设备序列号",
  addid: "请输入你要添加的序号",
  addname: "请输入你要添加的设备名",
  addUsername: "请输入你要添加的设备用户名",

  // 用户信息
  userinfo: "用户信息",
  userName: "用户名",
  usercontact: "联系方式",
  useremail: "电子邮箱",

  // 全局提示
  successLogin: "登录成功",
  errorLogin1: "登录失败，用户名或密码错误",
  errorLogin2: "登录失败，验证码错误",
  errorLogin3: "登录失败，验证码过期",
  errorLogin4: "登录失败次数过多，账户锁定10分钟",
  delsucess: "删除成功",
  addSuccess: "添加成功",
  addFail: "添加失败",
  timeOut: "登录超时，自动跳转登录页",
  changeEquipmentPwdError: "原设备密码输入错误",

  // 网络提示
  accessnot: "访问被拒绝",
  resourcenot: "资源不可用",
  nopage: "找不到页面",
  methoderror: "不允许此方法",
  serveerror: "服务器错误",
  unknownerror: "未知错误",


  // 表单提示

  
  emailTip: "**@**.***",
  phoneTip: "请输入正确的手机号码",
  
  confirmTip: "请与密码保持一致",
  equipmentIdTip: "请输入16或20位设备序列号",
  equipmentUserTip: "请输入设备用户名",
  equipmentPasswordTip: "请输入设备密码，没有则无需输入",
  equipmentPasswordTip1: "请输入设备密码",
  codeTip: "请输入获取的邮箱验证码",
  // phoneCodeTip: "请输入获取的手机验证码",//======
  equipmentPwdTip: "设备密码默认为空",
  equipmentNameTip: "请输入设备名称",
  equipmentUserNameTip: "设备用户名默认为“admin”",
  pagetip1: "单击左边“我的设备”中的设备，对设备进行预览，回放，配置等操作",
  pagetip2:
    "如果您是第一次访问，请下载插件，可能需要花费几分钟时间，请耐心等待",
  passwordChangeTip: "若不修改密码请保持为空",
  loginUseNameTip: "可使用用户名、邮箱号、手机号登录",

  placeholderUser: "用户名/邮箱号/手机号",
  placeholderPassword: "密码",
  placeholderCode: "验证码",
  placeholderEquipId: "设备序列号",
  placeholderEquipAdmin: "设备用户名",
  placeholderEquipPassword: "设备密码",
  placeholderRegistUser: "用户名",
  placeholderRegistMail: "邮箱",
  placeholderRegistPhone: "手机", //手机
  placeholderRegistCode: "验证码",
  placeholderRegistPwd: "密码",
  placeholderRegistPwd2: "确认密码",

  supportTitle: "技术支持联系方式",
  supportPhone: "服务热线",
  supportMail: "服务邮箱",
  supportTime: "服务时间：周一到周六8:30--17:30（法定节假日除外）",

  userPwdRule: "密码格式错误",
  userNameRule: "用户名格式错误",
  Ok: "确认",
  cancel: "取消",
  backOld: "回到旧版",

  cookie:
    "我们的网站使用cookies以改进我们的网站和改善用户体验。如您继续浏览本网站而未更改浏览器cookie设置，视为同意我们的cookies使用策略。",
  cookieLink: "政策",
  agree: "同意",
  notAgree: "不同意",

  cookiePolicy: "Cookie 政策",
  cookieUse: "我们如何使用Cookie和同类技术",
  cookieWhy:
    "为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据档。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。",
  cookieFor:
    "我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。但请注意，如果您不接受cookie，可能无法全面体验网站的某些功能。",
  cookieList: "以下是网站使用的cookie种类列表：",
  cookiePurpose: "目的",
  cookieDescribe: "描述",
  cookieType: "种类和失效时间",
  cookiePerformance: "性能（用户浏览器）",
  cookieLogin: "记录用户登录状态，以及7天内不用重新登录",
  cookieDay: "7天",
  cookieCnzz: "CNZZ",
  cookieTime: "记录用户访问网站的次数",
  cookieForever: "永久",
  cookieTrack:
    "很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do NotTrack请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么我们的网站都会尊重您的选择。",
  backHome: "回到首页",
  
  // RS接口返回状态码
  RS2000: '执行成功',//执行成功
  RS4000: '用户名或密码错误',//用户名或密码错误
  RS4010: '验证码错误',//验证码错误
  RS4011: '密码不一致',//密码不一致
  RS4012: '用户名已被注册',//用户名已被注册
  RS4013: '用户名为空',//用户名为空
  RS4014: '密码为空',//密码为空
  RS4015: '确认密码为空',//确认密码为空
  RS4016: '手机号为空',//手机号为空
  RS4017: '用户名格式不正确',//用户名格式不正确
  RS4018: '密码格式不正确',//密码格式不正确
  RS4019: '确认密码格式不正确',//确认密码格式不正确
  RS4020: '手机号格式不正确',//手机号格式不正确
  RS4021: '手机号已注册',//手机号已注册
  RS4022: '手机号未注册',//手机号未注册
  RS4023: '邮箱已注册',//邮箱已注册
  RS4024: '邮箱未注册',//邮箱未注册
  RS4025: '用户名未注册',//用户名未注册
  RS4026: '原密码错误',//原密码错误
  RS4027: '修改密码失败',//修改密码失败
  RS4029: '用户ID为空',//用户ID为空
  RS4030: '验证码为空',//验证码为空
  RS4031: '邮箱为空',//邮箱为空
  RS4032: '邮箱格式不正确',//邮箱格式不正确
  RS4033: '邮箱格式不正确',//邮箱格式不正确
  RS4034: '微信没有绑定',//微信没有绑定
  RS4035: '绑定微信失败',//绑定微信失败
  RS4036: '绑定手机号失败',//绑定手机号失败
  RS4037: '绑定邮箱失败',//绑定邮箱失败
  RS4038: '发送验证码超过最大次数',//发送验证码超过最大次数
  RS4039: '注册失败',//注册失败
  RS4040: '微信已绑定用户',//微信已绑定用户
  RS4041: '没有权限修改用户名（仅针对生成的匿名用户修改）',//没有权限修改用户名（仅针对生成的匿名用户修改）
  RS4042: 'USER_NOT_FB_BIND 用户没有绑定facebook',//USER_NOT_FB_BIND 用户没有绑定facebook
  RS4043: 'USER_FAIL_FB_BIND 用户绑定facebook失败',//USER_FAIL_FB_BIND 用户绑定facebook失败
  RS4044: 'USER_NOT_GG_BIND 用户没有google绑定',//USER_NOT_GG_BIND 用户没有google绑定
  RS4045: 'USER_FAIL_GG_BIND 用户绑定google失败',//USER_FAIL_GG_BIND 用户绑定google失败
  RS4046: 'Line账户未绑定',//Line账户未绑定
  RS4047: 'Line账户绑定失败',//Line账户绑定失败
  RS4100: '设备非法不允许添加',//设备非法不允许添加
  RS4101: '设备已存在',//设备已存在
  RS4102: '删除设备失败',//删除设备失败
  RS4103: '修改失败',//修改失败
  RS4104: '设备uuid参数异常',//设备uuid参数异常
  RS4105: '设备用户名参数异常',//设备用户名参数异常
  RS4106: '设备密码参数异常',//设备密码参数异常
  RS4116: '将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限',//将设备主账户移交给其他用户失败，检查用户是否拥有设备并且该用户拥有设备主账户权限
  RS4117: '不是主账户',//不是主账户
  RS4118: '用户没有此设备',//用户没有此设备
  RS4119: '添加设备不唯一，其它账户已添加',//添加设备不唯一，其它账户已添加
  RS4120: '添加设备最大限制(100个)',//添加设备最大限制(100个)
  RS4126: '设备支持令牌只能被一个账户添加',//设备支持令牌只能被一个账户添加
  RS4127: '	缺少设备令牌',//缺少设备令牌
  RS4300: '发送失败',//发送失败
  RS4302: '发送注销验证码成功',//发送注销验证码成功
  RS4400: '短信接口验证失败，请联系我们。',//短信接口验证失败，请联系我们
  RS4401: '短信接口参数错误，请联系我们',//短信接口参数错误，请联系我们
  RS4402: '每个手机号一天只能发送三次短信',//每个手机号一天只能发送三次短信
  RS4403: '发送失败，请稍后再试',//发送失败，请稍后再试
  RS4404: '发送太频繁了，请间隔120秒',//发送太频繁了，请间隔120秒
  RS4405: '发送失败',//发送失败
  RS5000: '服务器故障',//服务器故障
  RS5001: '证书不存在',//证书不存在
  RS5002: '请求头信息错误',//请求头信息错误
  RS5003: '证书失效',//证书失效
  RS5004: '生成密钥校验错误',//生成密钥校验错误
  RS5005: '参数异常',//参数异常
  RS5009: '微信code错误、AES加解密错误',//微信code错误、AES加解密错误
  RS5010: 'token 已过期',//token 已过期
  RS5011: 'token 错误',//token 错误
  RS5012: 'token 无权限',//token 无权限
  RS5013: '不支持',//不支持
  RS5014: '操作太频繁',//操作太频繁
  RS4050: '操作太频繁',//操作太频繁
  phoneCodeTip: "请输入获取的手机验证码",//======请输入获取的手机验证码
  phoneNotFind: "您输入的手机号码不存在，请检查",//=====您输入的手机号码不存在，请检查
  phoneFormatError: "手机号码格式错误，请检查",//======手机号码格式错误，请检查
  phoneSendSuccess: "验证码发送成功请注意查收",//=======验证码发送成功请注意查收
  hadPhone: "您的注册手机号码已存在", //======您的注册手机号码已存在
  phoneRule: "请输入手机号码",//======请输入手机号码

  useNameTip: "4-15位字符，可由中文、字母、数字、_和-里两项及以上组成",
  passwordTip: "8-32位字符长度，可由英文字母、数字、下划线和-里两项及以上组成",
  NameLength: "请输入不大于255位由大,小写字母,数字,中文组成的设备名称",
  UnameLength: "请输入不大于255位由大,小写字母,数字,中文组成的设备用户名",
  placeTip:"请输入序列号",
  deleteAll:"批量删除",

  // nettimeout:"网络超时",
  accessTokenfail:"服务超时,获取accessToken失败",
  userinfo2faile:"服务超时,用户信息获取失败",
  getcodefail:"服务超时,获取验证码失败",
  getmailfail:"服务超时,修改失败",
  getdevicefail:"服务超时,无法获取设备列表",
  getareacodefail:"服务超时,无法获取区号列表",
  rulenamefail:"服务超时,验证用户名失败",
  rulephonefail:"服务超时,验证用户手机号失败",
  ruleregisterfail:"服务超时,注册失败",
  ruleemailfail:"服务超时,验证邮箱失败",
  getdevicestatusfail:"服务超时,无法获取设备状态",

  oldequipmentpassword:"原密码",
  oldpasswordrule:"请输入设备原密码，没有则无需输入",
  newequipmentpassword:"新密码",
  newpasswordrule:"请输入设备新密码，没有则无需输入",
  confirmequippassword:"确认密码",
  confirmeqpasswordrule:"请与新密码保持一致",
};
